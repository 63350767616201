import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import { ToggleWrapperBallance } from './style';

function Toggle({ checked, onChecked }) {
  return (
    <ToggleWrapperBallance onClick={(e) => e.stopPropagation()}>
      <Switch checked={checked} onChange={onChecked} />
    </ToggleWrapperBallance>
  );
}

Toggle.propTypes = {
  checked: PropTypes.bool,
  onChecked: PropTypes.func,
};

Toggle.defaultProps = {
  checked: true,
  onChecked: () => {},
};

export default Toggle;
