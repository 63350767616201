import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import BorrowTab from 'components/common/borrowTabs/borrowTab';
import RepayBorrowTab from 'components/common/borrowTabs/repayBorrowTab';
import { Modal } from 'antd';
import closeImg from 'assets/img/close-white.svg';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { FormattedMessage } from 'react-intl';
import { Tabs, TabSection } from 'components/common/style';
import { ModalContent, ProgressBar } from './style';

function BorrowModal({ visible, asset, onCancel, settings, type }) {
  const [currentTab, setCurrentTab] = useState(type);
  useEffect(() => {
    if (visible) {
      setCurrentTab(type);
    }
  }, [visible]);

  if (!asset.id) {
    return <></>;
  }
  return (
    <Modal
      className="connect-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        {settings.pendingInfo &&
          settings.pendingInfo.status &&
          ['Borrow', 'Repay Borrow'].includes(settings.pendingInfo.type) && (
            <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />
          )}
        <TabSection>
          <Tabs
            isLoading={settings?.pendingInfo?.status}
            activeBar={currentTab === 'borrow' ? 'left' : 'right'}
            className="flex align-center"
          >
            <img className="close-btn pointer" src={closeImg} alt="close" onClick={onCancel} />
            {[
              { val: 'borrow', title: 'Borrow' },
              { val: 'repayBorrow', title: 'Repay Borrow' },
            ].map((item) => (
              <div
                key={item.val}
                className={`flex align-center just-center tab-item pointer ${currentTab === item.val && 'tab-active'}`}
                onClick={() => {
                  setCurrentTab(item.val);
                }}
              >
                <FormattedMessage id={item.title} />
              </div>
            ))}
          </Tabs>
          {currentTab === 'borrow' && <BorrowTab asset={asset} changeTab={setCurrentTab} onCancel={onCancel} />}
          {currentTab === 'repayBorrow' && (
            <RepayBorrowTab asset={asset} changeTab={setCurrentTab} onCancel={onCancel} />
          )}
        </TabSection>
      </ModalContent>
    </Modal>
  );
}

BorrowModal.propTypes = {
  visible: PropTypes.bool,
  asset: PropTypes.object,
  onCancel: PropTypes.func,
  type: PropTypes.string.isRequired,
};

BorrowModal.defaultProps = {
  visible: false,
  asset: {},
  onCancel: () => {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps))(BorrowModal);
