import styled from 'styled-components';
import { Button } from 'antd';

const Wrap = styled(Button)`
  &.ant-btn {
    height: 34px;
    padding: 0 21px;
    border-radius: 24px;
    font-size: 12px;
    border: none;
  }
  &.disabled-btn {
    cursor: not-allowed !important;
  }
  &.normal {
    background-color: ${(props) => props.theme['--color-btn-bg']};
    color: ${(props) => props.theme['--color-btn-text']};
    border: 1px solid ${(props) => props.theme['--color-btn-border']};
  }
  &.translucent {
    background-color: ${(props) => props.theme['--color-btn-bg']};
    color: ${(props) => props.theme['--color-translucent-text']};
    border: 1px solid ${(props) => props.theme['--color-translucent-border']};
  }
  &.green {
    background-color: ${(props) => props.theme['--color-btn-green-bg']};
    color: ${(props) => props.theme['--color-btn-green-text']};
  }
  &.green-next {
    background-color: ${(props) => props.theme['--color-btn-green-next-bg']};
    color: ${(props) => props.theme['--color-btn-green-next-text']};
  }
  &.green-disabled {
    background-color: ${(props) => props.theme['--color-btn-green-disabled-bg']};
    color: ${(props) => props.theme['--color-btn-green-disabled-text']};
  }
  &.yellow {
    background-color: ${(props) => props.theme['--color-btn-yellow-bg']};
    color: ${(props) => props.theme['--color-btn-yellow-text']};
  }
  &.yellow-next {
    background-color: ${(props) => props.theme['--color-btn-yellow-next-bg']};
    color: ${(props) => props.theme['--color-btn-yellow-next-text']};
  }
  &.yellow-disabled {
    background-color: ${(props) => props.theme['--color-btn-yellow-disabled-bg']};
    color: ${(props) => props.theme['--color-btn-yellow-disabled-text']};
  }
`;

export { Wrap };
