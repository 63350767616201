import styled from 'styled-components';

const LinkMenuWrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 34px;
  margin-bottom: 130px;
  .link-menu-item {
    flex: 1;
    text-align: center;
    a {
      color: ${(props) => props.theme['--color-text-top-light']};
    }
    .img-wrap {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin: 0 auto 6px;
    }
  }
  @media only screen and (max-width: 768px) {
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 37px;
    .link-menu-item {
      flex-grow: 0;
      margin: 0 33px;
      a {
        color: ${(props) => props.theme['--color-text-gray']};
      }
      .img-wrap {
        width: 35px;
        height: 35px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export { LinkMenuWrapper };
