import { connect } from 'react-redux';
import { uiActionCreators } from './actions';

const mapStateToProps = (state) => {
  return state.ui;
};
const mapDispatchToProps = uiActionCreators;

export function connectUI() {
  return connect(mapStateToProps, mapDispatchToProps);
}
