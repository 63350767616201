export const vars = {
  '--m-header-height': '50px',
};
export const themeCollection = {
  dark: {
    '--color-white': '#ffffff',
    '--color-black': '#35435B',
    '--color-orange': '#ff4a21',
    '--color-pink': '#DE4869',
    '--color-yellow': '#eec76a',
    '--color-purple': '#906de5',
    '--color-red': '#f9053e',
    // '--color-green': '#3541FF',
    '--color-green': '#3541FF',
    '--color-blue': '#039be5',
    '--color-dark-green': '#3541FF',
    '--color-dark-grey': '#666771',
    '--color-modal-white': '#5FD495',
    /* Background colors */
    // '--color-bg-main': '#090D27',
    '--color-bg-main': '#000000',
    '--color-bg-primary': '#282932',
    '--color-bg-primary-check': '#282932',
    '--color-bg-black': '#000000',
    '--color-bg-white': '#ffffff',
    '--color-bg-active': '#5FD495',
    '--color-disConnect-btn-color': '#5FD495',
    '--color-disConnect-btn-background': '#ffffff',
    '--color-bg-new-line-active': '#0E3F2D',
    '--color-bg-new-active': '#548272',
    '--color-bg-new-inactive': '#0E3F2D',
    '--color-bg-sidebar': '#282932',
    '--color-bg-icon': 'rgba(255, 255, 255, 0.2)',
    '--color-bg-table': '#0D1915',
    '--color-pools-bg-table': '#0D1915',
    '--color-bg-btn': '#28312D',
    '--color-btn-text-t': '#D4D4D6',
    /* Typography colors */
    '--color-text-main': '#ffffff',
    '--color-text-sidebar': '#3541FF',
    '--color-text-secondary': '#a1a1a1',
    '--color-text-inactive': '#5e5f6a',
    '--color-text-red': '#f9053e',
    '--color-text-green': '#3541FF',
    '--color-text-blue': '#2fe29b',
    '--color-text-gray': '#899893',
    '--color-text-desc': '#E2E4E3',
    '--color-input': '#252a4a',
    '--color-text-footer': '#42434e',
    '--color-border-main': '#eaeaea',
    '--color-primary': '#ff481c',
    '--color-secondary': '#d3d3d3',
    '--color-btn': '#FFFFFF',
    '--color-btn-text': '#FFFFFF',
    '--color-btn-back': '',
    '--color-btn-bg': '#0D1915',
    '--color-btn-border': '#ffffff',
    '--color-connect-btn': '#34353e',
    '--color-connect-background': 'linear-gradient(115.24deg,#333334 16.02%,#293336 83.98%)',
    '--color-connect-btn-hover-background': 'linear-gradient(115.24deg,#44946E 16.02%,#2C7352 83.98%)',
    '--color-connect-btn-hover-borderImage': 'linear-gradient(60deg,#FFF48E,#5FFFAA)',
    '--color-connect-btn-hover': '#334745',
    '--color-connect-btn-border': '#282932',
    '--color-connect-btn-hover-border': '#6BD89E',
    '--color-connect-btn-hover-row': '#2D2D2D',
    '--color-pools-text': '#FFFFFF',
    '--color-pools-line': '#3e3e46',
    '--color-pools-table-header-text': '#939498',
    '--color-pools-table-text': '#D4D4D6',
    '--color-pools-datetime': '#7e7e84',
    '--color-pools-table-hover-text': '#ffffff',
    '--color-pools-btn-hover-row': 'linear-gradient(270deg, #44946E 0%, #2C7352 100%)',
    '--color-pool-modal-bg': '#172522',
    '--color-pool-modal-input-placeholder': '#36403F',
    '--color-tab-btn-color': ' #3d3e46',
    '--color-table-line': 'rgba(255, 255, 255, 0.1)',
    '--color-warning-label-bg': '#000000',
    '--color-hint-bg': '#3D3E46',
    '--color-card-coin-name': '#eec76a',
    '--color-card-coin-name-liquidation': 'rgba(46, 59, 56,1)',
    '--color-card-hr': '#3E3E46',
    '--color-card-apy-bg': '#101C18',
    '--color-card-bg': '#172522',
    '--color-bg-renew': 'linear-gradient(180deg, rgba(21, 28, 25, 0.83) 0%, #16241f 29%, #1a382d 100%)',
    '--color-text-top': '#28302e',
    '--color-text-top-light': '#ffffff',
    '--color-token-btn': '#000000',
    '--color-collateral-enable': '#FFFFFF',
    '--filter-blur-brightness': '0.4',
    '--color-card-bg-new': '#0D1915',
    '--color-bg-icon-new': '#20342C',
    '--color-border-new': 'rgba(255, 255, 255, 0.1)',
    '--color-font-new': '#FFFFFF',
    '--color-font-number': '#D1D3D3',
    '--color-font-tab': '#ffffff',
    '--color-font-label': '#D4D4D6',
    '--color-more-border-new': 'rgba(69, 205, 133, 0.3)',
    '--color-more-bg-new': 'rgba(69, 205, 133, 0.1)',
    '--color-more-font-new': '#3541FF',
    '--color-market-border-new': 'rgba(255, 255, 255, 0.06)',
    '--color-bg-unchecked': '#4D4D4D',
    '--opacity-btn-black': '#1e2c29',
    '--color-border': '#343E39',
    '--color-bg-drawer': '#182A24',
    '--color-scrollbar': '#E1E7E3',
    '--color-btn-green-bg': '#0D9E61',
    '--color-btn-green-text': '#ffffff',
    '--color-btn-green-next-bg': '#39624C',
    '--color-btn-green-next-text': '#0D9E51',
    '--color-btn-green-disabled-bg': '#39624C',
    '--color-btn-green-disabled-text': '#FFFFFF',
    '--color-btn-yellow-bg': '#FFC700',
    '--color-btn-yellow-text': '#FFFFFF',
    '--color-btn-yellow-next-bg': '#3D3911',
    '--color-btn-yellow-next-text': '#E18700',
    '--color-btn-yellow-disabled-bg': '#3D3911',
    '--color-btn-yellow-disabled-text': '#FFFFFF',
    '--color-hover-row': '#252926',
    '--color-translucent-text': 'rgba(255,255,255, 0.6)',
    '--color-translucent-border': 'rgba(255,255,255, 0.1)',
    ...vars,
  },
  light: {
    '--color-white': '#333333',
    '--color-black': '#FFFFFF',
    '--color-orange': '#ff4a21',
    '--color-pink': '#DE4869',
    '--color-yellow': '#eec76a',
    '--color-purple': '#906de5',
    '--color-red': '#f9053e',
    '--color-green': '#fffff',
    '--color-blue': '#039be5',
    '--color-dark-green': '#3541FF',
    '--color-dark-grey': '#666771',
    '--color-modal-white': '#ffffff',
    /* Background colors */
    '--color-bg-main': '#F0F5FF',
    '--color-bg-primary': '#FFFFFF',
    '--color-bg-primary-check': '#D8DBEF',
    '--color-bg-black': '#F7FAFF',
    '--color-bg-white': '#000000',
    '--color-bg-active': '#5FD495',
    '--color-disConnect-btn-color': '#ffffff',
    '--color-disConnect-btn-background': '#5FD495',
    '--color-bg-new-line-active': '#DFE6F4',
    '--color-bg-new-active': '#E5ECF9',
    '--color-bg-new-inactive': '#F0F5FF',
    '--color-bg-sidebar': '#282932',
    '--color-bg-icon': 'rgba(108, 216, 158, 1)',
    '--color-bg-table': '#FFFFFF',
    '--color-pools-bg-table': '#FFFFFF',
    '--color-bg-btn': '#FFFFFF',
    '--color-btn-text-t': '#868C8A',
    /* Typography colors */
    '--color-text-main': '#ffffff',
    '--color-text-sidebar': '#3541FF',
    '--color-text-secondary': '#5E5E5E',
    '--color-text-inactive': '#A1A095',
    '--color-text-red': '#f9053e',
    '--color-text-green': '#3541FF',
    '--color-text-blue': '#2fe29b',
    '--color-text-gray': '#899893',
    '--color-text-desc': '#494949',
    '--color-input': '#DAD5B5',
    '--color-text-footer': '#BDBCB1  ',
    '--color-border-main': '#eaeaea',
    '--color-primary': '#ff481c',
    '--color-secondary': '#d3d3d3',
    '--color-btn': '#FDE774',
    '--color-btn-text': '#373A39',
    '--color-btn-back': '#FDE774',
    '--color-btn-bg': '#ffffff',
    '--color-btn-border': '#e5e5e5',
    '--color-connect-btn': '#D8E1F3',
    '--color-connect-background': 'linear-gradient(115.24deg,#F8F5E0 16.02%,#D7F6E5 83.98%)',
    '--color-connect-btn-hover-background': 'linear-gradient(60deg,#64FFC7,#68FF89)',
    '--color-connect-btn-hover-borderImage': 'linear-gradient(60deg,#10CD00,#10CD00)',
    '--color-connect-btn-hover': '#ffffff',
    '--color-connect-btn-border': '#fff',
    '--color-connect-btn-hover-border': '#10CD00',
    '--color-connect-btn-hover-row': '#EBF4EF',
    '--color-pools-text': '#333333',
    '--color-pools-line': '#E5E5E5',
    '--color-pools-table-header-text': '#868C8A',
    '--color-pools-table-text': '#333333',
    '--color-pools-datetime': '#333333',
    '--color-pools-table-hover-text': '#ffffff',
    '--color-pools-btn-hover-row': 'linear-gradient(270deg, #44946E 0%, #2C7352 100%)',
    '--color-pool-modal-bg': '#FFFFFF',
    '--color-pool-modal-input-placeholder': '#BDC6C5',
    '--color-tab-btn-color': '#F1F2F7',
    '--color-table-line': 'rgba(40,41,50,0.1)',
    '--color-warning-label-bg': '#F1F2F7',
    '--color-hint-bg': '#D7DBF2',
    '--color-card-coin-name': '#EEEEEE',
    '--color-card-coin-name-liquidation': 'rgba(238, 238, 238,1)',
    '--color-card-hr': '#e5e5e5',
    '--color-card-apy-bg': '#F6F6F6',
    '--color-card-bg': '#FFFFFF',
    '--color-bg-renew': '#edeef7',
    '--color-text-top': '#ffffff',
    '--color-text-top-light': '#333333',
    '--color-token-btn': '#8AE3B2',
    '--color-collateral-enable': '#333333',
    '--filter-blur-brightness': '1',
    '--color-card-bg-new': '#FFFFFF',
    '--color-bg-icon-new': '#DDF7EE',
    '--color-border-new': '#E5E5E5',
    '--color-font-new': '#868C8A',
    '--color-font-number': '#4A5750',
    '--color-font-tab': '#4A5750',
    '--color-font-label': '#4A5750',
    '--color-more-border-new': 'rgba(69, 205, 133, 0.3)',
    '--color-more-bg-new': '#3541FF',
    '--color-more-font-new': '#ffffff',
    '--color-market-border-new': '#E5E5E5',
    '--color-bg-unchecked': '#D9D9D9',
    '--opacity-btn-black': '#F1F1F1',
    '--color-border': '#E5E5E5',
    '--color-bg-drawer': '#ffffff',
    '--color-scrollbar': '#2D2D2D',
    '--color-btn-green-bg': '#3541FF',
    '--color-btn-green-text': '#ffffff',
    '--color-btn-green-next-bg': '#EDF3FC',
    '--color-btn-green-next-text': '#3541FF',
    '--color-btn-green-disabled-bg': '#39624C',
    '--color-btn-green-disabled-text': '#FFFFFF',
    '--color-btn-yellow-bg': '#FFC700',
    '--color-btn-yellow-text': '#FFFFFF',
    '--color-btn-yellow-next-bg': '#FFF1A8',
    '--color-btn-yellow-next-text': '#E18700',
    '--color-btn-yellow-disabled-bg': '#3D3911',
    '--color-btn-yellow-disabled-text': '#FFFFFF',
    '--color-hover-row': '#EBF4EF',
    '--color-translucent-text': '#868C8A',
    '--color-translucent-border': '#E5E5E5',
    ...vars,
  },
};
