export const LANG_LIST = [
  { value: 'en', label: 'English' },
  { value: 'id', label: 'Bahasa Indonesia' },
  { value: 'ja', label: '日本語' },
  { value: 'ko', label: '한국인' },
  { value: 'ru', label: 'Русский' },
  { value: 'tl', label: 'Tagalog' },
  { value: 'tr', label: 'Türkçe' },
  { value: 'vi', label: 'Tiếng Việt' },
];
