import styled from 'styled-components';

function getWidthString(span) {
  if (!span) return '';

  const width = (span / 12) * 100;
  return `width: ${width}%;`;
}
const DashboardWrapper = styled.div`
  height: 100%;
  @media only screen and (max-width: 1440px) {
    margin-top: 0;
  }
`;

const SpinnerWrapper = styled.div`
  height: 85vh;
  width: 100%;

  @media only screen and (max-width: 1440px) {
    height: 70vh;
  }
`;

const Row = styled.div`
  width: 100%;
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

const Column = styled.div`
  float: left;
  ${({ xs }) => (xs ? getWidthString(xs) : 'width: 100%')};

  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidthString(sm)};
  }

  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidthString(md)};
  }

  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }
`;

const SupplyWrap = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-card-bg-new']};
  // background: linear-gradient(170deg, #53d49c 0%, #7ae17c 100%);
  padding: 25px 18px;
`;

export { DashboardWrapper, SpinnerWrapper, Row, Column, SupplyWrap };
