import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { CircleProgressBarWrapper } from './style';

function CircleProgressBar({ label, percent, width }) {
  return (
    <CircleProgressBarWrapper>
      <Progress
        type="circle"
        // strokeColor={{
        //   '0%': '#45cd85',
        //   '100%': '#ACEBC2',
        // }}
        strokeColor="#ACEBC2"
        strokeWidth={10}
        strokeLinecap="square"
        trailColor="#ACEBC2"
        percent={100}
        width={width}
        showInfo={false}
      />
      <div className="circle-label">
        <p className={percent < 0 ? 'percent-red' : 'percent'}>{percent}%</p>
        <p className="label">{label}</p>
      </div>
    </CircleProgressBarWrapper>
  );
}

CircleProgressBar.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  percent: PropTypes.number,
  width: PropTypes.any,
};

CircleProgressBar.defaultProps = {
  label: 'Default Label',
  percent: 0.0,
  width: 120,
};

export default CircleProgressBar;
