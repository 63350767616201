// import Web3 from 'web3';
// eslint-disable-line import/no-unresolved
import { CONTRACT_BASE_TOKEN, CONTRACT_CBASE_ABI, CONTRACT_CTOKEN_ADDRESS } from 'constants/address';
import { networkInfo } from 'constants/network';
import { getWeb3 } from './contractService';

export const sendSupply = async (from, amount, networkName, callback) => {
  const web3 = getWeb3();
  try {
    const contract = new web3.eth.Contract(
      JSON.parse(CONTRACT_CBASE_ABI),
      CONTRACT_CTOKEN_ADDRESS[networkName][CONTRACT_BASE_TOKEN[networkName]].address
    );
    const contractData = contract.methods.mint().encodeABI();

    const tx = {
      from,
      to: CONTRACT_CTOKEN_ADDRESS[networkName][CONTRACT_BASE_TOKEN[networkName]].address,
      value: amount,
      data: contractData,
    };

    await web3.eth.sendTransaction(tx, (err) => {
      if (!err) {
        callback(true);
      }
      callback(false);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    callback(false);
  }
};

export const sendRepay = async (from, amount, networkName, callback) => {
  const web3 = getWeb3();
  try {
    const contract = new web3.eth.Contract(
      JSON.parse(CONTRACT_CBASE_ABI),
      CONTRACT_CTOKEN_ADDRESS[networkName][CONTRACT_BASE_TOKEN[networkName]].address
    );
    const contractData = contract.methods.repayBorrow().encodeABI();

    const tx = {
      from,
      to: CONTRACT_CTOKEN_ADDRESS[networkName][CONTRACT_BASE_TOKEN[networkName]].address,
      value: amount,
      data: contractData,
    };
    // Send transaction
    await web3.eth.sendTransaction(tx, (err) => {
      if (!err) {
        callback(true);
      }
      callback(false);
    });
  } catch (err) {
    // eslint-disable-next-line no-console
    callback(false);
  }
};
