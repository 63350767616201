import { createAction } from 'redux-actions';

export const CHANGE_LANGUAGE = '@ui/changeLanguage';
export const CHANGE_THEME = '@ui/changeTheme';
export const CHANGE_STATUS = '@ui/changeStatus';

export const uiActionCreators = {
  changeLanguage: createAction(CHANGE_LANGUAGE),
  changeTheme: createAction(CHANGE_THEME),
  changeStatus: createAction(CHANGE_STATUS),
};
