import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};
  .close-btn {
    position: absolute;
    top: 23px;
    right: 23px;

    &:hover {
      filter: invert(100%);
    }
  }
  .modal-header {
    padding-top: 40px !important;
  }

  .logo-text {
    width: 90px;
  }
  .title {
    margin-top: 40px;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme['--color-collateral-enable']};
  }

  .voting-spinner {
    color: #3541ff;
    margin: 40px 0 20px 0;
  }

  .confirm-text {
    font-size: 14px;
    line-height: 14px;
    color: #868c8a;
    margin-bottom: ${dim(40)};
  }
`;
export { ModalContent };
