/* eslint-disable no-console */
import Web3 from 'web3';
import store from 'store/store';
import {
  CONTRACT_CAI_TOKEN_ABI,
  CONTRACT_CAI_CONTROLLER_ABI,
  CONTRACT_CAI_VAULT_ABI,
  CONTRACT_VAULT_ABI,
  CONTRACT_FLD_ABI,
  CONTRACT_ERC20_TOKEN_ABI,
  CONTRACT_CTOKEN_ABI,
  CONTRACT_CBASE_ABI,
  CONTRACT_COMPTROLLER_ABI,
  CONTRACT_PRICE_ORACLE_ABI,
  CONTRACT_VOTE_ABI,
  CONTRACT_INTEREST_MODEL_ABI,
  CONTRACT_FLD_LENS_ABI,
  CONTRACT_VETOKEN_ABI,
  CONTRACT_FLDIZI_ABI,
} from 'constants/address';

export const web3Instance = { value: null };
// singleton instance
export const getWeb3 = () => {
  return web3Instance.value;
};

const call = (method, params) => {
  try {
    return method(...params).call();
  } catch (err) {
    console.log(err);
    return Promise.reject();
  }
};

const send = (method, params, from) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCaiTokenContract = (CONTRACT_CAI_TOKEN_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_TOKEN_ABI), CONTRACT_CAI_TOKEN_ADDRESS);
};

export const getCaiControllerContract = (CONTRACT_CAI_UNITROLLER_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_CONTROLLER_ABI), CONTRACT_CAI_UNITROLLER_ADDRESS);
};

export const getCaiVaultContract = (CONTRACT_CAI_VAULT_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_VAULT_ABI), CONTRACT_CAI_VAULT_ADDRESS);
};

export const getVaultContract = (CONTRACT_VAULT_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_VAULT_ABI), CONTRACT_VAULT_ADDRESS);
};

export const getTokenContract = (name, CONTRACT_TOKEN_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(
    JSON.parse(name === 'fld' ? CONTRACT_FLD_ABI : CONTRACT_ERC20_TOKEN_ABI),
    CONTRACT_TOKEN_ADDRESS[name].address
  );
};

export const getCTokenContract = (name, CONTRACT_CTOKEN_ADDRESS) => {
  const instance = getWeb3();
  const contract = new instance.eth.Contract(
    JSON.parse(name !== 'native' ? CONTRACT_CTOKEN_ABI : CONTRACT_CBASE_ABI),
    CONTRACT_CTOKEN_ADDRESS[name]?.address
  );
  return contract;
};

export const getComptrollerContract = (CONTRACT_COMPTROLLER_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_COMPTROLLER_ABI), CONTRACT_COMPTROLLER_ADDRESS);
};

export const getPriceOracleContract = (CONTRACT_PRICE_ORACLE_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_PRICE_ORACLE_ABI), CONTRACT_PRICE_ORACLE_ADDRESS);
};

export const getVoteContract = (CONTRACT_VOTE_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_VOTE_ABI), CONTRACT_VOTE_ADDRESS);
};

export const getInterestModelContract = (address) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_INTEREST_MODEL_ABI), address);
};

export const getFldLensContract = (CONTRACT_FLD_LENS_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_FLD_LENS_ABI), CONTRACT_FLD_LENS_ADDRESS);
};

export const methods = {
  call,
  send,
};
