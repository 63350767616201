import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { getTokenContract, methods } from 'utilities/contractService';
import { CONTRACT_TOKEN_ADDRESS } from 'constants/address';
import greenCheckImg from 'assets/img/green-check.png';
import arrowRightImg from 'assets/img/arrow-right.png';
import closeImg from 'assets/img/close.png';
import DelegationVoting from './delegationVoting';
import ManualVoting from './manualVoting';
import { ModalContent } from './style';

function DelegationTypeModal({ address, balance, delegateStatus, visible, onCancel }) {
  const [child, setChild] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleVoting = (dAddress) => {
    setIsLoading(true);
    const tokenContract = getTokenContract('fld', CONTRACT_TOKEN_ADDRESS);
    methods
      .send(tokenContract.methods.delegate, [dAddress || address], address)
      .then(() => {
        setIsLoading(false);
        onCancel();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      className="connect-modal"
      width={400}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
      afterClose={() => setChild('')}
    >
      <ModalContent className="flex flex-column align-center just-center">
        <img
          className={`go-back pointer ${child ? '' : 'hidden'}`}
          src={arrowRightImg}
          alt="left arrow"
          onClick={() => setChild('')}
        />
        <img className="close-btn pointer" src={closeImg} alt="close" onClick={onCancel} />
        <div className={`${child ? 'hidden' : ''}`}>
          <div className="flex align-center just-center header-content">
            <p>Choose Delegation Type</p>
          </div>
          <div
            className="flex flex-column section"
            onClick={(e) => {
              if (delegateStatus === 'self') {
                e.preventDefault();
                return;
              }
              setChild('manual');
              handleVoting('');
            }}
          >
            <div className="flex align-center just-between">
              <div className="flex align-center">
                <img src={greenCheckImg} alt="check" className="check-image" />
                <span>Manual Voting</span>
              </div>
              {delegateStatus !== 'self' ? (
                <img src={arrowRightImg} alt="arrow" className="arrow-image" />
              ) : (
                <p className="active-delegate">Active</p>
              )}
            </div>
            <div className="description">
              This option allows you to vote on proposals directly from your connected wallet.
            </div>
          </div>
          <div
            className="flex flex-column section"
            onClick={() => {
              setChild('delegate');
            }}
          >
            <div className="flex align-center just-between">
              <div className="flex align-center">
                <img src={greenCheckImg} alt="check" className="check-image" />
                <span>Delegate Voting</span>
              </div>
              {delegateStatus !== 'delegate' ? (
                <img src={arrowRightImg} alt="arrow" className="arrow-image" />
              ) : (
                <p className="active-delegate">Active</p>
              )}
            </div>
            <div className="description">
              This option allows you to delegate your votes to another Ethereum address. You never send Fld, only your
              voting rights, and can undelegate at any time.
            </div>
          </div>
        </div>
        {child === 'delegate' && <DelegationVoting isLoading={isLoading} onDelegate={handleVoting} />}
        {child === 'manual' && <ManualVoting isLoading={isLoading} balance={balance} address={address} />}
      </ModalContent>
    </Modal>
  );
}

DelegationTypeModal.propTypes = {
  address: PropTypes.string,
  balance: PropTypes.string,
  visible: PropTypes.bool,
  delegateStatus: PropTypes.string,
  onCancel: PropTypes.func,
};

DelegationTypeModal.defaultProps = {
  address: '',
  balance: PropTypes.string,
  visible: false,
  delegateStatus: '',
  onCancel: () => {},
};

export default DelegationTypeModal;
