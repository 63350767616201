import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { Icon } from 'antd';
import { connectAccount } from 'modules';
import Button from '@material-ui/core/Button';
import { getComptrollerContract, methods, getFldLensContract } from 'utilities/contractService';
import { EXPLORER_LINK, CONTRACT_FLD_LENS_ADDRESS, CONTRACT_COMPTROLLER_ADDRESS } from 'constants/address';
import DelegationTypeModal from 'components/common/delegationTypeModal';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Card } from 'components/common/style';
import coinImg from 'assets/img/fid-64.png';
import { formatNumber, getBigNumber } from 'utilities/number';
import { VotingWalletWrapper } from './style';

function VotingWallet({ balance, settings, earnedBalance, caiMint, delegateAddress, delegateStatus }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEarn, setIsLoadingEarn] = useState(false);

  useEffect(() => {
    if (!earnedBalance) {
      setIsLoadingEarn(true);
      return;
    }
    setIsLoadingEarn(false);
  }, [earnedBalance]);

  const getBefore = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(0, position + 5) : value;
  };

  const getAfter = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(position + 5) : null;
  };

  const handleCollect = async () => {
    const { assetList } = settings;

    // filter out tokens that users have positive balance to save gas cost by 'claimFld'
    const fldLensContract = getFldLensContract(CONTRACT_FLD_LENS_ADDRESS[settings.networkName]);
    const cTokensBalanceInfos = await methods.call(fldLensContract.methods.cTokenBalancesAll, [
      assetList.map((asset) => asset.ctokenAddress),
      settings.selectedAddress,
    ]);

    const outstandingCTokens = cTokensBalanceInfos.filter((info) => {
      // info[2]: borrowBalanceCurrent, info[3]: balanceOfUnderlying
      return getBigNumber(info[2]).gt(0) || getBigNumber(info[3]).gt(0);
    });

    if (+earnedBalance !== 0 || +caiMint !== 0) {
      setIsLoading(true);
      const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
      methods
        .send(
          appContract.methods.claimFld,
          [settings.selectedAddress, outstandingCTokens.map((token) => token[0])],
          settings.selectedAddress
        )
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      <VotingWalletWrapper>
        <div className="flex align-center header">
          <p className="title">Voting Wallet</p>
        </div>
        <div className="flex flex-column content">
          <p className="content-label">Fld Balance</p>
          <div className="flex align-center just-between">
            <div className="flex align-center">
              <img src={coinImg} alt="coin" />
              <p className="content-value">
                {getBefore(formatNumber(balance))}
                <span>{getAfter(formatNumber(balance))}</span>
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-column content">
          {isLoadingEarn && <LoadingSpinner />}
          {!isLoadingEarn && (
            <div className="flex align-center just-between">
              <div className="flex flex-column">
                <p className="content-label">Fld Earned</p>
                <div className="flex align-center">
                  <img src={coinImg} alt="coin" />
                  <p className="content-value">
                    {getBefore(formatNumber(earnedBalance))}
                    <span>{getAfter(formatNumber(earnedBalance))}</span>
                  </p>
                </div>
                <div className="mint-content-label">CAI Mint Earned</div>
                <div className="flex align-center">
                  <img src={coinImg} alt="coin" />
                  <p className="content-value">
                    {getBefore(formatNumber(caiMint))}
                    <span>{getAfter(formatNumber(caiMint))}</span>
                  </p>
                </div>
              </div>
              {settings.selectedAddress && (
                <div className="flex align-center">
                  <p className="pointer" onClick={handleCollect}>
                    Collect
                  </p>
                </div>
              )}
            </div>
          )}
        </div>
        {delegateStatus && (
          <div className="flex flex-column content delegate-change">
            <p className="content-label">Delegating To</p>
            <div className="flex align-center just-between">
              <div className="flex align-center">
                <a
                  className="content-value"
                  href={`${EXPLORER_LINK[settings.networkName]}/address/${delegateAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {delegateStatus === 'self'
                    ? 'Self'
                    : `${delegateAddress.substr(0, 4)}...${delegateAddress.substr(delegateAddress.length - 4, 4)}`}
                </a>
              </div>
              <div className="flex align-center">
                <p className="change pointer" onClick={() => setIsOpenModal(true)}>
                  Change
                </p>
              </div>
            </div>
          </div>
        )}
        {settings.selectedAddress && !delegateStatus && (
          <div className="flex flex-column setup">
            <p className="setup-header">Setup Voting</p>
            <p className="setup-content">
              You can either vote on each proposal yourself or delegate your votes to a third party. Fld Governance puts
              you in charge of the future of Fld.
              {/* <a href="/#">Learn more.</a> */}
            </p>
          </div>
        )}
        {settings.selectedAddress && !delegateStatus && (
          <div className="center footer">
            <Button className="started-btn" onClick={() => setIsOpenModal(true)}>
              Get Started
            </Button>
          </div>
        )}
        <DelegationTypeModal
          visible={isOpenModal}
          balance={balance}
          delegateStatus={delegateStatus}
          address={settings.selectedAddress ? settings.selectedAddress : ''}
          onCancel={() => setIsOpenModal(false)}
        />
      </VotingWalletWrapper>
    </Card>
  );
}

VotingWallet.propTypes = {
  balance: PropTypes.string.isRequired,
  earnedBalance: PropTypes.string.isRequired,
  caiMint: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  delegateAddress: PropTypes.string.isRequired,
  delegateStatus: PropTypes.string.isRequired,
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(VotingWallet);
