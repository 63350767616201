import en from './en.json';
import id from './id.json';
import ja from './ja.json';
import ko from './ko.json';
import ru from './ru.json';
import tl from './tl.json';
import tr from './tr.json';
import vi from './vi.json';

export const langCollection = {
  en,
  id,
  ja,
  ko,
  ru,
  tl,
  tr,
  vi,
};
