import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { Label } from 'components/common/style';
import BorrowModal from 'components/common/borrowModal';
import MarketTable from 'components/common/table';
import { formatNumber, getBigNumber } from 'utilities/number';

import { BorrowMarketWrapper } from './style';

function BorrowMarket({ borrowedAssets, remainAssets, settings }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [record, setRecord] = useState({});

  const handleClickRow = (row) => {
    setRecord(row);
    setIsOpenModal(true);
  };

  const remainColumns = [
    {
      title: <FormattedMessage id="Asset" />,
      dataIndex: 'img',
      key: 'img',
      render(img, asset) {
        return {
          children: (
            <div className="flex align-center">
              <img src={asset.img} alt="ethereum" />
              <div className="flex flex-column align-start">
                <Label size="14" primary>
                  {asset.displayName}
                </Label>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Borrow APY" />,
      dataIndex: 'borrowApy',
      key: 'borrowApy',
      render(borrowApy, asset) {
        const apy = settings.withFLD ? getBigNumber(asset.fldBorrowApy).plus(borrowApy) : borrowApy;
        return {
          children: (
            <div className="apy-content">
              {/* {!settings.withFLD || apy.isNegative() ? (
                <Icon type="arrow-down" style={{ color: '#f9053e' }} />
              ) : (
                <Icon type="arrow-up" />
              )} */}
              <div className={!settings.withFLD || apy.isNegative() ? 'apy-red-label' : 'apy-green-label'}>
                {formatNumber(apy, true, 2, apy.dp(2, 1) > 0 ? '+' : '', '%')}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Wallet Balance" />,
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      render(walletBalance, asset) {
        return {
          children: (
            <Label size="14" primary>
              {formatNumber(walletBalance, true, 5, '$')} {asset.symbol}
            </Label>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Liquidity" className="liquidity" />,
      dataIndex: 'liquidity',
      key: 'liquidity',
      render(liquidity) {
        return {
          children: (
            <Label size="14" primary>
              {formatNumber(liquidity, true, 2, '$')}
            </Label>
          ),
        };
      },
    },
  ];

  const borrowColumns = [
    {
      title: <FormattedMessage id="Asset" />,
      dataIndex: 'img',
      key: 'img',
      render(img, asset) {
        return {
          children: (
            <div className="flex align-center">
              <img src={asset.img} alt="ethereum" />
              <div className="flex flex-column align-start">
                <Label size="14" primary>
                  {asset.displayName}
                </Label>
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Borrow APY" />,
      dataIndex: 'borrowApy',
      key: 'borrowApy',
      render(borrowApy, asset) {
        const apy = settings.withFLD ? getBigNumber(asset.fldBorrowApy).plus(borrowApy) : borrowApy;
        return {
          children: (
            <div className="apy-content">
              {/* {!settings.withFLD || apy.isNegative() ? (
                <Icon type="arrow-down" style={{ color: '#f9053e' }} />
              ) : (
                <Icon type="arrow-up" />
              )} */}
              <div className={!settings.withFLD || apy.isNegative() ? 'apy-red-label' : 'apy-green-label'}>
                {formatNumber(apy, true, 2, apy.dp(2, 1) > 0 ? '+' : '', '%')}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Borrow Balance" />,
      dataIndex: 'borrowBalance',
      key: 'borrowBalance',
      render(borrowBalance, asset) {
        return {
          children: (
            <div className="wallet-label flex flex-column">
              <Label size="14" primary>
                {formatNumber(borrowBalance.times(asset.tokenPrice), true, 2, '$')}
              </Label>
              <Label size="14">
                {formatNumber(borrowBalance, true, 5)} {asset.symbol}
              </Label>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="% Of Limit" />,
      dataIndex: 'percentOfLimit',
      key: 'percentOfLimit',
      render(percentOfLimit) {
        return {
          children: <Label size="14">{formatNumber(percentOfLimit, true, 2, '', '%')}</Label>,
        };
      },
    },
  ];

  return (
    <BorrowMarketWrapper>
      {borrowedAssets.length === 0 && remainAssets.length === 0 && <LoadingSpinner />}
      {borrowedAssets.length > 0 && (
        <MarketTable
          columns={borrowColumns}
          data={borrowedAssets}
          // title={<FormattedMessage id="Borrowed" />}
          handleClickRow={handleClickRow}
        />
      )}
      {remainAssets.length > 0 && (
        <MarketTable
          columns={remainColumns}
          data={remainAssets}
          title={<FormattedMessage id="All Markets" />}
          handleClickRow={handleClickRow}
        />
      )}
      <BorrowModal visible={isOpenModal} asset={record} onCancel={() => setIsOpenModal(false)} />
    </BorrowMarketWrapper>
  );
}

BorrowMarket.propTypes = {
  borrowedAssets: PropTypes.array,
  remainAssets: PropTypes.array,
  settings: PropTypes.object.isRequired,
};

BorrowMarket.defaultProps = {
  borrowedAssets: [],
  remainAssets: [],
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps))(BorrowMarket);
