import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Row, Col, Icon, Progress } from 'antd';
import { connectAccount, accountActionCreators } from 'modules';
import { getTokenContract, getComptrollerContract, methods } from 'utilities/contractService';
import MainLayout from 'components/layout/wrap';
import LoadingSpinner from 'components/common/loadingSpinner';
import {
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_FLD_TOKEN_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  EXPLORER_LINK,
} from 'constants/address';
import fldLogo from 'assets/img/fid-64.png';
import caiImg from 'assets/img/coins/cai.png';
import { formatNumber, getBigNumber } from 'utilities/number';
import { FLDLayout, FLDWrapper, SpinnerWrapper, TableWrapper, FLDInfoWrapper } from './style';

function FLD({ settings }) {
  const [markets, setMarkets] = useState([]);
  const [dailyDistribution, setDailyDistribution] = useState('0');
  const [totalDistributed, setTotalDistributed] = useState('0');
  const [remainAmount, setRemainAmount] = useState('0');
  const [sortInfo, setSortInfo] = useState({ field: '', sort: 'desc' });
  const [isLoading, setIsLoading] = useState(false);
  let canShowPage = settings.selectedAddress && !settings.accountLoading && !settings.wrongNetwork;

  const mintedAmount = '23700000';

  const getFLDInfo = async () => {
    const tempMarkets = [];
    const sum = (settings.markets || []).reduce((accumulator, market) => {
      return getBigNumber(accumulator).plus(getBigNumber(market.totalDistributed));
    }, 0);
    const compContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);

    // total info
    let fldCAIVaultRate = await methods.call(compContract.methods.fairlendCAIVaultRate, []);
    fldCAIVaultRate = getBigNumber(fldCAIVaultRate)
      .div(1e18)
      .times(20 * 60 * 24);
    const tokenContract = getTokenContract('fld', CONTRACT_TOKEN_ADDRESS[settings.networkName]);
    const remainedAmount = await methods.call(tokenContract.methods.balanceOf, [
      CONTRACT_COMPTROLLER_ADDRESS[settings.networkName],
    ]);
    setDailyDistribution(
      getBigNumber(settings.dailyFld).div(getBigNumber(10).pow(18)).plus(fldCAIVaultRate).dp(2, 1).toString(10)
    );
    setTotalDistributed(sum.toString(10));
    setRemainAmount(getBigNumber(remainedAmount).div(getBigNumber(10).pow(18)).dp(2, 1).toString(10));
    for (let i = 0; i < settings.markets.length; i += 1) {
      tempMarkets.push({
        underlyingSymbol: settings.markets[i].underlyingSymbol,
        perDay: +getBigNumber(settings.markets[i].supplierDailyFld)
          .plus(getBigNumber(settings.markets[i].borrowerDailyFld))
          .div(getBigNumber(10).pow(18))
          .dp(2, 1)
          .toString(10),
        supplyAPY: +(getBigNumber(settings.markets[i].supplyFldApy).isLessThan(0.01)
          ? '0.01'
          : getBigNumber(settings.markets[i].supplyFldApy).dp(2, 1).toString(10)),
        borrowAPY: +(getBigNumber(settings.markets[i].borrowFldApy).isLessThan(0.01)
          ? '0.01'
          : getBigNumber(settings.markets[i].borrowFldApy).dp(2, 1).toString(10)),
      });
    }
    tempMarkets.push({
      underlyingSymbol: 'CAI',
      perDay: +fldCAIVaultRate.dp(2, 1).toString(10),
      supplyAPY: settings.caiAPY || 0,
      borrowAPY: 0,
    });
    setMarkets(tempMarkets);
  };

  useEffect(() => {
    if (settings.markets && settings.dailyFld) {
      getFLDInfo();
    }
  }, [settings.markets]);

  const handleSort = (field) => {
    setSortInfo({
      field,
      sort: sortInfo.field === field && sortInfo.sort === 'desc' ? 'asc' : 'desc',
    });
  };

  useEffect(() => {
    setIsLoading(settings.selectedAddress && !settings.accountLoading && !settings.wrongNetwork);
  }, [settings.selectedAddress, settings.accountLoading, settings.wrongNetwork]);

  useEffect(() => {
    canShowPage = settings.selectedAddress && !settings.accountLoading && !settings.wrongNetwork;
  }, [settings.selectedAddress, settings.accountLoading, settings.wrongNetwork]);

  return (
    <FLDLayout>
      <MainLayout title="User Distribution">
        <FLDWrapper>
          {!canShowPage ? (
            <SpinnerWrapper>
              <LoadingSpinner />
            </SpinnerWrapper>
          ) : (
            <>
              <FLDInfoWrapper className="flex align-center just-between">
                <div className="flex align-center fld-info">
                  <img src={fldLogo} alt="fld" />
                  <a
                    className="highlight"
                    href={`${EXPLORER_LINK[settings.networkName]}/token/${
                      CONTRACT_FLD_TOKEN_ADDRESS[settings.networkName]
                    }`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {CONTRACT_FLD_TOKEN_ADDRESS[settings.networkName]}
                  </a>
                  <CopyToClipboard text={CONTRACT_FLD_TOKEN_ADDRESS[settings.networkName]} onCopy={() => {}}>
                    <Icon className="pointer copy-btn" type="copy" />
                  </CopyToClipboard>
                </div>
                <div className="flex flex-column distribution-wrapper">
                  <div className="flex align-center just-around info-wrapper">
                    <div className="info-item">
                      <p className="title">Daily Distribution</p>
                      <p className="value">{formatNumber(dailyDistribution)}</p>
                    </div>
                    {/* <div className="info-item">
                      <p className="title">Total Distributed</p>
                      <p className="value">{formatNumber(totalDistributed)}</p>
                    </div> */}
                    <div className="info-item">
                      <p className="title">Remaining</p>
                      <p className="value">{formatNumber(remainAmount)}</p>
                    </div>
                  </div>
                  <Progress
                    percent={getBigNumber(totalDistributed)
                      .dividedBy(getBigNumber(mintedAmount))
                      .multipliedBy(100)
                      .toNumber()}
                    strokeColor="#f8b94b"
                    strokeWidth={7}
                    showInfo={false}
                  />
                </div>
              </FLDInfoWrapper>
              <TableWrapper>
                <p className="header-title">Market Distribution</p>
                <Row className="table_header">
                  <Col xs={{ span: 24 }} lg={{ span: 6 }} className="market">
                    Market
                  </Col>
                  <Col xs={{ span: 8 }} lg={{ span: 6 }} className="per-day right">
                    <span onClick={() => handleSort('perDay')}>
                      <img src={fldLogo} alt="fld" /> Per Day{' '}
                      {sortInfo.field === 'perDay' && (
                        <Icon type={sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'} />
                      )}
                    </span>
                  </Col>
                  <Col xs={{ span: 8 }} lg={{ span: 6 }} className="supply-apy right">
                    <span onClick={() => handleSort('supplyAPY')}>
                      Supply
                      <img src={fldLogo} alt="fld" />
                      APY{' '}
                      {sortInfo.field === 'supplyAPY' && (
                        <Icon type={sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'} />
                      )}
                    </span>
                  </Col>
                  <Col xs={{ span: 8 }} lg={{ span: 6 }} className="borrow-apy right">
                    <span onClick={() => handleSort('borrowAPY')}>
                      Borrow
                      <img src={fldLogo} alt="fld" />
                      APY{' '}
                      {sortInfo.field === 'borrowAPY' && (
                        <Icon type={sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'} />
                      )}
                    </span>
                  </Col>
                  {/* <Col xs={{ span: 6 }} lg={{ span: 4 }} className="total-distributed right">
                    Total Distributed
                  </Col> */}
                </Row>
                <div className="table_content">
                  {markets &&
                    (markets || [])
                      .sort((a, b) => {
                        if (sortInfo.field) {
                          if (sortInfo.field === 'perDay') {
                            return sortInfo.sort === 'desc'
                              ? +getBigNumber(b.perDay).minus(getBigNumber(a.perDay)).toString(10)
                              : +getBigNumber(a.perDay).minus(getBigNumber(b.perDay)).toString(10);
                          }
                          if (sortInfo.field === 'supplyAPY') {
                            return sortInfo.sort === 'desc'
                              ? +getBigNumber(b.supplyAPY).minus(getBigNumber(a.supplyAPY)).toString(10)
                              : +getBigNumber(a.supplyAPY).minus(getBigNumber(b.supplyAPY)).toString(10);
                          }
                          if (sortInfo.field === 'borrowAPY') {
                            return sortInfo.sort === 'desc'
                              ? +getBigNumber(b.borrowAPY).minus(getBigNumber(a.borrowAPY)).toString(10)
                              : +getBigNumber(a.borrowAPY).minus(getBigNumber(b.borrowAPY)).toString(10);
                          }
                        }
                        return +getBigNumber(b.perDay).minus(getBigNumber(a.perDay)).toString(10);
                      })
                      .map((item, index) => (
                        <Row className="table_item pointer" key={index}>
                          <Col xs={{ span: 24 }} lg={{ span: 6 }} className="flex align-center market">
                            {item.underlyingSymbol !== 'CAI' ? (
                              <img
                                className="asset-img"
                                src={CONTRACT_TOKEN_ADDRESS[item.underlyingSymbol.toLowerCase()].asset}
                                alt="asset"
                              />
                            ) : (
                              <img className="cai-img" src={caiImg} alt="asset" />
                            )}
                            <p>{item.underlyingSymbol}</p>
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 6 }} className="per-day right">
                            <p className="mobile-label">Per day</p>
                            <p>{item.perDay}</p>
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 6 }} className="supply-apy right">
                            <p className="mobile-label">Supply APY</p>
                            <p>{item.supplyAPY}%</p>
                          </Col>
                          <Col xs={{ span: 24 }} lg={{ span: 6 }} className="borrow-apy right">
                            <p className="mobile-label">Borrow APY</p>
                            {item.underlyingSymbol !== 'CAI' ? <p>{item.borrowAPY}%</p> : <p>-</p>}
                          </Col>
                          {/* <Col xs={{ span: 24 }} lg={{ span: 4 }} className="total-distributed right">
                            <p className="mobile-label">Total Distributed</p>
                            <p>{formatNumber(item.totalDistributed.toString())}</p>
                          </Col> */}
                        </Row>
                      ))}
                </div>
              </TableWrapper>
            </>
          )}
        </FLDWrapper>
      </MainLayout>
    </FLDLayout>
  );
}

FLD.propTypes = {
  settings: PropTypes.object,
};

FLD.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { getVoterAccounts } = accountActionCreators;

  return bindActionCreators(
    {
      getVoterAccounts,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(FLD);
