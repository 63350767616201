import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const CardWrapper = styled.div`
  width: 100%;
  // height: ${dim(190)};
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-card-bg-new']};
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &::before {
    content: '';
    background-color: #f2f6fc;
    width: 116px;
    height: 116px;
    position: absolute;
    left: -58px;
    top: -58px;
    z-index: 0;
    border-radius: 50%;
  }
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    padding: 20px;
    height: auto;
  }

  .title {
    z-index: 2;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #828d98;
  }

  .img-wrap {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    background-color: ${(props) => props.theme['--color-bg-icon-new']};
    margin-right: 11px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
    }
  }
  .number {
    color: ${(props) => props.theme['--color-font-number']};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  .apply {
    display: flex;
    // flex-direction: column;
    justify-content: center;
    .earth {
      margin-top: 10px;
    }
    .add-mask {
      max-width: 100%;
      width: 100%;
      height: 36px;
      border: 1px solid ${(props) => props.theme['--color-border-new']};
      box-sizing: border-box;
      border-radius: 8px;
      text-align: left;
      padding: 20px;
      color: ${(props) => props.theme['--color-font-new']};
      @media only screen and (max-width: 1009px) {
        width: 127px;
      }
      @media only screen and (max-width: 992px) {
        width: 185px;
      }
      @media only screen and (max-width: 768px) {
        width: auto;
        white-space: nowrap;
      }
      &:hover {
        opacity: 0.7;
        .font {
          cursor: pointer;
          // color: ${(props) => props.theme['--color-yellow']};
          // font-weight: 900;
          // font-size: 15px
        }
      }
      img {
        width: 12px;
        height: 14px;
        margin-right: 9px;
        color: #ffffff;
        // border: 1px solid red;
        &:hover {
          opacity: 0.1s;
        }
      }
      .font {
        opacity: 0.6;
        // margin: 7px 0;
      }
    }
  }
  .add-fld-token {
    font-size: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-yellow']};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  p {
    font-size: 17.5px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    line-height: 1;
    color: ${(props) => props.theme['--color-text-secondary']};
  }

  .copy-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => props.theme['--color-yellow']};
    margin-left: 26px;

    i {
      color: ${(props) => props.theme['--color-text-main']};
      svg {
        transform: rotate(-45deg);
      }
    }
  }
`;

const BtnCard = styled.div`
  flex-basis: 50%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 106px;
  border-radius: 8px;
  padding: 25px 18px;
  margin: 0 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: #828d98;
  text-align: center;
  .ico {
    width: 24px;
    height: 24px;
    margin-bottom: 20px;
  }
  &.green {
    background: #e8f9f7;
  }
  &.pink {
    background: #ffeeee;
  }
  &.yellow {
    background: #fff7f0;
  }
`;
export { CardWrapper, BtnCard };
