import React from 'react';
import PropTypes from 'prop-types';
import { connectAccount } from 'modules';
import { compose } from 'recompose';
import { langCollection } from 'lang/index';
import { IntlProvider } from 'react-intl';

function Lang({ children, ui }) {
  return (
    <IntlProvider locale={ui.lang} messages={langCollection[ui.lang]}>
      {children}
    </IntlProvider>
  );
}

Lang.propTypes = {
  children: PropTypes.node.isRequired,
  ui: PropTypes.object,
};

Lang.defaultProps = {
  ui: {},
};

const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};
export default compose(connectAccount(mapStateToProps, undefined))(Lang);
