import React from 'react';
import PropTypes from 'prop-types';
import { formatNumber, getBigNumber } from 'utilities/number';
import { Card, Row, Column } from 'components/common/style';
import { VotingPowerWrapper } from './style';

function VotingPower({ power }) {
  const getBefore = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(0, position + 5) : value;
  };

  const getAfter = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(position + 5) : null;
  };

  return (
    <Row>
      <Column xs="12" sm="8">
        <Card>
          <VotingPowerWrapper className="flex flex-column">
            <p className="title">Voting Weight</p>
            <p className="content">
              {getBefore(formatNumber(power))}
              <span>{getAfter(formatNumber(power))}</span>
            </p>
          </VotingPowerWrapper>
        </Card>
      </Column>
    </Row>
  );
}

VotingPower.propTypes = {
  power: PropTypes.string,
};

VotingPower.defaultProps = {
  power: '0.00000000',
};

export default VotingPower;
