import styled from 'styled-components';

const ModalContent = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};
  padding: 30px 30px 0;

  .close-btn {
    position: absolute;
    top: 23px;
    right: 23px;
  }

  .go-back {
    position: absolute;
    top: 23px;
    left: 23px;
    transform: rotate(180deg);
  }

  .header-content {
    font-size: 24.5px;
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-main']};
    margin-bottom: 38px;
  }

  .check-image {
    width: 28px;
    margin-right: 15px;
  }

  .arrow-image {
    width: 26px;
    height: 16px;
  }

  .section {
    width: 100%;
    cursor: pointer;
    border-top: 2px solid ${(props) => props.theme['--color-bg-active']};
    padding: 43px 0;

    span {
      color: ${(props) => props.theme['--color-text-main']};
      font-weight: 500;
      font-size: 17px;
    }

    .description {
      font-size: 13.5px;
      font-weight: normal;
      color: ${(props) => props.theme['--color-text-secondary']};
      padding: 12px 26px 0 42px;
    }
    .active-delegate {
      color: ${(props) => props.theme['--color-dark-green']};
    }
  }

  .hidden {
    display: none;
  }
`;

const VotingWrapper = styled.div`
  .close-btn {
    position: absolute;
    top: 23px;
    right: 23px;
  }

  .header-content {
    font-size: 24.5px;
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-main']};
    margin-bottom: 38px;
  }

  .check-image {
    width: 38px;
    margin-right: 25px;
  }

  .arrow-image {
    width: 26px;
    height: 16px;
  }

  .voting-selection {
    width: 100%;
    border-top: 2px solid ${(props) => props.theme['--color-bg-active']};
    padding: 43px 0;

    .address {
      color: ${(props) => props.theme['--color-text-main']};
      font-weight: 500;
      font-size: 17px;
    }

    .leaderboard {
      color: ${(props) => props.theme['--color-text-blue']};
      font-weight: 500;
      font-size: 13.5px;
      cursor: pointer;
    }

    .detail {
      font-size: 13.5px;
      font-weight: normal;
      color: ${(props) => props.theme['--color-text-secondary']};
      padding-top: 12px;
    }

    .ant-input {
      margin-top: 35px;
      height: 48px;
      background-color: #1b2040;
      border: 1px solid #797979;
      font-size: 13.5px;
      text-align: center;
      color: ${(props) => props.theme['--color-text-main']};
    }

    .vote-btn {
      margin-top: 45px;
      width: 100%;
      height: 48px;
      border-radius: 5px;
      background-image: linear-gradient(to right, #45cd85, #7eddaa);
      .MuiButton-label {
        font-size: 13px;
        font-weight: 500;
        color: ${(props) => props.theme['--color-text-main']};
        text-transform: capitalize;
      }
    }
  }
`;

const ManualVotingWrapper = styled.div`
  width: 100%;

  .header-content {
    font-size: 24.5px;
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-main']};
    margin-bottom: 38px;
  }

  .manual-voting-section {
    width: 100%;
    border-top: 2px solid ${(props) => props.theme['--color-bg-active']};
    padding: 43px 0;

    .voting-count {
      color: ${(props) => props.theme['--color-text-main']};
      font-size: 29.5px;
      font-weight: bold;
    }

    .voting-address {
      margin-top: 36px;
      color: ${(props) => props.theme['--color-text-main']};
      font-size: 17px;
      font-weight: 500;
    }

    .voting-spinner {
      margin-top: 63px;
      color: #3541ff;
    }

    .voting-confirm {
      margin-top: 65px;
      color: ${(props) => props.theme['--color-text-secondary']};
      font-size: 13.5px;
      font-weight: normal;
    }
  }
`;
export { ModalContent, VotingWrapper, ManualVotingWrapper };
