import styled from 'styled-components';

function getWidthString(span) {
  if (!span) return '';

  const width = (span / 12) * 100;
  return `width: ${width}%;`;
}

const Row = styled.div`
  width: 100%;
  &::after {
    content: '';
    clear: both;
    display: table;
  }
`;

const Column = styled.div`
  float: left;
  ${({ xs }) => (xs ? getWidthString(xs) : 'width: 100%')};

  @media only screen and (min-width: 768px) {
    ${({ sm }) => sm && getWidthString(sm)};
  }

  @media only screen and (min-width: 992px) {
    ${({ md }) => md && getWidthString(md)};
  }

  @media only screen and (min-width: 1200px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }
`;

const Label = styled.span`
  font-size: ${({ size }) => size || 16}px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  font-weight: 900;
  color: ${(props) => (props.primary ? props.theme['--color-text-main'] : props.theme['--color-text-secondary'])};
`;
const BoldLabel = styled.span`
  font-size: ${({ size }) => size || 16}px;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
  font-weight: 700;
  color: ${(props) => props.theme['--color-font-label']};
`;

const Card = styled.div`
  height: 100%;
  display: flex;
  border-radius: 8px;
  padding: 0px;
  flex-direction: ${({ direction }) => direction};
  justify-content: center;
  overflow: hidden;

  @media only screen and (max-width: 768px) {
    /* margin: 5px 10px; */
  }
`;

const TabSection = styled.div`
  flex-shrink: 0;
  width: 100%;
  .body-content {
    margin: 30px 0;
    @media only screen and (max-width: 768px) {
      margin: 0;
    }
    .input-wrapper {
      position: relative;
      input {
        width: 100%;
        border: none;
        height: 100%;
        font-size: 40px;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
        color: ${(props) => props.theme['--color-yellow']};
        text-align: center;
        background: transparent;
        &:focus {
          outline: none;
        }
      }
      .max {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        right: 0;
        font-weight: 600;
        font-size: 14px;
        color: #4ad3a1;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
          position: unset;
          span {
            margin-right: 0 !important;
          }
        }
        span {
          white-space: nowrap;
          margin-right: 20px;
        }
        .info_img {
          width: 20px;
          height: 20px;
          margin-bottom: 0;
          margin-left: 12px;
        }
      }
    }

    img {
      height: 50px;
      width: 50px;
      margin-bottom: 20px;
    }

    .warning-label {
      position: relative;
      width: 100%;
      max-width: 400px;
      font-size: 15px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
      color: ${(props) => props.theme['--color-text-secondary']};
      padding: 5px 25px 0 45px;
      margin-bottom: 10px;
      background-color: ${(props) => props.theme['--color-warning-label-bg']};
      text-align: left;
      border-radius: 4px;
      // opacity: 0.26;
      &::before {
        position: absolute;
        content: '!';
        color: #979797;
        width: 22px;
        height: 22px;
        background: ${(props) => props.theme['--color-hint-bg']};
        border-radius: 50%;
        left: 17px;
        top: 8px;
        text-align: center;
      }
    }
  }

  .repay-green-color {
    color: #30bd72;
  }

  .mint-cai-content {
    margin: 0;
  }

  .cai-content-section {
    height: 260px;
  }

  .balance-content {
    width: 100%;
    height: 64px;
    @media only screen and (max-width: 768px) {
      flex-wrap: wrap;
      height: 84px;
      justify-content: center;
      padding: 15px 0 0;
      align-items: flex-start;
      .wallet-balance {
        width: 100%;
        text-align: center;
      }
    }
    .wallet-balance {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #939498;
      flex-grow: 2;
    }
    .proportion-wrapper {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #3541ff;
      & > span {
        margin-left: 20px;
      }
    }
    .info_img {
      width: 14px;
      height: 14px;
      margin-bottom: 0;
      margin-left: 4px;
    }
  }
  .apy-content {
    background: ${(props) => props.theme['--color-card-apy-bg']};
    border-radius: 8px;
    width: 100%;
    padding: 20px 10px;
    font-size: 14px;
    font-weight: 600;
    & > div > span:first-child {
      font-weight: 400;
      color: #939498;
    }
    .apy-progress .ant-progress-inner,
    .apy-progress .ant-progress-bg {
      border-radius: 100px 0 0 100px;
      height: 8px !important;
    }
    .arrow-right-img {
      height: 10px;
      width: 15px;
      margin: 0 10px;
    }
    @media only screen and (max-width: 768px) {
      border-radius: 4px;
      padding: 10px;
      .apy-progress .ant-progress-inner,
      .apy-progress .ant-progress-bg {
        border-radius: 100px 0 0 100px;
        height: 4px !important;
      }
    }
  }
  .description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-bg-white']};
    opacity: 0.48;
    width: 100%;
    margin-bottom: 20px;
    &.borrow-caps {
      border-top: 2px solid ${(props) => props.theme['--color-bg-active']};
      padding: 10px 0;
      margin: 0;
    }
  }
  .borrow-balance {
    font-size: 16px;
    color: ${(props) => props.theme['--color-text-main']};
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .borrow-limit {
    // color: ${(props) => props.theme['--color-text-main']};
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  .borrow-limit-used {
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      margin-bottom: 0;
    }
    // color: ${(props) => props.theme['--color-text-main']};
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button {
    margin: 20px 0;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    // background-image: linear-gradient(to right, #45cd85, #b6ff49);
    background: #3541ff;
    &:hover {
      background: #646dff !important;
    }
    .MuiButton-label {
      font-size: 16px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-main']};
      text-transform: capitalize;
    }

    &.cai-auto {
      margin-top: auto;
    }
    @media only screen and (max-width: 768px) {
      height: 44px;
      margin: 30px 0 15px;
      font-size: 14px;
      // background: linear-gradient(270deg, #60ffab 0%, #30bd72 100%);
      background: #3541ff;
    }
  }
  .withdraw {
    background: #3541ff !important;
    color: #fff !important;
    @media only screen and (max-width: 768px) {
      background: linear-gradient(270deg, #ffde8e 0%, #e3b74e 100%) !important;
      // background: #3541ff !important;
    }
  }

  .asset-img {
    height: 30px;
    width: 30px;
    margin-right: 12px;
  }

  .warning-label {
    width: 100%;
    font-size: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-text-secondary']};
    padding: 10px 25px;
  }
`;

const Tabs = styled.div`
  width: 100%;
  height: 88px;
  border-radius: ${({ isLoading }) => (isLoading ? '' : '8px 8px 0 0')};
  padding-top: 14px;
  background-color: #3541ff;
  position: relative;
  &::after {
    content: ${({ activeBar }) => (activeBar === 'none' ? 'none' : `''`)};
    display: block;
    width: 60px;
    height: 4px;
    background: #fff;
    position: absolute;
    bottom: 0;
    border-radius: 42px;
    transition: left 300ms;
    left: ${({ activeBar }) => (activeBar === 'left' ? 'calc(25% - 30px)' : 'calc(75% - 30px)')};
  }
  .tab-item {
    width: 100%;
    height: 41px;
    color: #e7efd5;
    font-weight: 700;
    font-size: 18px;
  }
  .tab-active {
    border-radius: 5px;
    color: #ffffff;
  }
  .ant-progress-inner {
    background-color: ${(props) => props.theme['--color-bg-primary']};
  }
  @media only screen and (max-width: 768px) {
    padding-top: 0;
    height: 64px;
    &::after {
      height: 2px;
      width: 30px;
      left: ${({ activeBar }) => (activeBar === 'left' ? 'calc(25% - 15px)' : 'calc(75% - 15px)')};
    }
    .tab-item {
      font-size: 14px;
    }
    .close-btn {
      width: 12px;
      height: 12px;
      right: 10px;
      top: 10px;
    }
  }
`;

const TabContentWrapper = styled.div`
  position: relative;
  overflow: hidden;
  .approve-enable {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    padding: 0 40px 64px;
    .title {
      font-size: 40px;
      flex-grow: 2;
      color: ${(props) => props.theme['--color-text-main']};
      color: #000000 !important;
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
    @media only screen and (max-width: 768px) {
      .title {
        font-size: 23px;
        line-height: 23px;
        img {
          width: 23px;
          height: 23px;
          margin-right: 3px;
        }
      }
    }
    .button {
      margin: 0;
    }
  }
  .borrow-limit-modal {
    color: ${(props) => props.theme['--color-text-main']};
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    @media only screen and (max-width: 768px) {
      text-align: center;
      line-height: 24px;
    }
  }
`;

const LoadingBgWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
`;

const TabContent = styled.div`
  filter: ${({ blurBg, theme }) => {
    const brightness = theme['--filter-blur-brightness'];
    const filterVal = `blur(5px) brightness(${brightness})`;
    return blurBg ? filterVal : '';
  }};
  width: 100%;
  margin: auto;
  margin-top: 0px;
  padding: 0 40px 16px;
  background-color: ${(props) => props.theme['--color-card-bg']};
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0 20px 10px;
    .input-wrapper {
      width: 100%;
    }
  }
  .input-wrapper {
    width: 100%;
    height: 50px;
    padding-bottom: 10px;
    position: relative;
    border-bottom: 1px solid ${(props) => props.theme['--color-card-hr']};
    position: relative;
    @media only screen and (max-width: 768px) {
      height: 33px;
      padding-bottom: 10px;
    }
    .coin-name {
      position: absolute;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      color: ${(props) => props.theme['--color-card-coin-name']};
      right: 0;
      z-index: 1;
      @media only screen and (max-width: 768px) {
        font-size: 23px;
      }
    }
    input {
      z-index: 2;
      border: none;
      height: 100%;
      width: 100%;
      font-size: 40px;
      @media only screen and (max-width: 768px) {
        font-size: 23px;
        height: 100%;
      }
      color: ${(props) => props.theme['--color-white']};
      text-align: center;
      background: transparent;
      &:focus {
        outline: none;
      }
    }
    .max {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      right: 0;
      font-weight: 600;
      font-size: 14px;
      color: #4ad3a1;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
        position: unset;
        span {
          margin-right: 0 !important;
        }
      }
      span {
        white-space: nowrap;
        margin-right: 20px;
      }
    }
  }

  img {
    height: 50px;
    width: 50px;
    margin-bottom: 20px;
  }

  .warning-label {
    position: relative;
    width: 100%;
    max-width: 400px;
    font-size: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-text-secondary']};
    padding: 5px 25px 0 45px;
    margin-bottom: 10px;
    background-color: ${(props) => props.theme['--color-warning-label-bg']};
    text-align: left;
    border-radius: 4px;
    // opacity: 0.26;
    &::before {
      position: absolute;
      content: '!';
      color: #979797;
      width: 22px;
      height: 22px;
      background: ${(props) => props.theme['--color-hint-bg']};
      border-radius: 50%;
      left: 17px;
      top: 8px;
      text-align: center;
    }
  }
  & > div {
    width: 100%;
  }
  .ant-progress-inner {
    background-color: ${(props) => props.theme['--color-bg-primary']};
  }
  .cai-balance {
    display: flex;
    flex-direction: column;
  }
  .tabcontent-title {
    height: 74px;
    display: flex;
    font-size: 12px;
    img {
      width: 14px;
      height: 14px;
      margin: 0;
      margin-right: 10px;
    }
    span {
      color: ${(props) => props.theme['--color-white']};
      opacity: 0.5;
    }
    @media only screen and (max-width: 768px) {
      height: 63px;
    }
  }
  .trans-proportion {
    display: flex;
    align-items: center;
    color: ${(props) => props.theme['--color-white']};
    margin-bottom: 14px;
    img {
      width: 18px;
      height: 18px;
      margin: 0;
    }
    & span:first-child {
      color: #939498;
      margin-left: 10px;
      margin-right: 10px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 15px;
      font-size: 10px;
      img {
        width: 12px;
        height: 12px;
      }
      & span:first-child {
        color: #939498;
        margin-left: 4px;
        margin-right: 5px;
      }
    }
  }
`;

const PageTitle = styled.div`
  margin: 20px 0 10px;
  display: none;
  font-family: 'PingFang SC';
  font-size: 23px;
  line-height: 23px;
  color: ${(props) => props.theme['--color-white']};
  width: 100%;
  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export {
  Row,
  Column,
  Label,
  Card,
  TabSection,
  Tabs,
  TabContent,
  TabContentWrapper,
  PageTitle,
  BoldLabel,
  LoadingBgWrapper,
};
