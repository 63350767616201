import styled from 'styled-components';

const ProposalsWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: #181c3a;
  padding: 20px 30px;

  .proposal-head {
    margin-bottom: 20px;
    .header {
      font-size: 17px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
    }
    .create-proposal-btn {
      width: 150px;
      height: 40px;
      border-radius: 5px;
      background-image: linear-gradient(to right, #45cd85, #7eddaa);
      .MuiButton-label {
        font-size: 14px;
        font-weight: 500;
        color: ${(props) => props.theme['--color-text-main']};
        text-transform: capitalize;
      }
    }
  }

  .footer {
    margin-top: 40px;
    .pages {
      font-size: 16px;
      color: ${(props) => props.theme['--color-text-secondary']};
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      display: none;
    }

    .ant-pagination-item a {
      color: ${(props) => props.theme['--color-text-main']};
    }

    .ant-pagination-item:focus a,
    .ant-pagination-item:hover a {
      color: ${(props) => props.theme['--color-orange']};
    }

    .ant-pagination-item-active {
      background: transparent;
      border-color: transparent;
      a {
        color: ${(props) => props.theme['--color-orange']};
      }
    }

    .button {
      width: 200px;
      flex-direction: row-reverse;
      span {
        font-size: 16px;
        font-weight: 900;
        color: ${(props) => props.theme['--color-text-main']};
      }

      img {
        width: 26px;
        height: 16px;
        border-radius: 50%;
      }

      .button-prev {
        cursor: pointer;
        img {
          margin-right: 25px;
          transform: rotate(180deg);
        }
      }

      .button-next {
        cursor: pointer;
        span {
          margin-right: 25px;
        }
      }

      .button-prev:focus,
      .button-prev:hover,
      .button-next:focus,
      .button-next:hover {
        span {
          color: ${(props) => props.theme['--color-orange']};
        }
      }
    }
  }
`;

const NoProposalWrapper = styled.div`
  width: 100%;
  height: 80px;

  .title {
    font-size: 16px;
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-main']};
  }
`;

export { ProposalsWrapper, NoProposalWrapper };
