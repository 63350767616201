export const ASSETS_STATUS_MAP = {
  NONE: 0,
  SUPPLY: 1,
  BORROW: 2,
  OTHER: 3,
};

export const ASSETS_STATUS = {
  [ASSETS_STATUS_MAP.NONE]: 'none',
  [ASSETS_STATUS_MAP.SUPPLY]: 'supply',
  [ASSETS_STATUS_MAP.BORROW]: 'borrow',
  [ASSETS_STATUS_MAP.OTHER]: 'other',
};

export const ASSETS_STATUS_NAME = {
  [ASSETS_STATUS_MAP.NONE]: 'None',
  [ASSETS_STATUS_MAP.SUPPLY]: 'Supply',
  [ASSETS_STATUS_MAP.BORROW]: 'Borrow',
  [ASSETS_STATUS_MAP.OTHER]: 'Other',
};

export const SAFE_MAX_THRESHOLD = 0.4;
