import styled from 'styled-components';

const Wrap = styled.div`
  background-color: ${(props) => props.theme['--color-bg-table']};
  padding: 20px;
  border-radius: 8px;
  @media only screen and (max-width: 768px) {
    background-color: unset;
    box-shadow: none;
    padding: 0;
    .ant-table-content {
      background-color: ${(props) => props.theme['--color-bg-table']};
      padding: 20px;
      border-radius: 8px;
      overflow: auto;
    }
  }
  .asset-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
  }
  .flag-label {
    border-radius: 30% 0 30% 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #fff;
    margin-left: 4px;
    padding: 2px;
  }
  .bg-red {
    background: red;
  }
  .bg-green {
    background: green;
  }
  .green-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #009948;
  }
  .red-label {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 14px;
    color: #f3212f;
  }
  .ant-tabs-nav-container {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
  }
  .ant-tabs-nav {
    .ant-tabs-tab {
      // padding: 20px 0;
      color: #868c8a;
      &-active {
        color: ${(props) => props.theme['--color-font-tab']};
      }
    }
  }
  .ant-tabs-bar {
    border-bottom: 1px solid ${(props) => props.theme['--color-border-new']};
  }
  .ant-tabs-ink-bar {
    height: 4px;
    background-color: #3541ff;
    border-radius: 4px;
  }

  /* table */
  .ant-table-thead > tr > th {
    background: transparent;
    color: #868c8a;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: ${(props) => props.theme['--color-hover-row']};
  }
  .ant-table-body .ant-table-row-hover {
    background-color: ${(props) => props.theme['--color-hover-row']};
  }
  .ant-table-body .ant-table-row-hover > td {
    background-color: ${(props) => props.theme['--color-hover-row']};
  }

  /* tablet & small screens */
  .ant-table-wrapper {
    @media only screen and (max-width: 1264px) {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
      }
    }
  }

  /* mobile  */
  .ant-table table {
    @media only screen and (max-width: 768px) {
      border-collapse: collapse;
    }
  }
  .ant-table-wrapper {
    @media only screen and (max-width: 768px) {
      overflow-x: scroll;
    }
    .ant-table-row-level-0 {
      @media only screen and (max-width: 768px) {
        border-bottom: 1px solid ${(props) => props.theme['--color-card-hr']};
      }
    }
  }
  .ant-table-placeholder {
    background: rgba(0, 0, 0, 0.2);
  }
  .ant-empty-description {
    color: ${(props) => props.theme['--color-text-main']};
  }
`;

export { Wrap };
