import styled from 'styled-components';

const LoadingSpinnerWrapper = styled.div`
  width: 100%;
  height: 100%;

  .loading-spinner {
    color: ${(props) => props.theme['--color-yellow']};
  }
`;
export { LoadingSpinnerWrapper };
