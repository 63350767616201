import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { Tabs, Table } from 'antd';
import { Wrap } from './style';

function TabsTable(props) {
  const { tabsProps, tabList, tableProps, onChange } = props;
  return (
    <Wrap>
      {tabList.length > 0 ? (
        <Tabs className="tabs" {...tabsProps} onChange={onChange}>
          {tabList.map((item, index) => (
            <Tabs.TabPane key={index} tab={item.title}>
              <Table {...tableProps} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      ) : (
        <Table {...tableProps} />
      )}
    </Wrap>
  );
}

TabsTable.propTypes = {
  tabList: PropTypes.array,
  tableProps: PropTypes.object.isRequired,
  tabsProps: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

TabsTable.defaultProps = {
  tabList: [],
  tabsProps: {},
};

export default TabsTable;
