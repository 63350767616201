import { combineReducers } from 'redux';
import { LOGOUT_SUCCESS } from 'modules/auth/actions';
import { auth, account, ui } from 'modules';
import { resetReducer } from 'modules/reset';

const appReducer = combineReducers({
  auth,
  account,
  ui,
});

export default function rootReducer(state, action) {
  let finalState = appReducer(state, action);
  if (action.type === LOGOUT_SUCCESS) {
    finalState = resetReducer(finalState, action);
  }
  return finalState;
}
