/* eslint-disable no-console */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { NavLink, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Button as AntButton } from 'antd';
import MenuIconLight from 'assets/img/menu-light.svg';
import LogoPng from 'assets/img/logo.png';
import MenuIconDark from 'assets/img/menu-dark.svg';
import { getMarketsData } from 'utilities/subgraph';
import eventBus from 'utilities/eventBus';
import BigNumber from 'bignumber.js';
import { NET_IMG } from 'constants/network-img';
import { connectUI } from 'modules/ui/connectUI';
import { promisify } from 'utilities/promisify';
import { TIMEOUT, LOCKED, NOT_INSTALLED } from 'constants/hint';
import {
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_CAI_TOKEN_ADDRESS,
  SUBGRAPH_URL,
} from 'constants/address';
import { connectAccount, accountActionCreators } from 'modules';
import logoImg from 'assets/img/fld-logo-dark.svg';
import logoImgLight from 'assets/img/fld-logo-light.svg';
import checkIsValidNetwork from 'utilities/checkIsValidNetwork';
import Web3 from 'web3';
import { web3Instance, methods } from 'utilities/contractService';
import { onboard, chains } from 'components/App';
import toast from 'components/common/toast';
import { networkInfo, networkList } from 'constants/network';
import { networkInfo as mainNetworkInfo, networkList as mainNetworkList } from 'constants/network-main';
import { networkStatus } from 'modules/initialState';
import NetworkModal from 'components/common/networkModal';
import CustomNetworkModal from 'components/common/customNetworkModal';
import { LOAD_GLOBAL_DATA } from 'constants/eventCenter';
import { formatNumber, getBigNumber } from 'utilities/number';
import LinkMenu from '../components/link-menu';
import Drawer from '../components/drawer';
import { SidebarWrapper, Logo, MainMenu, FaucetMenu, TotalValue, Label, SidebarImg, NetworkWrap } from './style';
import { navList, customNavList } from './constant';
import ThemeSwitch from '../components/theme-switch';

let walletType = null;

const executeByUnit = (num, cb) => {
  let privateNum = num;
  return () => {
    privateNum += 1;
    if (privateNum % 3 === 0) {
      cb();
    }
  };
};

function Sidebar({ history, settings, setSetting, getGovernanceFld, ui, changeStatus, changeTheme }) {
  const [isMarketInfoUpdating, setMarketInfoUpdating] = useState(false);
  const [error, setError] = useState('');
  const [web3, setWeb3] = useState(null);
  const [showDrawer, setShowDrawer] = useState(false);
  const [tvl, setTVL] = useState(getBigNumber(0));
  const [currTvl, setCurrTVL] = useState(getBigNumber(0));

  const [isOpenNetworkModal, setIsOpenNetworkModal] = useState(false);

  const setDecimals = (networkName) => {
    if (!networkName || !CONTRACT_TOKEN_ADDRESS[networkName]) return;
    let decimals = {};
    Object.values(CONTRACT_TOKEN_ADDRESS[networkName]).forEach((item) => {
      if (!decimals[item.id]) {
        decimals[item.id] = {};
      }
      decimals[item.id].token = CONTRACT_TOKEN_ADDRESS[networkName][item.id].decimal;
      decimals[item.id].ctoken = CONTRACT_CTOKEN_ADDRESS[networkName][item.id].decimal;
      decimals[item.id].price = 18 + 18 - CONTRACT_TOKEN_ADDRESS[networkName][item.id].decimal;
    });
    setSetting({ decimals });
  };
  const checkNetwork = () => {
    let chainId = settings.chainId;
    if (chainId) {
      // Mainnet
      if (
        Object.keys(mainNetworkInfo).some((item) => mainNetworkInfo[item].chainId === chainId) &&
        (process?.env.REACT_APP_ENV === 'prod' || process?.env.REACT_APP_AWS_ENV === 'prod')
      ) {
        toast.info(
          {
            title: `Official FLD tokens have not been issued yet.`,
          },
          {
            toastId: 'id1',
          }
        );
        return;
      } else if (process?.env.REACT_APP_AWS_ENV === 'prod') {
        toast.error(
          { title: `Wrong network. Please switch to supported mainnets.` },
          {
            toastId: 'id2',
          }
        );
        setSetting({
          wrongNetwork: true,
        });
        return;
      }
      toast.error(
        {
          title: `Wrong network. Please switch to supported testnets.`,
        },
        {
          toastId: 'id2',
        }
      );
      setSetting({
        wrongNetwork: true,
      });
    } else {
      toast.error(
        {
          title: `Wrong network. Please switch to supported testnets.`,
        },
        {
          toastId: 'id2',
        }
      );
      setSetting({
        wrongNetwork: true,
      });
    }
  };

  const getMarketInfo = async (networkName) => {
    return new Promise((resolve, reject) => {
      getMarketsData({ networkName })
        .then((res) => {
          if (!res.status) {
            reject();
            return;
          }
          const markets = Object.values(CONTRACT_CTOKEN_ADDRESS[networkName])
            .map((value) =>
              res.data.markets.find((market) => market.symbol.toLowerCase() === value.symbol.toLowerCase())
            )
            .filter((item) => !!item);
          resolve(markets);
        })
        .catch((err) => {
          reject();
        });
    });
  };

  const getAllNetworksMarkets = async () => {
    Promise.all(networkList.filter((i) => SUBGRAPH_URL[i.shortName]).map((item) => getMarketInfo(item.shortName))).then(
      (values) => {
        const marketList = [];
        values.forEach((item) => {
          marketList.push(...item);
        });
        setSetting({
          allNetWorkMarkets: marketList,
        });
      }
    );
  };

  useEffect(() => {
    getAllNetworksMarkets();
  }, []);

  const getMarkets = async () => {
    if (settings.networkName === 'Wrong netWork' || !settings.networkName) {
      return;
    }
    const res = await promisify(getGovernanceFld, {
      networkName: settings.networkName,
    });
    if (!res.status) {
      executeByUnit(3, changeStatus(networkStatus.error));
      return;
    } else {
      changeStatus(networkStatus.success);
    }
    const markets = Object.values(CONTRACT_CTOKEN_ADDRESS[settings.networkName])
      .map((value) => res.data.markets.find((market) => market.symbol.toLowerCase() === value.symbol.toLowerCase()))
      .filter((item) => !!item);
    setSetting({
      markets,
      dailyFld: res.data.dailyFld,
    });
  };
  useEffect(() => {
    const walletsSub = onboard.state.select('wallets');
    const subscription = walletsSub.subscribe((wallets) => {
      const connectedWallets = wallets.map(({ label }) => label);
      web3Instance.value = new Web3(wallets[0].provider);
      if (!wallets) return;
      const chainId = wallets[0].chains[0].id;
      const key = Object.keys(networkInfo).find((item) => networkInfo[item].chainId === chainId);
      const networkName = networkInfo[key]?.shortName;
      setDecimals(networkName);
      setSetting({
        selectedAddress: wallets[0].accounts[0].address,
        chainId,
        networkName,
        wrongNetwork: false,
        markets: [],
      });
      web3Instance.value.eth.getBlockNumber((err, latestBlockNumber) => {
        if (err) throw err;
        else setSetting({ latestBlockNumber });
      });
      window.localStorage.setItem('connectedWallets', JSON.stringify(connectedWallets));
    });
    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    function getMarketsFun() {
      if (settings.chainId !== -1) {
        getMarkets();
      }
    }
    getMarketsFun();
    const updateTimer = setInterval(getMarketsFun, 5000);
    return () => {
      if (updateTimer) {
        clearInterval(updateTimer);
      }
    };
  }, [settings.chainId]);

  const updateMarketInfo = async () => {
    let data;
    const accountAddress = settings?.selectedAddress;
    if (!accountAddress || !settings.decimals || !settings.markets || isMarketInfoUpdating) {
      return;
    }

    setMarketInfoUpdating(true);
    try {
      let vaultCaiStaked = 10000000;
      let fldCAIVaultRate = 1;
      // Total Cai Staked
      vaultCaiStaked = getBigNumber(vaultCaiStaked).div(1e18);

      // Fairlend cai vault rate
      fldCAIVaultRate = getBigNumber(fldCAIVaultRate)
        .div(1e18)
        .times(20 * 60 * 24);

      // CAI APY
      const fldMarket = settings.markets.find((ele) => ele.underlyingSymbol === 'FLD');
      const caiAPY = getBigNumber(fldCAIVaultRate)
        .times(fldMarket ? fldMarket.tokenPrice : 0)
        .times(365 * 100)
        .div(vaultCaiStaked)
        .dp(2, 1)
        .toString(10);
      setSetting({
        caiAPY,
        vaultCaiStaked,
      });

      const currTotalLiquidity = (settings.markets || []).reduce((accumulator, market) => {
        return getBigNumber(accumulator).plus(getBigNumber(market.totalSupplyUsd));
      }, vaultCaiStaked);
      const newcurTotalLiquidity = !data ? currTotalLiquidity : getBigNumber(data);
      setCurrTVL(newcurTotalLiquidity);

      const allTotalLiquidity = (settings.allNetWorkMarkets || []).reduce((accumulator, market) => {
        return getBigNumber(accumulator).plus(getBigNumber(market.totalSupplyUsd));
      }, vaultCaiStaked);
      const newAllTotalLiquidity = !data ? allTotalLiquidity : getBigNumber(allTotalLiquidity).plus(getBigNumber(data));
      setTVL(newAllTotalLiquidity);
    } catch (error2) {
      // eslint-disable-next-line
      console.log(error2);
    } finally {
      setMarketInfoUpdating(false);
    }
  };

  const handleAccountChange = async () => {
    await updateMarketInfo();
    setSetting({
      accountLoading: false,
    });
  };

  useEffect(() => {
    updateMarketInfo();
  }, [settings.allNetWorkMarkets, settings.markets]);

  useEffect(() => {
    if (settings.chainId === -1) {
      return;
    }
    handleAccountChange();
    checkNetwork();
  }, [settings.chainId]);

  useEffect(() => {
    eventBus.addListener(LOAD_GLOBAL_DATA, getMarkets);
    return () => {
      eventBus.removeListener(LOAD_GLOBAL_DATA, getMarkets);
    };
  }, []);

  const LogoComponent = (
    <Logo>
      <img
        src={`${ui.theme === 'dark' ? MenuIconLight : MenuIconDark}`}
        alt="menu"
        className="menu"
        onClick={() => setShowDrawer(!showDrawer)}
      />
      <NavLink to={navList[0].path}>
        <img src={LogoPng} alt="logo" className="logo-text" width="180px" height="100%" />
      </NavLink>
      <NetworkWrap>
        <div className="info-item">
          <span className="desc" onClick={() => setIsOpenNetworkModal(true)}>
            <img className="icon" src={NET_IMG[settings.networkName]} alt="" />
            {settings.networkName}
          </span>
        </div>
      </NetworkWrap>
      <NetworkModal visible={isOpenNetworkModal} onCancel={() => setIsOpenNetworkModal(false)} />
      {/* <ThemeSwitch className="md-hidden" ui={ui} changeTheme={changeTheme} /> */}
    </Logo>
  );
  return (
    <SidebarWrapper>
      <Drawer tvl={tvl} currTvl={currTvl} visible={showDrawer} onVisibilityChange={setShowDrawer} />
      {LogoComponent}
      <MainMenu>
        {navList.map((item, index) => (
          <NavLink
            className="flex flex-start align-center sidebar-nav"
            to={item.path}
            activeClassName="active sidebar-nav"
            key={index}
          >
            <span>
              <img src={ui.theme === 'dark' ? item.img : item.lightImg} className="nav-icon-wrap" alt="fld-menu-icon" />
            </span>
            <Label primary>
              <FormattedMessage id={item.id} />
            </Label>
          </NavLink>
        ))}
      </MainMenu>
      <FaucetMenu>
        {settings.selectedAddress && (
          <>
            <TotalValue>
              <div className="flex flex-column align-center just-center">
                <Label primary className="center" size="14">
                  <FormattedMessage id="Total Value Locked" />
                </Label>
                <Label primary size="14">
                  {formatNumber(currTvl, true, 2, '$')}
                </Label>
              </div>
            </TotalValue>
            {/* <TotalValue>
              <div className="flex flex-column align-center just-center">
                <Label primary className="center" size="14">
                  <FormattedMessage id="Total Value Locked" />
                </Label>
                <Label primary size="14">
                  {formatNumber(tvl, true, 2, '$')}
                </Label>
              </div>
            </TotalValue> */}
          </>
        )}
        {/* <div
          className="liquidation"
          onClick={() => {
            history.push('/liquidation');
          }}
        >
          <FormattedMessage id="Liquidation" />
        </div> */}
      </FaucetMenu>
      <LinkMenu className="xs-hidden" />
    </SidebarWrapper>
  );
}

Sidebar.propTypes = {
  history: PropTypes.object,
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
  getGovernanceFld: PropTypes.func.isRequired,
  ui: PropTypes.object,
  changeStatus: PropTypes.func,
};

Sidebar.defaultProps = {
  settings: {},
  history: {},
  ui: {},
  changeStatus() {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting, getGovernanceFld } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
      getGovernanceFld,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps),
  connectUI(mapStateToProps, undefined)
)(Sidebar);
