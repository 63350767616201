import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Input, Icon } from 'antd';
import Button from '@material-ui/core/Button';
import { VotingWrapper } from './style';

function DelegationVoting({ history, isLoading, onDelegate }) {
  const [delegateAddress, setDelegateAddress] = useState('');
  return (
    <VotingWrapper>
      <div className="flex align-center just-center header-content">
        <p>Delegate Voting</p>
      </div>
      <div className="flex flex-column voting-selection">
        <div className="flex align-center just-start">
          <span className="address">Select and Address</span>
        </div>
        <div className="detail">
          If you know the address you wish to delegate to, enter it below. If not, you can view the Delegate Leaderboard
          to find a political party you wish to support.
        </div>
      </div>
      <div className="flex flex-column voting-selection">
        <div className="flex align-center just-between">
          <span className="address">Delegate Address</span>
          <span className="leaderboard pointer" onClick={() => history.push('/vote/leaderboard')}>
            Delegate Leaderboard
          </span>
        </div>
        <Input
          value={delegateAddress}
          placeholder="Enter a 0x address"
          onChange={(e) => setDelegateAddress(e.target.value)}
        />
        <Button className="vote-btn" disabled={isLoading} onClick={() => onDelegate(delegateAddress)}>
          Delegate Votes
        </Button>
      </div>
    </VotingWrapper>
  );
}

DelegationVoting.propTypes = {
  history: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onDelegate: PropTypes.func.isRequired,
};

export default compose(withRouter)(DelegationVoting);
