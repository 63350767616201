import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};
  padding: 37px 16px;

  .add-cai-token {
    font-size: 18px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-green']};
    margin-left: 10px;
    margin-bottom: 3px;
  }

  img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 16px;
  }

  p {
    font-size: 17.5px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    line-height: 1;
    color: ${(props) => props.theme['--color-text-main']};
  }

  .cai-apy {
    margin-left: 10px;
    color: ${(props) => props.theme['--color-green']};
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .copy-btn {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${(props) => props.theme['--color-bg-active']};
    margin-left: 26px;

    i {
      color: ${(props) => props.theme['--color-text-main']};
      svg {
        transform: rotate(-45deg);
      }
    }
  }
`;

export { CardWrapper };
