import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';

import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { connectAccount, accountActionCreators } from 'modules';

import { Card } from 'components/common/style';
import { Row, Col } from 'antd';

import moreImg from 'assets/img/more.svg';
import moreImgLight from 'assets/img/more_light.svg';
import replyImg from 'assets/img/reply.svg';
import replyImgLight from 'assets/img/reply-light.svg';
import boostImg from 'assets/img/boost.svg';
import boostImgLight from 'assets/img/boost-light.svg';
import { MoreWrapper } from './style';

function More({ settings, setSetting, ui }) {
  return (
    <Card>
      <MoreWrapper className="">
        <div className="flex flex-start align-center xs-hidden">
          <span>
            <div className="img-wrap">
              <img src={ui.theme === 'dark' ? moreImg : moreImgLight} alt="fld-balance" />
            </div>
          </span>
          <span className="font">More...</span>
        </div>
        <Row gutter={10}>
          <Col xs={12} md={24}>
            <div className="add-mask flex align-center just-center">
              <span>
                <img src={ui.theme === 'dark' ? replyImg : replyImgLight} alt="" />
              </span>
              <span>Repay All</span>
            </div>
          </Col>
          <Col xs={12} md={24}>
            <div className="add-mask boost flex align-center just-center">
              <span>
                <img src={ui.theme === 'dark' ? boostImg : boostImgLight} alt="" />
              </span>
              <span>Boost</span>
            </div>
          </Col>
        </Row>

        <div className="last-wrap xs-hidden">
          <div className="font">Coming Soon...</div>
        </div>
      </MoreWrapper>
    </Card>
  );
}

More.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
};

More.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(More);
