import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { MarketTableWrapper } from './style';

function MarketTable({ columns, data, title, handleClickRow }) {
  return (
    <MarketTableWrapper>
      <div className={title ? 'all-title' : 'all-title-line'}>{title}</div>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        onRow={(row) => {
          return {
            onClick: () => handleClickRow(row), // click row
          };
        }}
        size="middle"
      />
    </MarketTableWrapper>
  );
}

MarketTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  handleClickRow: PropTypes.func.isRequired,
};

MarketTable.defaultProps = {
  data: [],
  columns: [],
  title: '',
};

export default MarketTable;
