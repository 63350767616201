import { Modal } from 'antd';
import closeImg from 'assets/img/close-white.svg';
import { Tabs, TabSection } from 'components/common/style';
import SupplyTab from 'components/common/supplyTabs/supplyTab';
import WithdrawTab from 'components/common/supplyTabs/withdrawTab';
import { connectAccount } from 'modules';
import { CONTRACT_BASE_TOKEN } from 'constants/address';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { ModalContent, ProgressBar } from '../borrowModal/style';

function SupplyModal({ visible, asset, onCancel, settings, type }) {
  const [currentTab, setCurrentTab] = useState(type);
  const [showEnable, setShowEnable] = useState(false);
  useEffect(() => {
    if (visible) {
      setCurrentTab(type);
    }
  }, [visible]);

  useEffect(() => {
    setShowEnable(!asset.isEnabled && asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]);
  }, [asset.isEnabled, asset.id, settings.networkName]);

  if (!asset.id) {
    return <></>;
  }
  return (
    <Modal
      className="connect-modal mobile-modal"
      width={500}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        <TabSection>
          {settings.pendingInfo &&
            settings.pendingInfo.status &&
            ['Supply', 'Withdraw'].includes(settings.pendingInfo.type) && (
              <ProgressBar role="progressbar" aria-hidden="false" aria-label="notification timer" />
            )}
          <Tabs
            isLoading={settings?.pendingInfo?.status}
            activeBar={showEnable ? 'none' : currentTab === 'supply' ? 'left' : 'right'}
            className="flex align-center"
          >
            <img className="close-btn pointer" src={closeImg} alt="close" onClick={onCancel} />
            {[
              { val: 'supply', title: 'Supply' },
              { val: 'withdraw', title: 'Withdraw' },
            ].map((item) => (
              <div
                key={item.val}
                className={`flex align-center just-center tab-item pointer ${currentTab === item.val && 'tab-active'}`}
                onClick={() => {
                  if (!showEnable) setCurrentTab(item.val);
                }}
              >
                <FormattedMessage id={item.title} />
              </div>
            ))}
          </Tabs>
          {currentTab === 'supply' && <SupplyTab asset={asset} changeTab={setCurrentTab} onCancel={onCancel} />}
          {currentTab === 'withdraw' && <WithdrawTab asset={asset} changeTab={setCurrentTab} onCancel={onCancel} />}
        </TabSection>
      </ModalContent>
    </Modal>
  );
}

SupplyModal.propTypes = {
  visible: PropTypes.bool,
  asset: PropTypes.object,
  onCancel: PropTypes.func,
  type: PropTypes.string.isRequired,
};

SupplyModal.defaultProps = {
  visible: false,
  asset: {},
  onCancel: () => {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps))(SupplyModal);
