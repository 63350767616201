import styled from 'styled-components';

const CardWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};
  padding: 14px 18px 27px 22px;
  .ant-select-selection__rendered {
    margin-left: 0px;
  }
  .left {
    width: 200px;
    // flex: 1;
    // margin-right: auto;
  }
  .right {
    width: 100px;
    // border: 1px solid red;
    margin-left: auto;
  }

  .asset-img {
    width: 28px;
    height: 28px;
    margin-right: 13px;
  }

  .label {
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-secondary']};
  }

  .historic-label {
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-secondary']};
    margin: 10px 41px 0;
  }

  .value {
    font-size: 17px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-main']};
  }

  .apy-label {
    text-align: right;
    font-size: 16px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: normal;
    color: ${(props) => props.theme['--color-text-secondary']};
  }

  .apy-value {
    text-align: right;
    font-size: 19px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-green']};
  }

  .apy-value-red {
    text-align: right;
    font-size: 19px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-red']};
  }

  .description {
    margin-top: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }

  .asset-select-wrapper {
    width: 100%;

    @media only screen and (max-width: 1496px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .add-token-wrapper {
      margin-left: 20px;
      color: ${(props) => props.theme['--color-white']};

      @media only screen and (max-width: 1496px) {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
        justify-content: flex-end;
      }

      .add-token {
        font-size: 18px;
        color: ${(props) => props.theme['--color-green']};
        margin-left: 10px;
      }

      .underlying-asset,
      .ctoken-asset {
        margin-right: 20px;
      }

      .destination {
        color: ${(props) => props.theme['--color-white']};
      }
    }
  }
`;

const AssetSelectWrapper = styled.div`
  position: relative;
  .ant-select {
    .ant-select-selection {
      background-color: transparent;
      border: none;
      color: ${(props) => props.theme['--color-text-main']};
      font-size: 17px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
      margin-top: 0px;
      i {
        color: ${(props) => props.theme['--color-text-main']};
      }
    }
  }
  .asset-select-choose {
    background-color: ${(props) => props.theme['--color-bg-primary']};
    color: ${(props) => props.theme['--color-text-main']};
  }
`;

export { CardWrapper, AssetSelectWrapper };
