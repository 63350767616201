import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import SupplyModal from 'components/common/supplyModal';
import BorrowModal from 'components/common/borrowModal';
import CustomButton from 'components/common/button';
import { ASSETS_STATUS_MAP } from 'constants/enum';
import { Wrap } from './style';

function Container({ asset }) {
  const [isOpenCollateralConfirm, setIsCollateralConfirm] = useState(false);
  const [isOpenSupplyModal, setIsOpenSupplyModal] = useState(false);
  const [isOpenBorrowModal, setIsOpenBorrowModal] = useState(false);
  const [modalType, setModalType] = useState();

  const onClickBtn = (type) => {
    switch (type) {
      case 'supply':
        setModalType('supply');
        setIsOpenSupplyModal(true);
        break;
      case 'withdraw':
        setModalType('withdraw');
        setIsOpenSupplyModal(true);
        break;
      case 'borrow':
        setModalType('borrow');
        setIsOpenBorrowModal(true);
        break;
      case 'repayBorrow':
        setModalType('repayBorrow');
        setIsOpenBorrowModal(true);
        break;
      default:
    }
  };

  const generatorButton = () => {
    const map = {
      [ASSETS_STATUS_MAP.NONE]: (
        <>
          <CustomButton
            className="m-r-10 green action-btn"
            onClick={() => {
              onClickBtn('supply');
            }}
          >
            Supply
          </CustomButton>
          <CustomButton
            className="yellow action-btn"
            onClick={() => {
              onClickBtn('borrow');
            }}
          >
            Borrow
          </CustomButton>
        </>
      ),
      [ASSETS_STATUS_MAP.SUPPLY]: (
        <>
          <CustomButton
            className="m-r-10 green action-btn"
            onClick={() => {
              onClickBtn('supply');
            }}
          >
            Supply
          </CustomButton>
          <CustomButton
            className="green-next action-btn"
            onClick={() => {
              onClickBtn('withdraw');
            }}
          >
            Withdraw
          </CustomButton>
        </>
      ),
      [ASSETS_STATUS_MAP.BORROW]: (
        <>
          <CustomButton
            className="m-r-10 yellow action-btn"
            onClick={() => {
              onClickBtn('borrow');
            }}
          >
            Borrow
          </CustomButton>
          <CustomButton
            className="yellow-next action-btn"
            onClick={() => {
              onClickBtn('repayBorrow');
            }}
          >
            Repay
          </CustomButton>
        </>
      ),
    };
    return map[asset.status];
  };
  return (
    <Wrap>
      {generatorButton()}
      {/* Avoid duplicate render */}
      {isOpenSupplyModal && (
        <SupplyModal
          type={modalType}
          visible={isOpenSupplyModal}
          asset={asset}
          onCancel={() => setIsOpenSupplyModal(false)}
        />
      )}
      {/* Avoid duplicate render */}
      {isOpenBorrowModal && (
        <BorrowModal
          type={modalType}
          visible={isOpenBorrowModal}
          asset={asset}
          onCancel={() => setIsOpenBorrowModal(false)}
        />
      )}
    </Wrap>
  );
}

Container.propTypes = {
  asset: PropTypes.object.isRequired,
};

Container.defaultProps = {};

export default Container;
