import styled from 'styled-components';

const LineProgressBarWrapper = styled.div`
  width: 100%;

  .label {
    font-size: 13.5px;
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-main']};
  }

  .percent {
    font-size: 18px;
    font-weight: 900;
    color: ${(props) => props.theme['--color-text-main']};
  }

  .ant-progress {
    .ant-progress-inner {
      background-color: #eec76a;
    }
  }
`;
export { LineProgressBarWrapper };
