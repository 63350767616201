import { CONTRACT_CAI_TOKEN_ADDRESS, CONTRACT_TOKEN_ADDRESS, CONTRACT_CTOKEN_ADDRESS } from 'constants/address';

const addToken = async (asset = 'cai', decimal, type, networkName) => {
  let tokenAddress = '';
  let tokenSymbol = '';
  let tokenDecimals = 18;
  let tokenImage = '';
  if (asset === 'cai') {
    tokenAddress = CONTRACT_CAI_TOKEN_ADDRESS[networkName];
    tokenSymbol = 'CAI';
    tokenDecimals = 18;
    tokenImage = `${window.location.origin}/coins/cai.png`;
  } else if (asset === 'fldtest') {
    tokenAddress = '0x428b14e341F3231DDB47ba82A8c3FdC8bD6556e4';
    tokenSymbol = 'FLDTest';
    tokenDecimals = 18;
    tokenImage = `${window.location.origin}/coins/fld.png`;
  } else {
    tokenAddress =
      type === 'token'
        ? CONTRACT_TOKEN_ADDRESS[networkName][asset].address
        : CONTRACT_CTOKEN_ADDRESS[networkName][asset].address;
    tokenSymbol = type === 'token' ? CONTRACT_TOKEN_ADDRESS[networkName][asset].symbol : `c${asset.toUpperCase()}`;
    tokenDecimals = decimal || (type === 'token' ? 18 : 8);
    tokenImage = `${window.location.origin}/coins/${type === 'token' ? asset : `c${asset}`}.png`;
  }

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20', // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      // eslint-disable-next-line no-console
      console.log('Thanks for your interest!');
    } else {
      // eslint-disable-next-line no-console
      console.log('Your loss!');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export default addToken;
