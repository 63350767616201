import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { CONTRACT_CAI_TOKEN_ADDRESS, EXPLORER_LINK } from 'constants/address';
import coinImg from 'assets/img/coins/cai.png';
import { Card } from 'components/common/style';
import addToken from 'utilities/addToken';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CardWrapper } from './style';

function CaiInfo({ settings }) {
  const handleLink = () => {
    window.open(
      `${EXPLORER_LINK[settings.networkName]}/token/${CONTRACT_CAI_TOKEN_ADDRESS[settings.networkName]}?a=${
        settings.selectedAddress
      }`,
      '_blank'
    );
  };

  return (
    <Card>
      <CardWrapper className="flex align-center just-between">
        <div className="flex align-center">
          <img src={coinImg} alt="coin" />
          <p>{formatNumber(settings.userCaiBalance)} CAI </p>
          {(window.ethereum || window.BinanceChain) && (
            <Icon
              className="add-cai-token"
              type="plus-circle"
              theme="filled"
              onClick={() => addToken('cai', 18, 'token', settings.networkName)}
            />
          )}
          {settings.caiAPY && <p className="cai-apy">APY: {settings.caiAPY}%</p>}
        </div>
        <div className="flex align-center just-center pointer" onClick={() => handleLink()}>
          <p className="highlight">{`${settings.selectedAddress.substr(0, 6)}...${settings.selectedAddress.substr(
            settings.selectedAddress.length - 4,
            4
          )}`}</p>
          <div className="flex align-center just-center copy-btn">
            <Icon type="arrow-right" />
          </div>
        </div>
      </CardWrapper>
    </Card>
  );
}

CaiInfo.propTypes = {
  settings: PropTypes.object,
};

CaiInfo.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(CaiInfo);
