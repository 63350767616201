import styled from 'styled-components';

const ModalContent = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    position: absolute;
    top: 30px;
    right: 23px;
  }

  .header-content {
    width: 100%;
    height: 80px;
    background-color: ${(props) => props.theme['--color-bg-primary']};
    font-weight: bold;
    font-size: 20px;
    color: ${(props) => props.theme['--color-text-main']};
    border-radius: 8px;
  }

  .max-operations {
    color: ${(props) => props.theme['--color-orange']};
    font-size: 20px;
    margin-top: 10px;
  }

  .invalid_msg {
    color: ${(props) => props.theme['--color-orange']};
    font-size: 14px;
    margin-top: 10px;
  }

  .ant-collapse-header,
  .ant-collapse-content-box,
  .rc-md-navigation {
    background-color: ${(props) => props.theme['--color-bg-main']};
    color: ${(props) => props.theme['--color-text-main']} !important;
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0px;
  }

  .ant-collapse,
  .ant-collapse-content,
  .ant-collapse-item,
  .ant-collapse > .ant-collapse-item,
  .rc-md-editor {
    border-color: ${(props) => props.theme['--color-bg-main']};
  }

  .proposal_form {
    width: 100%;
    margin-top: 20px;

    .proposal-data-list {
      max-height: 300px;
      overflow: auto;
      padding: 0 20px;
      margin-bottom: 20px;

      .proposal-content {
        width: 100%;
        padding: 0px 20px;
        .input-wrapper {
          input {
            width: 350px;
            border: none;
            height: 55px;
            font-size: 60px;
            color: ${(props) => props.theme['--color-orange']};
            text-align: center;
            &:focus {
              outline: none;
            }
          }
        }

        .add-btn-wrapper {
          width: 100%;
          .add-btn {
            height: 40px;
            background-image: linear-gradient(to right, #45cd85, #7eddaa);
            border-radius: 10px;
            .MuiButton-label {
              font-size: 16px;
              font-weight: bold;
              color: ${(props) => props.theme['--color-text-main']};
              text-transform: capitalize;
            }
            &:not(:last-child) {
              margin-right: 10px;
            }

            @media only screen and (max-width: 768px) {
              .MuiButton-label {
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .description-wrapper {
      padding: 0px 20px;
    }
    .btn-wrapper {
      margin-top: 47px;
      margin-bottom: 40px;
      .proposal-btn {
        width: 210px;
        height: 52px;
        background-image: linear-gradient(to right, #45cd85, #7eddaa);
        border-radius: 10px;
        .MuiButton-label {
          font-size: 16px;
          font-weight: bold;
          color: ${(props) => props.theme['--color-text-main']};
          text-transform: capitalize;
        }
      }
      .ant-btn[disabled] {
        color: ${(props) => props.theme['--color-text-secondary']};
        background-color: rgba(0, 145, 255, 0.05);
        box-shadow: unset;
      }
    }
  }
`;

export { ModalContent };
