import styled from 'styled-components';

const ThemeSwitchWrapper = styled.span`
  width: 32px;
  text-align: center;
  font-size: 14px;
  /* height: 20px; */
  cursor: pointer;
  margin-left: 10px;
  display: block;
  @media only screen and (max-width: 768px) {
    img {
      width: 27px !important;
    }
  }
`;

export { ThemeSwitchWrapper };
