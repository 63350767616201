import icon1 from 'assets/img/icons/home.svg';
import icon2 from 'assets/img/icons/data.svg';
import transactions from 'assets/img/icons/transactions.svg';
import icon3 from 'assets/img/icons/lightning.svg';
import icon1Light from 'assets/img/icons/home_light.svg';
import icon2Light from 'assets/img/icons/data_light.svg';
import icon3Light from 'assets/img/icons/transactions_light.svg';

const navList = [
  {
    path: '/dashboard',
    id: 'Dashboard',
    iconType: 'home',
    theme: 'filled',
    img: icon1,
    lightImg: icon1Light,
  },
  {
    path: '/market',
    id: 'Market Data',
    iconType: 'area-chart',
    theme: '',
    img: icon2,
    lightImg: icon2Light,
  },
  {
    path: '/transaction',
    id: 'Transactions',
    iconType: 'area-chart',
    theme: '',
    img: transactions,
    lightImg: icon3Light,
  },
];

const customNavList = [{}];

export { navList, customNavList };
