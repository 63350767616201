import celoImg from 'assets/img/networks/ethf.png';
import ethImg from 'assets/img/coins/ethf.png';

const networkInfo = {
  etf: {
    chainId: '0x7d44c', // 513100
    chainName: 'EthereumFair',
    rpcUrls: ['https://rpc.etherfair.link'],
    blockExplorerUrls: ['https://explorer.etherfair.org'],
    shortName: 'ETHF',
  },
};

const networkList = [
  {
    name: 'EthereumFair',
    value: 'etf',
    icon: celoImg,
    supportTrans: true,
    shortName: 'ETHF',
    supportLiquidation: true,
  },
];
export { networkInfo, networkList };
