import styled from 'styled-components';
import { Drawer } from 'antd';

const List = styled.div`
  width: 100%;
  padding: 0 20px 20px;
  background-color: ${(props) => props.theme['--color-bg-drawer']};
`;
const ListItem = styled.div`
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme['--color-text-main']};
  font-size: 14px;
  &:not(:last-child) {
    border-bottom: 1px solid ${(props) => props.theme['--color-border']};
  }
`;

const DrawerWrap = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

export { DrawerWrap, List, ListItem };
