import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Web3 from 'web3';
import { compose } from 'recompose';
import { Pagination, Icon } from 'antd';
import Button from '@material-ui/core/Button';
import { getTokenContract, getVoteContract, methods } from 'utilities/contractService';
import { CONTRACT_VOTE_ADDRESS, CONTRACT_TOKEN_ADDRESS } from 'constants/address';
import { connectAccount } from 'modules';
import Proposal from 'components/common/proposal';
import toast from 'components/common/toast';
import LoadingSpinner from 'components/common/loadingSpinner';
import arrowRightImg from 'assets/img/arrow-right.png';
import { Card } from 'components/common/style';
import ProposalModal from '../proposalModal';
import { ProposalsWrapper, NoProposalWrapper } from './style';

function Proposals({ address, isLoadingProposal, settings, votingWeight, pageNumber, proposals, total, onChangePage }) {
  const [current, setCurrent] = useState(pageNumber);
  const [pageSize, setPageSize] = useState(5);

  const [proposalModal, setProposalModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [proposalThreshold, setProposalThreshold] = useState(0);
  const [maxOperation, setMaxOperation] = useState(0);
  const [delegateAddress, setDelegateAddress] = useState('');

  useEffect(() => {
    if (address) {
      const voteContract = getVoteContract(CONTRACT_VOTE_ADDRESS);
      methods.call(voteContract.methods.proposalThreshold, []).then((res) => {
        setProposalThreshold(+Web3.utils.fromWei(res, 'ether'));
      });
      methods.call(voteContract.methods.proposalMaxOperations, []).then((res) => {
        setMaxOperation(Number(res));
      });
    }
  }, [address]);

  useEffect(() => {
    if (
      settings.selectedAddress &&
      (delegateAddress === '' || delegateAddress === '0x0000000000000000000000000000000000000000')
    ) {
      const tokenContract = getTokenContract('fld', CONTRACT_TOKEN_ADDRESS[settings.networkName]);
      methods
        .call(tokenContract.methods.delegates, [address])
        .then((res) => {
          setDelegateAddress(res);
        })
        .catch(() => {});
    }
  }, [settings.selectedAddress, address, delegateAddress]);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage(page, (page - 1) * size, size);
  };

  const onNext = () => {
    handleChangePage(current + 1, 5);
  };

  const onPrev = () => {
    handleChangePage(current - 1, 5);
  };

  const handleShowProposalModal = () => {
    if (+votingWeight < +proposalThreshold) {
      toast.error({
        title: `You can't create proposal. Your voting power should be ${proposalThreshold} FLD at least`,
      });
      return;
    }
    const voteContract = getVoteContract(CONTRACT_VOTE_ADDRESS);
    setIsLoading(true);
    methods.call(voteContract.methods.latestProposalIds, [address]).then((pId) => {
      if (pId !== '0') {
        methods.call(voteContract.methods.state, [pId]).then((status) => {
          if (status === '0' || status === '1') {
            toast.error({
              title: `You can't create proposal. there is proposal in progress!`,
            });
          } else {
            setProposalModal(true);
          }
          setIsLoading(false);
        });
      } else {
        setProposalModal(true);
        setIsLoading(false);
      }
    });
  };

  return (
    <Card>
      <ProposalsWrapper className="flex flex-column">
        <div className="flex align-center just-between proposal-head">
          <p className="header">Governance Proposals</p>
          {address && (
            <Button className="create-proposal-btn" onClick={handleShowProposalModal}>
              Create Proposal
            </Button>
          )}
        </div>
        <div className="body">
          {isLoadingProposal && <LoadingSpinner />}
          {!isLoadingProposal && proposals && proposals.length !== 0 ? (
            proposals.map((item) => {
              return (
                <Proposal
                  proposal={item}
                  votingWeight={votingWeight}
                  delegateAddress={delegateAddress}
                  address={address}
                  key={item.id}
                />
              );
            })
          ) : (
            <NoProposalWrapper className="flex just-center align-center">
              <div className="title">No Proposals</div>
            </NoProposalWrapper>
          )}
        </div>
        {proposals && proposals.length !== 0 && (
          <div className="flex align-center just-between footer">
            <Pagination
              size="small"
              defaultCurrent={1}
              defaultPageSize={5}
              current={current}
              pageSize={pageSize}
              total={total}
              onChange={handleChangePage}
            />
            <div className="flex just-between align-center button">
              {current * pageSize < total && (
                <div className="flex align-center button-next" onClick={onNext}>
                  <span>Next</span>
                  <img src={arrowRightImg} alt="arrow" />
                </div>
              )}
              {current > 1 && (
                <div className="flex align-center button-prev" onClick={onPrev}>
                  <img src={arrowRightImg} alt="arrow" />
                  <span>Prev</span>
                </div>
              )}
            </div>
          </div>
        )}
        <ProposalModal
          address={address}
          visible={proposalModal}
          maxOperation={maxOperation}
          onCancel={() => setProposalModal(false)}
        />
      </ProposalsWrapper>
    </Card>
  );
}

Proposals.propTypes = {
  address: PropTypes.string.isRequired,
  isLoadingProposal: PropTypes.bool.isRequired,
  votingWeight: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  proposals: PropTypes.array,
  pageNumber: PropTypes.number,
  total: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,
};

Proposals.defaultProps = {
  proposals: [],
  pageNumber: 1,
  total: 0,
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(Proposals);
