import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import coinImg from 'assets/img/fid-64.png';
import { Card } from 'components/common/style';
import { EXPLORER_LINK } from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CardWrapper } from './style';

function CoinInfo({ address, balance }) {
  const handleLink = () => {
    window.open(`${EXPLORER_LINK}/address/${address}`, '_blank');
  };

  return (
    <Card>
      <CardWrapper className="flex align-center just-between">
        <div className="flex align-center">
          <img src={coinImg} alt="coin" />
          <p>{formatNumber(balance)}</p>
        </div>
        <div className="flex align-center just-center pointer" onClick={() => handleLink()}>
          <p className="highlight">{`${address.substr(0, 4)}...${address.substr(address.length - 4, 4)}`}</p>
          <div className="flex align-center just-center copy-btn">
            <Icon type="arrow-right" />
          </div>
        </div>
      </CardWrapper>
    </Card>
  );
}

CoinInfo.propTypes = {
  address: PropTypes.string,
  balance: PropTypes.string,
};

CoinInfo.defaultProps = {
  address: '',
  balance: '0.0000',
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps, undefined))(CoinInfo);
