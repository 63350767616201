import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Row, Col, Pagination, Select, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Label, PageTitle } from 'components/common/style';
import { transTypeApi } from 'utilities/subgraph';
import { FormattedMessage } from 'react-intl';
import { isString, findKey } from 'lodash';

import { promisify } from 'utilities/promisify';
import moment from 'moment';
import arrowRightImg from 'assets/img/arrow-right.png';
import fldImg from 'assets/img/coins/fld.png';
import {
  EXPLORER_LINK,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CTOKEN_ABI,
  CONTRACT_CBASE_ABI,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_BASE_TOKEN,
} from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import { getWeb3, methods, getTokenContract } from 'utilities/contractService';
import { getAccount, getUnderlyingDecimal } from 'utilities/getAccount';
import { LiquidationWrapper, TableWrapper, HeaderWrapper, FooterWrapper, ScrollContent, SpinnerWrapper } from './style';
import LiquidationModal from './components/liquidationModal';
import { cTokens } from './data';

const { Option } = Select;
const DEFAULT_PAGE = 1;
const LIMIT = 20;
const PAGE_SIZE = 20;

const env = process?.env.REACT_APP_AWS_ENV;
function Liquidation({ getLiquidation, setLiquidation, settings }) {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(999);
  const [canShowPage, setCanShowPage] = useState(false);
  const [isOpenLiquidationModal, setIsOpenLiquidationModal] = useState(false);
  const [currentLiquidationItem, setCurrentLiquidationItem] = useState(null);
  const [liquidationEnableProgressShow, setLiquidationEnableProgressShow] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);

  const loadLiquidationList = useCallback(async () => {
    await promisify(getLiquidation, {
      networkName: settings.networkName,
      offset,
      env: 'prod',
    })
      .then((res) => {
        if (!res) return;
        const resultMap = {};
        const resArr = [];
        res.forEach(async (i) => {
          async function handle(item) {
            const borrowAccount = getAccount(item.cToken, item.borrow, settings.markets);
            const saveAccount = getAccount(item.cToken, item.save, settings.markets);
            const underlyingDecimal = getUnderlyingDecimal(item.cToken, settings.markets);
            let balanceOf;
            if (item.name !== CONTRACT_BASE_TOKEN[settings.networkName]) {
              const tokenContract = getTokenContract(item.name, CONTRACT_TOKEN_ADDRESS[settings.networkName]);
              balanceOf = await methods.call(tokenContract.methods.balanceOf, [settings.selectedAddress]);
            } else {
              const web3 = getWeb3();
              balanceOf = await web3.eth.getBalance(settings.selectedAddress);
            }
            if (!resultMap[item.borrower]) {
              resultMap[item.borrower] = {
                id: item.id,
                name: item.name,
                borrower: item.borrower,
                unix: item.unix,
                totalBorrowed: borrowAccount,
                totalCollateral: saveAccount,
                supplyList: [],
                borrowList: [],
                underlyingDecimal,
              };
            } else {
              resultMap[item.borrower].totalBorrowed = getBigNumber(resultMap[item.borrower].totalBorrowed)
                .plus(borrowAccount)
                .toNumber();
              resultMap[item.borrower].totalCollateral = getBigNumber(resultMap[item.borrower].totalCollateral)
                .plus(saveAccount)
                .toNumber();
            }
            if (item.save > 0) {
              resultMap[item.borrower].supplyList.push({
                name: item.name,
                cToken: item.cToken,
                value: item.save,
                amount: saveAccount,
              });
            }
            if (item.borrow > 0) {
              resultMap[item.borrower].borrowList.push({
                name: item.name,
                cToken: item.cToken,
                value: item.borrow,
                amount: borrowAccount,
                wallet: formatNumber(balanceOf / 10 ** underlyingDecimal, true, 4),
              });
            }
          }
          resArr.push(handle(i));
        });
        Promise.all(resArr).then(() => {
          const result = Object.values(resultMap);
          // sort
          result.sort((a, b) => {
            return Number(b.unix) - Number(a.unix);
          });
          setData(result);
          if (result.length < pageSize) {
            const newTotal = offset * pageSize + result.length;
            setTotal(newTotal);
          }
          setCanShowPage(true);
        });
      })
      .catch((e) => {
        setCanShowPage(true);
      });
  }, [offset, settings.networkName, settings.markets]);
  const updateInfo = async (borrower) => {
    setCanShowPage(false);
    await promisify(setLiquidation, { networkName: settings.networkName, borrower }).then(async (res) => {
      if (res?.code === 200) {
        setIsOpenLiquidationModal(false);
        loadLiquidationList();
      }
    });
  };
  // useEffect(() => {
  //   if (!settings.networkName) return;
  //   loadLiquidationList();
  // }, [loadLiquidationList, settings.networkName]);

  useEffect(() => {
    if (!settings.networkName) return;
    if (!settings.markets.length) return;
    if (settings.markets.length) {
      setCanShowPage(false);
      loadLiquidationList();
    }
  }, [settings.networkName, settings.markets.length]);

  const handleChangePage = (page) => {
    setOffset(page - 1);
  };
  const onNext = () => {
    handleChangePage(offset + 2);
  };
  const onPrev = () => {
    handleChangePage(offset);
  };
  const handleLiquidation = async (selectedRow, cToken, collateral, amount) => {
    const web3 = getWeb3();
    const { underlyingDecimal } = selectedRow;
    const borrower = selectedRow.borrower || '0xd59f470c6f647a447031574696d8c27b9b6cc776';
    // const cToken = selectedRow.cToken || '0x061A848eBEFa36151D8A12bd7b0DFdAc91f58f2B';
    // const collateral = selectedRow.cToken || '0x061A848eBEFa36151D8A12bd7b0DFdAc91f58f2B';

    let instance = new web3.eth.Contract(JSON.parse(CONTRACT_CTOKEN_ABI), cToken);

    // 判断是否是本网的token
    const net = settings.networkName;
    let instanceOwn = new web3.eth.Contract(JSON.parse(CONTRACT_CBASE_ABI), cToken);
    const tokenObj = cTokens[net];
    const isCurrentNet = Object.values(tokenObj).findIndex((item) => item === cToken);
    setIsModalLoading(true);
    try {
      if (isCurrentNet === -1) {
        // liquidate
        await instance.methods
          .liquidateBorrow(
            borrower,
            amount.times(getBigNumber(10).pow(underlyingDecimal)).integerValue().toString(10),
            collateral
          )
          .send({
            from: settings.selectedAddress,
          })
          .then()
          .catch();
        setIsOpenLiquidationModal(false);
        // liquidate之后更新数据
        updateInfo(borrower);
      } else {
        // liquidate
        // await instanceOwn.methods.liquidateBorrow(borrower, amount * 1e8, collateral).send({
        //   from: settings.selectedAddress,
        // });

        await instance.methods
          .liquidateBorrow(
            borrower,
            amount.times(getBigNumber(10).pow(underlyingDecimal)).integerValue().toString(10),
            collateral
          )
          .send({
            from: settings.selectedAddress,
          })
          .then()
          .catch();
        setIsOpenLiquidationModal(false);
        // liquidate之后更新数据
        updateInfo(borrower);
      }
      setIsModalLoading(false);
    } catch (error) {
      setIsModalLoading(false);
    }
  };
  return (
    <MainLayout title="Liquidation" fullHeight>
      <LiquidationWrapper>
        <ScrollContent>
          <TableWrapper>
            <div className="table_header">
              <Row>
                {/* <Col lg={{ span: 4 }} className="type">
                  Ctoken
                </Col> */}
                <Col lg={{ span: 5 }} className="type">
                  Borrower
                </Col>
                <Col lg={{ span: 5 }} className="hash">
                  Total Borrowed
                </Col>
                <Col lg={{ span: 5 }} className="block">
                  Total Collateral
                </Col>
                <Col lg={{ span: 5 }} className="block">
                  Collateral Ratio
                </Col>
                <Col lg={{ span: 4 }} className="block">
                  Update Time
                </Col>
              </Row>
            </div>
            <div className="table_content">
              {!canShowPage ? (
                <SpinnerWrapper>
                  <LoadingSpinner />
                </SpinnerWrapper>
              ) : (
                data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <Row
                    className="table_item"
                    key={index}
                    onClick={() => {
                      setIsOpenLiquidationModal(true);
                      setCurrentLiquidationItem(item);
                    }}
                  >
                    {/* <Col lg={{ span: 4 }} className="coin-type">
                      <p className="item-title">
                        {item.cToken && `${item.cToken.slice(0, 6)}...${item.cToken.slice(-6)}`}
                      </p>
                    </Col> */}
                    <Col lg={{ span: 5 }} className="from">
                      <p className="item-title">
                        {item.borrower && `${item.borrower.slice(0, 6)}...${item.borrower.slice(-6)}`}
                      </p>
                    </Col>
                    <Col lg={{ span: 5 }} className="hash">
                      <p className="item-title">{formatNumber(item.totalBorrowed, true, 2, '$')}</p>
                    </Col>
                    <Col lg={{ span: 5 }} className="block">
                      <p className="item-title">{formatNumber(item.totalCollateral, true, 2, '$')}</p>
                    </Col>
                    <Col lg={{ span: 5 }} className="block">
                      <p className="item-title">
                        {item.totalCollateral ? (item.totalBorrowed / item.totalCollateral).toFixed(4) : 55}
                      </p>
                    </Col>
                    <Col lg={{ span: 4 }} className="block">
                      <p className="item-title">{moment(item.unix * 1000).format('MM/DD/YYYY HH:mm')}</p>
                    </Col>
                  </Row>
                ))
              )}
            </div>
          </TableWrapper>
        </ScrollContent>
        {/* <FooterWrapper>
          <Icon className="icons" type="left" onClick={onPrev} />
          <div className="footer">
            <Pagination
              size="small"
              current={offset + 1}
              // total={total}
              onChange={handleChangePage}
              // pageSize={5}
              // style={{ marginTop: 20 }}
              defaultCurrent={DEFAULT_PAGE}
              pageSize={PAGE_SIZE}
              // current={page}
              total={data.length}
              // onChange={(page) => {
              //   setPage(page);
              //   setRefresh(true);
              // }}
              // showTotal={() => `共 ${data.length} 条`}
            />
          </div>
          <Icon type="right" className="icons" onClick={onNext} />
        </FooterWrapper> */}
      </LiquidationWrapper>
      {isOpenLiquidationModal && (
        <LiquidationModal
          markets={settings.markets}
          visible={isOpenLiquidationModal}
          selectedRow={currentLiquidationItem}
          liquidationEnableProgressShow={liquidationEnableProgressShow}
          networkName={settings.networkName}
          onLiquidation={handleLiquidation}
          onCancel={() => setIsOpenLiquidationModal(false)}
          loading={isModalLoading}
        />
      )}
    </MainLayout>
  );
}

Liquidation.propTypes = {
  settings: PropTypes.object,
};

Liquidation.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { getLiquidation, setLiquidation } = accountActionCreators;

  return bindActionCreators(
    {
      getLiquidation,
      setLiquidation,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Liquidation);
