import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Drawer } from 'antd';
import { connectUI } from 'modules/ui/connectUI';
import BigNumber from 'bignumber.js';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';
import { NET_IMG } from 'constants/network-img';
import avatarImg from 'assets/img/networks/ethf.png';
import arrowDark from 'assets/img/arrow-dark.svg';
import arrowLight from 'assets/img/arrow-light.svg';
import { onboard } from 'components/App';

import { LANG_LIST } from 'constants/lang';
import eventBus from 'utilities/eventBus';
import NetworkModal from 'components/common/networkModal';
import { CLICK_DRAWER_WALLET, CLICK_DRAWER_LANG } from 'constants/eventCenter';
import { formatNumber, getBigNumber } from 'utilities/number';
import { DrawerWrapper, Label, InfoList, Tools } from './style';
import { navList } from '../../sidebar/constant';
import LinkMenu from '../link-menu';

function DrawerComponent({ ui, visible, onVisibilityChange, tvl, currTvl, settings }) {
  const [isOpenNetworkModal, setIsOpenNetworkModal] = useState(false);
  const onClickWallet = () => {
    eventBus.emit(CLICK_DRAWER_WALLET);
  };
  const onClickSwitchLang = () => {
    eventBus.emit(CLICK_DRAWER_LANG);
    onVisibilityChange(false);
  };
  const connectWallet = async () => {
    try {
      onboard.connectWallet();
    } catch (walleterror) {
      // console.error(walleterror);
    }
  };
  return (
    <DrawerWrapper>
      <Drawer
        placement="top"
        height="unset"
        closable={false}
        onClose={() => onVisibilityChange(false)}
        visible={visible}
        key="top"
        className="sidebar-drawer"
        getContainer={false}
      >
        {/* {LogoComponent} */}

        {navList.map((item, index) => (
          <NavLink
            className="flex flex-start align-center m-sidebar-nav"
            to={item.path}
            activeClassName="active active-mobile-option m-sidebar-nav"
            key={index}
            onClick={() => onVisibilityChange(false)}
          >
            <span>
              <img
                src={ui.theme === 'dark' ? item.img : item.lightImg}
                className="m-nav-icon-wrap"
                alt="fld-menu-icon"
              />
            </span>
            <Label primary className="text">
              <FormattedMessage id={item.id} />
            </Label>
          </NavLink>
        ))}
        <InfoList>
          <div className="info-item">
            <span className="title">
              <FormattedMessage id="Total Value Locked" />:
            </span>
            <span className="desc">{formatNumber(currTvl, true, 2, '$')}</span>
          </div>
          {/* <div className="info-item">
            <span className="title">
              <FormattedMessage id="Total Value Locked" />:
            </span>
            <span className="desc">{formatNumber(tvl, true, 2, '$')}</span>
          </div> */}
          <div className="info-item">
            <span className="title">
              <FormattedMessage id="Latest Block" />:
            </span>
            <span className="desc">{settings.latestBlockNumber || 0}</span>
          </div>
          <div className="info-item">
            <span className="title">Network:</span>
            <span className="desc" onClick={() => setIsOpenNetworkModal(true)}>
              <img className="icon" src={NET_IMG[settings.networkName]} alt="" />
              {settings.networkName}
            </span>
          </div>
        </InfoList>
        <Tools>
          <div className="tools-item" onClick={onClickWallet}>
            <span className="title">
              <FormattedMessage id="Wallet" />:
            </span>
            <span className="desc" onClick={connectWallet}>
              <img className="icon" src={avatarImg} alt="" />
              {!settings.selectedAddress
                ? 'Connect'
                : `${settings.selectedAddress.substr(0, 6)}...${settings.selectedAddress.substr(
                    settings.selectedAddress.length - 4,
                    4
                  )}`}
              <img className="arrow" src={ui.theme === 'dark' ? arrowDark : arrowLight} alt="" />
            </span>
          </div>
          {/* <NavLink to="/liquidation">
            <div className="tools-item">
              <span className="title">
                <FormattedMessage id="Liquidation" />
              </span>
              <span>
                <img className="arrow" src={ui.theme === 'dark' ? arrowDark : arrowLight} alt="" />
              </span>
            </div>
          </NavLink> */}

          <div className="tools-item" onClick={onClickSwitchLang}>
            <span className="title">{LANG_LIST.find((i) => i.value === ui.lang).label}</span>
            <span className="desc">
              <img className="arrow" src={ui.theme === 'dark' ? arrowDark : arrowLight} alt="" />
            </span>
          </div>
        </Tools>
        <LinkMenu />
      </Drawer>
      <NetworkModal visible={isOpenNetworkModal} onCancel={() => setIsOpenNetworkModal(false)} />
    </DrawerWrapper>
  );
}

DrawerComponent.propTypes = {
  ui: PropTypes.object,
  visible: PropTypes.bool.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  tvl: PropTypes.any.isRequired,
  currTvl: PropTypes.any.isRequired,
};

DrawerComponent.defaultProps = {
  ui: {},
};
const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});
const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps),
  connectUI(mapStateToProps, undefined)
)(DrawerComponent);
