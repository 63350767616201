import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';

import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import { Card, Row, Column, Label, BoldLabel } from 'components/common/style';
import supplyImg from 'assets/img/supply.svg';
import supplyImgLight from 'assets/img/supply-light.svg';
import borrowImg from 'assets/img/borrow.svg';
import borrowImgLight from 'assets/img/borrow-light.svg';
import TabsTable from 'components/common/tabs-table';
import { formatNumber, getBigNumber } from 'utilities/number';
import BigNumber from 'bignumber.js';
import Toggle from 'components/common/toggle';
import { getComptrollerContract, methods } from 'utilities/contractService';
import { CONTRACT_COMPTROLLER_ADDRESS, CONTRACT_TOKEN_ADDRESS } from 'constants/address';
import toast from 'components/common/toast';
import SupplyModal from 'components/common/supplyModal';
import BorrowModal from 'components/common/borrowModal';
import CustomButton from 'components/common/button';
import LoadingSpinner from 'components/common/loadingSpinner';
import ActionBtn from 'components/common/action-btn';
import { SpinnerWrapper } from 'components/page//dashBoard/style';
import { throttle } from 'lodash';
import BorrowMarket from './borrowMarket';
import SupplyMarket from './supplyMarket';
import { CardWrapper, TableTitle, MarketWrap, LoadingWrapper } from './style';

const Market = ({ settings, setSetting, ui, allTableData, switchCollateral }) => {
  const [currentTab, setCurrentTab] = useState('supply');
  const [currentCategory, setCurrentCategory] = useState('all');
  const contractObj = CONTRACT_TOKEN_ADDRESS[settings.networkName];
  const [currentTableData, setCurrentTableData] = useState(allTableData);

  useEffect(() => {
    if (currentTab !== 'cai') {
      setSetting({ marketType: currentTab });
    }
  }, [currentTab, setSetting]);
  const columns = !contractObj
    ? []
    : [
        {
          title: <FormattedMessage id="Asset" />,
          dataIndex: 'asset',
          key: 'asset',
          render(img, asset) {
            return {
              children: (
                <div className="flex align-center">
                  <img className="asset-icon" src={asset?.img} alt="ethereum" />
                  <div className="flex flex-column align-start">
                    <Label size="14">{asset?.displayName}</Label>
                  </div>
                  {contractObj[asset.id]?.isNew ? <div className="flag-label bg-red">New</div> : null}
                  {contractObj[asset.id]?.isReFi ? <div className="flag-label bg-green">ReFi</div> : null}
                </div>
              ),
            };
          },
        },
        {
          title: <FormattedMessage id="Link" />,
          dataIndex: 'link',
          key: 'link',
          render(link, asset) {
            return {
              children: (
                <div className="flex align-center">
                  {contractObj[asset.id]?.link ? (
                    <a target="_blank" className="link" href={contractObj[asset.id]?.link} rel="noreferrer">
                      <Label size="14">{contractObj[asset.id]?.linkLabel}</Label>
                    </a>
                  ) : null}
                </div>
              ),
            };
          },
        },
        {
          title: '',
          dataIndex: 'status',
          key: 'status-m',
          render(status, asset) {
            return {
              children: (
                <div className="flex md-hidden">
                  <ActionBtn asset={asset} />
                </div>
              ),
            };
          },
        },
        {
          title: (
            <span className="md-hidden">
              <FormattedMessage id="Collateral" />
            </span>
          ),
          dataIndex: 'collateral',
          key: 'collateral-m',
          render(collateral, asset) {
            return {
              children: +asset.collateralFactor ? (
                <div className="md-hidden">
                  <Toggle checked={collateral} onChecked={() => switchCollateral(asset)} />
                </div>
              ) : null,
            };
          },
        },
        {
          title: <FormattedMessage id="Supply APY" />,
          dataIndex: 'supplyApy',
          key: 'supplyApy',
          render(supplyApy, asset) {
            const apy = settings.withFLD ? supplyApy.plus(asset.fldSupplyApy) : supplyApy;
            return {
              children: (
                <div className="green-label">
                  <span>+</span>
                  {formatNumber(apy, true, 2, '', '%')}
                </div>
              ),
            };
          },
        },
        {
          title: <FormattedMessage id="Borrow APY" />,
          dataIndex: 'borrowApy',
          key: 'borrowApy',
          render(borrowApy, asset) {
            const apy = settings.withFLD ? getBigNumber(asset.fldBorrowApy).plus(borrowApy) : borrowApy;
            return {
              children: (
                <div className={!settings.withFLD || apy.isNegative() ? 'red-label' : 'green-label'}>
                  {formatNumber(apy, true, 2, apy.dp(2, 1) > 0 ? '+' : '', '%')}
                </div>
              ),
            };
          },
        },
        {
          title: <FormattedMessage id="Liquidity" className="liquidity" />,
          dataIndex: 'liquidity',
          key: 'liquidity',
          render(liquidity) {
            return {
              children: <BoldLabel size="14">{formatNumber(liquidity, true, 2, '$')}</BoldLabel>,
            };
          },
        },
        {
          title: <FormattedMessage id="Collateral Factor" />,
          dataIndex: 'collateralFactor',
          key: 'collateralFactor',
          render(collateralFactor) {
            return {
              children: (
                <BoldLabel size="14">
                  {`${getBigNumber(collateralFactor || 0)
                    .times(100)
                    .dp(2, 1)
                    .toString(10)}%`}
                </BoldLabel>
              ),
            };
          },
        },
        {
          title: <FormattedMessage id="Wallet" />,
          dataIndex: 'walletBalance',
          key: 'walletBalance',
          render(walletBalance, asset) {
            return {
              children: (
                <BoldLabel size="14">
                  {formatNumber(walletBalance, true, 5)} {asset.symbol}
                </BoldLabel>
              ),
            };
          },
        },
        {
          title: (
            <span className="xs-hidden">
              <FormattedMessage id="Collateral" />
            </span>
          ),
          dataIndex: 'collateral',
          key: 'collateral',
          align: 'right',
          render(collateral, asset) {
            return {
              children: +asset.collateralFactor ? (
                <div className="xs-hidden">
                  <Toggle checked={collateral} onChecked={() => switchCollateral(asset)} />
                </div>
              ) : null,
            };
          },
        },
        {
          title: '',
          dataIndex: 'status',
          key: 'name',
          render(status, asset) {
            return {
              children: (
                <div className="flex just-end xs-hidden ">
                  <ActionBtn asset={asset} />
                </div>
              ),
            };
          },
        },
      ];

  const tabs = !contractObj
    ? []
    : [
        {
          key: 'all',
          title: 'All',
        },
        // {
        //   key: 'ERC20',
        //   title: 'ERC-20',
        // },
        // {
        //   key: 'DeFiLP',
        //   title: 'DeFi LP',
        // },
      ];

  const changeTableData = (category) => {
    switch (category) {
      case 'all':
        setCurrentTableData(allTableData);
        break;
      // case 'ERC20':
      //   setCurrentTableData(allTableData.filter?.((item) => item.category === 'ERC20'));
      //   break;
      // case 'DeFiLP':
      //   setCurrentTableData(allTableData.filter?.((item) => item.category === 'DeFiLP'));
      //   break;
      default:
        setCurrentTableData(allTableData);
    }
  };

  const onTabsChange = (item) => {
    const key = tabs[item]?.key;
    setCurrentCategory(key);
    changeTableData(key);
  };

  useEffect(() => {
    changeTableData(currentCategory);
  }, [allTableData]);
  return (
    <>
      <MarketWrap blurBg={allTableData.length === 0}>
        <TabsTable
          onChange={onTabsChange}
          tableProps={{
            columns,
            dataSource: currentTableData,
            pagination: false,
            // loading: {
            //   spinning: currentTableData.length === 0,
            //   indicator: <LoadingSpinner />,
            // },
          }}
          tabList={tabs}
        />
      </MarketWrap>
      {allTableData.length === 0 && (
        <LoadingWrapper>
          <LoadingSpinner />
        </LoadingWrapper>
      )}
    </>
  );
};

Market.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
  allTableData: PropTypes.array.isRequired,
  switchCollateral: PropTypes.func.isRequired,
};

Market.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(Market);
