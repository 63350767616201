import { initialState } from 'modules/initialState';
import { handleActions } from 'redux-actions';
import { saveState } from 'utilities/localStorage';
import { CHANGE_LANGUAGE, CHANGE_THEME, CHANGE_STATUS } from './actions';

export default handleActions(
  {
    [CHANGE_LANGUAGE]: (state, action) => {
      const res = {
        ...state,
        lang: action.payload,
      };
      saveState(res);
      return res;
    },
    [CHANGE_THEME]: (state) => {
      const res = {
        ...state,
        theme: state.theme === 'dark' ? 'light' : 'dark',
      };
      saveState(res);
      return res;
    },
    [CHANGE_STATUS]: (state, action) => {
      const res = {
        ...state,
        subgraphStatus: action.payload,
      };
      saveState(res);
      return res;
    },
  },
  initialState.ui
);
