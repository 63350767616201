import styled from 'styled-components';

const TransactionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* height: fit-content; */
  @media only screen and (max-width: 768px) {
    padding: 15px 0;
    gap: 0;
  }
  .ant-pagination-item a {
    color: ${(props) => props.theme['--color-text-main']};
    font-weight: 400;
    font-size: 12px;
  }
  .ant-pagination-item-active {
    background: transparent;
    border-color: transparent;
    a {
      color: #3541FF;
    }
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    display: none;
  }
  .ant-pagination > li:nth-last-child(2) {
    display: none;
  }
  .ant-pagination-item:hover a {
    color: ${(props) => props.theme['--color-yellow']} !important;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: fit-content;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme['--color-btn-text-t']};
  @media only screen and (max-width: 768px) {
    gap: 15px;
    flex-wrap: wrap;
  }
  .title {
    font-weight: 400;
    font-size: 14px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  .transaction-type {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme['--color-bg-btn']};
    border-radius: 8px;
    cursor: pointer;
    width: 74px;
    height: 38px;

    &:hover {
      opacity: 0.6;
    }

    @media only screen and (max-width: 768px) {
      flex-basis: calc(25% - 12px);
      /* flex-grow: 1;
      flex-shrink: 0; */
    }
  }
`;
const TableWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  height: 100%;
  flex-direction: column;
  box-sizing: content-box;
  background: ${(props) => props.theme['--color-bg-table']};
  border-radius: 8px;
  color: ${(props) => props.theme['--color-text-top-light']};
  @media only screen and (max-width: 768px) {
    width: 1050px;
    margin-top: 20px;
    height: auto;
  }
  .table_header {
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme['--color-text-secondary']};
    cursor: pointer;
    padding: 30px;
    padding-bottom: 10px;
  }
  .table_content {
    overflow: auto;
    .table_item {
      padding: 25px 30px 25px;
      font-weight: 400;
      font-size: 14px;
      &:hover {
        box-sizing: border-box;
        background: ${(props) => props.theme['--color-connect-btn-hover-row']};
      }
      .coin-type {
        display: flex;
        flex-direction: row;
        gap: 10px;
        img {
          height: 20px;
          width: 20px;
        }
      }
      .from {
        cursor: pointer;
        color: #3541FF;

        &:hover {
          opacity: 0.8;
        }
      }
      .to {
        cursor: pointer;
        color: #ffb400;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;
const FooterWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  width: 100%;
  justify-content: center;

  .footer {
    color: ${(props) => props.theme['--color-text-top-light']};
  }
  .icons {
    color: ${(props) => props.theme['--color-text-top-light']};
    font-size: 15px;
    cursor: pointer;
    font-weight: 900;
    &:hover {
      color: ${(props) => props.theme['--color-yellow']};
    }
  }
`;

const ScrollContent = styled.div`
  height: 85%;
  overflow-y: scroll;
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    .table_header {
    }
    .table_content {
      overflow: hidden;
      max-height: 490px;
      overflow-y: scroll;
    }
    .ant-row {
      display: flex;
    }
    .ant-col {
      width: 150px;
    }
  }
`;

export { TransactionWrapper, TableWrapper, HeaderWrapper, FooterWrapper, ScrollContent };
