import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { CONTRACT_TOKEN_ADDRESS, EXPLORER_LINK } from 'constants/address';
import addToken from 'utilities/addToken';
import { formatNumber, getBigNumber } from 'utilities/number';
import iconWallet from 'assets/img/icons/wallet.svg';
import { ReactComponent as iconLink } from 'assets/img/icons/link.svg';
import { Card } from 'components/common/style';
import plusImg from 'assets/img/plus.svg';
import plusImgLight from 'assets/img/plus_light.svg';
import Earth from 'assets/img/earth.svg';
import Add from 'assets/img/add.svg';
import CustomButton from 'components/common/button';
import { isProd } from 'constants/env';
import { CardWrapper, BtnCard } from './style';

function CoinInfo({ settings, ui }) {
  const handleLink = () => {
    window.open(
      `${EXPLORER_LINK[settings.networkName]}/token/${CONTRACT_TOKEN_ADDRESS[settings.networkName].fld.address}?a=${
        settings.selectedAddress
      }`,
      '_blank'
    );
  };

  const leftNum = formatNumber(settings.userFLDBalance);
  return (
    <Card>
      <CardWrapper className="flex align-center">
        <div className="flex just-between align-center full">
          <div className="flex align-center z-index-1">
            {/* <div className="img-wrap">
              <img src={iconWallet} alt="fld-balance" />
            </div> */}
            <div>
              <span className="title">Token</span>
              <div className="number m-t-10 md-hidden">{leftNum}</div>
            </div>
          </div>
          <div className="number xs-hidden">{leftNum}</div>
        </div>
        <div className="apply">
          <BtnCard className={`green translucent  ${isProd ? 'disabled-btn' : ''}`} onClick={() => handleLink()}>
            <img className="ico" src={Earth} alt="" />
            <span className="font">View on Explorer</span>
          </BtnCard>
          <BtnCard
            className={`pink translucent ${isProd ? '' : 'disabled-btn'}`}
            onClick={() => addToken('fld', 18, 'token', settings.networkName)}
          >
            <img className="ico" src={Add} alt="" />
            <span className="font">Add to Metamask</span>
          </BtnCard>
        </div>
      </CardWrapper>
    </Card>
  );
}

CoinInfo.propTypes = {
  settings: PropTypes.object,
};

CoinInfo.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

export default compose(connectAccount(mapStateToProps, undefined))(CoinInfo);
