import styled from 'styled-components';

const ProposalWrapper = styled.div`
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${(props) => props.theme['--color-bg-active']};

  .title {
    margin-bottom: 10px;
    * {
      width: 250px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 20px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
    }
  }
  .detail {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  .orange-text {
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme['--color-orange']};
  }
  .Passed-btn {
    color: ${(props) => props.theme['--color-dark-green']};
  }
  .Active-btn {
    color: ${(props) => props.theme['--color-yellow']};
  }
  .Succeeded-btn,
  .Queued-btn {
    color: ${(props) => props.theme['--color-blue']};
  }
  .Failed-btn {
    color: #f3f3f3;
  }

  .description {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
  }

  .vote-status {
    padding: 10px;
    img {
      width: 29px;
      height: 29px;
      border-radius: 50%;
      margin-right: 40px;
    }
    button {
      height: 32px;
      border-radius: 5px;
      background-image: linear-gradient(to right, #45cd85, #7eddaa);
      .MuiButton-label {
        font-size: 12px;
        font-weight: 500;
        color: ${(props) => props.theme['--color-text-main']};
        text-transform: capitalize;
      }
      &:not(:last-child) {
        margin-right: 5px;
      }
    }

    @media only screen and (max-width: 768px) {
      img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 40px;
      }
    }
  }
`;
export { ProposalWrapper };
