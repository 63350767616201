import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { LineProgressBarWrapper } from './style';

function LineProgressBar({ label, percent }) {
  return (
    <LineProgressBarWrapper>
      <div className="flex align-center just-between">
        <p className="label">{label}</p>
        <p className="percent">{percent}%</p>
      </div>
      <Progress percent={percent} strokeColor="#ffffff" strokeWidth={7} showInfo={false} />
    </LineProgressBarWrapper>
  );
}

LineProgressBar.propTypes = {
  label: PropTypes.string,
  percent: PropTypes.number,
};

LineProgressBar.defaultProps = {
  label: 'Borrow Limit',
  percent: 0.0,
};

export default LineProgressBar;
