import styled from 'styled-components';

const ChartWrapper = styled.div`
  width: 100% + 40px;
  margin: 10px -20px 10px;

  .title {
    text-align: right;
    font-size: 14px;
    font-weight: 600;
    color: ${(props) => props.theme['--color-text-main']};
    margin-right: 20px;
  }

  .recharts-cartesian-grid {
    opacity: 0.05;
  }

  .chart1 {
    height: 150px;
  }
  .chart2 {
    height: 150px;
  }
`;
export { ChartWrapper };
