import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const MainLayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  background: ${(props) => props.theme['--color-bg-renew']};
  backdrop-filter: blur(150px);
  & > div:first-child {
    flex-shrink: 0;
  }
  @media only screen and (max-width: 768px) {
    display: block;
  }
  .main {
    height: 100vh;
    .full_height > .main-content {
      overflow: hidden;
    }
    .full_height,
    .full_height > div,
    .full_height .main-content > div {
      height: 100%;
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }
    & > .row {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .top--wrapper {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
    .main-content--wrapper {
      flex-grow: 2;
      overflow: auto;
      padding: 20px 52px 30px 0;
      & > div {
        // background-color: ${(props) => props.theme['--color-bg-main']};
      }
      @media only screen and (max-width: 768px) {
        padding: 0 ${dim(26)} 30px;
      }
    }
    .main-content {
      display: flex;
      flex-direction: column;
      overflow: auto;
      overflow-x: hidden;
    }

    @media only screen and (max-width: 768px) {
      height: calc(100% - ${(props) => props.theme['--m-header-height']});
      /* overflow: auto; */
      /* background-color: ${(props) => props.theme['--color-bg-main']}; */
      & > div {
        height: 100%;
      }
      .main-content--wrapper {
        overflow: unset;
        background-color: ${(props) => props.theme['--color-bg-main']};
      }
    }
  }
  div {
    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      border-radius: 12px;
      background-color: ${(props) => props.theme['--color-scrollbar']};
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      /* background-color: #f7c408cc; */
    }

    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }
`;
export { MainLayoutWrapper };
