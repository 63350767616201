import styled from 'styled-components';

const FooterWrapper = styled.div`
  padding-top: 20px;
  margin-right: 52px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 768px) {
    height: 150px;
    flex-direction: column;
    justify-content: center;
    &::before {
      content: none;
    }
    & a {
      margin-right: unset !important;
    }
  }
  .status-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: ${(props) => props.theme['--color-dark-green']};
    margin-right: 21px;
  }

  .subgraph-error {
    margin-right: 6px;
    background-color: ${(props) => props.theme['--color-red']};
  }
  .subgraph-error-msg {
    display: inline-block;
    margin-right: 21px;
    color: ${(props) => props.theme['--color-red']};

    @media screen and (max-width: 1128px) {
      margin-right: 14px;
    }

    @media screen and (max-width: 1121px) {
      margin-right: 12px;
    }

    @media screen and (max-width: 1117px) {
      margin-right: 7px;
    }
  }

  a {
    color: #000000;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    &:hover {
      opacity: 0.8;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    @media only screen and (max-width: 830pxpx) {
      font-size: 12px;
    }
  }

  .changeLanguage {
    .ant-select-selection {
      background-color: ${(props) => props.theme['--color-text-top']};
      border: 0px;
      color: ${(props) => props.theme['--color-text-top-light']};
      font-size: 13px;
      font-weight: 600;
      text-align: center;
      text-align-last: center;
      height: 38px;
      line-height: 38px;
      cursor: pointer;
      padding-top: 10px;
      border-radius: 24px;
      overflow-x: hidden;
    }
    .ant-select-selection__rendered {
      display: flex;
      justify-content: center;
      line-height: 20px;
    }

    svg {
      &:hover {
        color: #d84967;
      }
    }
  }

  .theme {
    width: 32px;
    text-align: center;
    font-size: 14px;
    height: 20px;
    cursor: pointer;
    margin-left: 10px;
  }
  .network-wrap {
    height: 38px;
    color: ${(props) => props.theme['--color-text-top-light']};
    background-color: ${(props) => props.theme['--color-text-top']};
    border-radius: 24px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    padding: 0 14px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    @media screen and (max-width: 1113px) {
      font-size: 12px;
    }
  }
`;
const FooterWrapperLeft = styled.div`
  display: flex;
  align-items: center;
`;

const NetworkButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 146px;
  z-index: 11;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
  .red {
    background-color: red !important;
    width: 128px !important;
  }
  .connect-btn {
    width: 108px;
    height: 26px;
    border-radius: 6px;
    background-color: #5fd495;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
    &:hover {
      background-color: #5fd495;
      opacity: 0.8;
    }

    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-top-light']};
      text-transform: capitalize;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const NetworkWrap = styled.div`
  width: auto;
  height: 38px;
  color: ${(props) => props.theme['--color-text-top-light']};
  padding-right: 10px;

  @media only screen and (max-width: 830px) {
    font-size: 12px;
  }
  .label {
    margin-right: 15px;
    opacity: 0.6;
    @media only screen and (max-width: 830px) {
      font-size: 12px;
    }
  }
  .network-name {
    margin-left: auto;
    @media only screen and (max-width: 830px) {
      font-size: 12px;
    }
  }
  .img-wrap {
    width: 18px;
    height: 18px;
    margin-right: 5px;
    line-height: 18px;
    display: inline-block;
    img {
      width: 18px;
      height: 18px;
    }
  }
`;

const WalletWrap = styled.div`
  width: auto;
  height: 38px;
  color: ${(props) => props.theme['--color-text-top-light']};

  padding-right: 10px;
  .label {
    margin-right: 30px;
    opacity: 0.6;
  }
  .network-name {
    margin-left: auto;
    .img-wrap {
      width: 18px;
      height: 18px;
      margin-right: 5px;
      line-height: 18px;
      display: inline-block;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
`;

const BlockWrap = styled.div`
  width: auto;
  height: 38px;
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme['--color-text-top-light']};
  a {
    color: ${(props) => props.theme['--color-text-top-light']};
  }
  .label {
    margin-right: 15px;
    opacity: 0.6;
  }
`;
export { FooterWrapper, FooterWrapperLeft, NetworkButton, NetworkWrap, WalletWrap, BlockWrap };
