import BigNumber from 'bignumber.js';
import commaNumber from 'comma-number';

const NUMBER_PRECISION = 0.00001;
const OTHER_PRECISION = 0.01;

const formater = commaNumber.bindWith(',', '.');

export const getBigNumber = (value) => {
  if (!value) {
    return new BigNumber(0);
  }
  if (BigNumber.isBigNumber(value)) {
    return value;
  }
  return new BigNumber(value);
};

const getFormatNumber = (value) => {
  return formater(value);
};

const getNumberWithPrecision = (value, dp, rm = 1) => {
  if (dp === undefined) return value;
  const valBigNumber = getBigNumber(value);
  return valBigNumber.dp(dp, rm);
};

const getPrecision = (prefix = '', suffix = '') => {
  let res = NUMBER_PRECISION;
  if (prefix === '$' || suffix === '%') {
    res = OTHER_PRECISION;
  }
  return res;
};

/**
 *
 * @param {*} value  value of number
 * @param {*} format Format or not
 * @param {*} dp decimal places https://mikemcl.github.io/bignumber.js/#dp
 * @param {*} prefix like $1000
 * @param {*} suffix like 50%
 * @param {*} customPrecision 0.0000001
 * @returns
 */
export const formatNumber = (value, format = true, dp = 2, prefix = '', suffix = '', customPrecision) => {
  const bigNumber = BigNumber.isBigNumber(value) ? value : getBigNumber(value);
  if (suffix === '%' && bigNumber.absoluteValue().isGreaterThan(10000000000)) {
    return 'infinity';
  }
  const precision = customPrecision || getPrecision(prefix, suffix);
  const exceeded = bigNumber.gt(0) && bigNumber.lt(precision);
  const valBigNumber = getNumberWithPrecision(value, dp, 1);
  const finalNumber = format ? getFormatNumber(valBigNumber.toString(10)) : valBigNumber.toString(10);
  return exceeded ? `<${prefix}${precision}${suffix}` : `${prefix}${finalNumber}${suffix}`;
};
