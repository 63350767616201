import { dim } from 'modules/ui/dim';
import styled from 'styled-components';

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-card-bg-new']};
  padding: 24px 5px;
  color: white;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  .card-row {
    & > div:not(:last-child) {
      margin-bottom: 24px;
    }

    &::-webkit-scrollbar {
      width: 20px;
    }
  }
`;

const TableTitle = styled.div`
  font-size: ${dim(24)};
  font-weight: 700;
  font-size: 24px;
  color: ${(props) => props.theme['--color-bg-white']};
  margin-bottom: 16px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 768px) {
    margin: 40px 0 20px;
    padding: 0 13px;
    font-size: 16px;
    line-height: 16px;
  }
  .img-wrap {
    width: 32px;
    height: 32px;
    display: inline-block;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
    @media only screen and (max-width: 768px) {
      margin-right: 5px;
      width: 16px;
      height: 16px;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  .tab-item {
    font-size: 14px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 118px;
    height: 32px;
    color: ${(props) => props.theme['--color-text-main']};
    background-color: ${(props) => props.theme['--color-bg-new-inactive']};
  }
  .first {
    border-radius: 6px 0px 0px 6px;
  }
  .second {
    border-radius: 0px 6px 6px 0px;
  }

  .tab-active {
    font-weight: 600;
    color: ${(props) => props.theme['--color-text-main']};
    background-color: ${(props) => props.theme['--color-bg-new-active']};
  }
`;

const TabContent = styled.div`
  width: 100%;
  height: calc(100% - 75px);
  margin-top: 15px;
  display: flex;
  justify-content: center;
`;

const MintRepayCai = styled.div`
  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    justify-content: flex-start;
  }
`;
const SupplyMarketWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 6px;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const BorrowMarketWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 6px;
  @media only screen and (max-width: 768px) {
    padding: 0;
  }
`;

const MarketWrap = styled.div`
  filter: ${({ blurBg, theme }) => {
    const brightness = theme['--filter-blur-brightness'];
    const filterVal = `blur(5px) brightness(${brightness})`;
    return blurBg ? filterVal : '';
  }};
  .loading {
    margin-top: 150px;
    @media screen and (max-width: 768px) {
      margin-top: 30px;
    }
  }
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: 140px;
  left: 0;
  right: 0;
  margin: 0 auto;
`;
export {
  CardWrapper,
  Tabs,
  TabContent,
  MintRepayCai,
  SupplyMarketWrapper,
  BorrowMarketWrapper,
  TableTitle,
  MarketWrap,
  LoadingWrapper,
};
