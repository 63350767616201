const getArgs = (func) => {
  // First match everything inside the function argument parens.
  const args = func.toString().match(/.*?\(([^)]*)\)/) ? func.toString().match(/.*?\(([^)]*)\)/)[1] : '';
  // Split the arguments string into an array comma delimited.
  return args
    .split(',')
    .map((arg) => {
      // Ensure no inline comments are parsed and trim the whitespace.
      return arg.replace(/\/\*.*\*\//, '').trim();
    })
    .filter((arg) => {
      // Ensure no undefined values are added.
      return arg;
    });
};
export default getArgs;
