import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { Icon } from 'antd';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import {
  getTokenContract,
  getCTokenContract,
  getComptrollerContract,
  getCaiControllerContract,
  getFldLensContract,
  methods,
} from 'utilities/contractService';
import MainLayout from 'components/layout/wrap';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Card, Row, Column } from 'components/common/style';
import earnedIcon from 'assets/img/icons/earned.svg';
import {
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_FLD_LENS_ADDRESS,
  CONTRACT_CAI_UNITROLLER_ADDRESS,
} from 'constants/address';
import CustomButton from 'components/common/button';

import Star from 'assets/img/star.svg';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CardWrapper, BtnCard } from './style';

function Claim({ settings, setSetting, ui }) {
  const [earnedBalance, setEarnedBalance] = useState('0.00000000');

  const getVoteInfo = async () => {
    const myAddress = settings.selectedAddress;
    if (!myAddress) return;
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
    // const caiContract = getCaiControllerContract(CONTRACT_CAI_UNITROLLER_ADDRESS);
    let [cheeInitialIndex, cheeAccrued] = await Promise.all([
      methods.call(appContract.methods.fairlendInitialIndex, []),
      methods.call(appContract.methods.fairlendAccrued, [myAddress]),
    ]);
    let fldEarned = getBigNumber(0);
    await Promise.all(
      Object.values(CONTRACT_CTOKEN_ADDRESS[settings.networkName]).map(async (item, index) => {
        const cTokenContract = getCTokenContract(item.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
        let [supplyState, supplierIndex, supplierTokens, borrowState, borrowerIndex, borrowBalanceStored, borrowIndex] =
          await Promise.all([
            methods.call(appContract.methods.fairlendSupplyState, [item.address]),
            methods.call(appContract.methods.fairlendSupplierIndex, [item.address, myAddress]),
            methods.call(cTokenContract.methods.balanceOf, [myAddress]),
            methods.call(appContract.methods.fairlendBorrowState, [item.address]),
            methods.call(appContract.methods.fairlendBorrowerIndex, [item.address, myAddress]),
            methods.call(cTokenContract.methods.borrowBalanceStored, [myAddress]),
            methods.call(cTokenContract.methods.borrowIndex, []),
          ]);
        const supplyIndex = supplyState.index;
        if (+supplierIndex === 0 && +supplyIndex > 0) {
          supplierIndex = cheeInitialIndex;
        }
        let deltaIndex = getBigNumber(supplyIndex).minus(supplierIndex);

        const supplierDelta = getBigNumber(supplierTokens).multipliedBy(deltaIndex).dividedBy(1e36);

        fldEarned = fldEarned.plus(supplierDelta);
        if (+borrowerIndex > 0) {
          deltaIndex = getBigNumber(borrowState.index).minus(borrowerIndex);
          const borrowerAmount = getBigNumber(borrowBalanceStored).multipliedBy(1e18).dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
          fldEarned = fldEarned.plus(borrowerDelta);
        }
      })
    );

    fldEarned = fldEarned.plus(cheeAccrued).dividedBy(1e18).dp(8, 1).toString(10);

    setEarnedBalance(fldEarned && fldEarned !== '0' ? `${fldEarned}` : '0.00000000');
  };

  useEffect(() => {
    if (settings.markets.length) {
      getVoteInfo();
    }
  }, [settings.markets]);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEarn, setIsLoadingEarn] = useState(false);

  useEffect(() => {
    if (!earnedBalance) {
      setIsLoadingEarn(true);
      return;
    }
    setIsLoadingEarn(false);
  }, [earnedBalance]);

  const getBefore = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(0, position + 5) : value;
  };

  const getAfter = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(position + 5) : null;
  };

  const rightNum = getBefore(formatNumber(earnedBalance));
  const disabled = rightNum === '0.0000';

  const handleCollect = async () => {
    if (disabled) {
      return;
    }
    const { assetList } = settings;

    // filter out tokens that users have positive balance to save gas cost by 'claimFld'
    const fldLensContract = getFldLensContract(CONTRACT_FLD_LENS_ADDRESS[settings.networkName]);
    const cTokensBalanceInfos = await methods.call(fldLensContract.methods.cTokenBalancesAll, [
      assetList.map((asset) => asset.ctokenAddress),
      settings.selectedAddress,
    ]);

    const outstandingCTokens = cTokensBalanceInfos.filter((info) => {
      // info[2]: borrowBalanceCurrent, info[3]: balanceOfUnderlying
      return getBigNumber(info[2]).gt(0) || getBigNumber(info[3]).gt(0);
    });

    if (+earnedBalance !== 0) {
      setIsLoading(true);
      const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
      methods
        .send(
          appContract.methods.claimChee,
          [settings.selectedAddress, outstandingCTokens.map((token) => token[0])],
          settings.selectedAddress
        )
        .then(() => {
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <Card>
      {isLoadingEarn ? (
        <LoadingSpinner />
      ) : (
        <CardWrapper className="flex align-center ">
          <div className="flex just-between align-center full z-index-1">
            <div className="flex align-center">
              {/* <div className="img-wrap">
                <img src={earnedIcon} alt="earned" />
              </div> */}
              <div>
                <div className="title">Token Earned</div>
                <div className="number m-t-10 md-hidden">+{rightNum}</div>
              </div>
            </div>
            <div className="number xs-hidden">+{rightNum}</div>
          </div>

          {settings.selectedAddress && (
            <BtnCard className={`yellow translucent ${disabled ? '' : 'disabled-btn'}`} onClick={handleCollect}>
              <img className="ico" src={Star} alt="" />
              <span className="font"> Collect</span>
            </BtnCard>
          )}
        </CardWrapper>
      )}
    </Card>
  );
}

Claim.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
};

Claim.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Claim);
