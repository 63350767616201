import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    color: #ffffff;
    position: absolute;
    top: 18px;
    right: 18px;
    @media only screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      right: 14px;
      top: 12px;
    }

    &:hover {
      filter: invert(100%);
    }
  }

  .header-content {
    width: 100%;
    background-color: #3541ff;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-bottom: 40px;
    @media only screen and (max-width: 564px) {
      margin-bottom: 0;
    }
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      height: 64px;
    }
    .logo-image {
      width: 65px;
      height: 61px;
      margin-bottom: 39px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 19px;
      }
    }
    .title {
      font-size: ${dim(18)};
      color: #ffffff;
      height: 88px;
      font-weight: 700;
      text-align: center;
      align-items: center;
      display: flex;
    }

    .back-btn {
      width: 100%;
      padding: 10px 30px;
      img {
        transform: rotate(180deg);
        margin-right: 10px;
      }
      span {
        color: ${(props) => props.theme['--color-white']};
        font-size: 20px;
      }
    }
  }
  .tips {
    font-size: ${dim(14)};
    line-height: 14px;
    margin-top: 10px;
    margin-bottom: 50px;
    text-align: center;
    white-space: nowrap;
    color: ${(props) => props.theme['--color-text-top-light']};
  }
  .tips-display-none {
    display: none;
  }
  .connect-wallet-content {
    width: 100%;
    padding: 0px 55px 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .btn-disabled {
      position: relative;
    }
    .btn-disabled::after {
      content: '';
      cursor: not-allowed;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme['--opacity-btn-black']};
      opacity: 0.8;
      z-index: 11;
      border-radius: 8px;
    }
    .btn-disabled:hover {
      background: unset !important;
    }
    .metamask-connect-btn-active {
      &:hover {
        background: ${(props) => props.theme['--color-connect-btn-hover-borderImage']};
        background-repeat: no-repeat;
        &::after {
          position: absolute;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border-radius: 6px;
          top: 2px;
          left: 2px;
          content: '';
          z-index: 0;
          background: ${(props) => props.theme['--color-connect-btn-hover-background']};
        }
      }
    }
    .metamask-connect-btn {
      width: 48%;
      cursor: pointer;
      padding: 0 15px;
      height: ${dim(60)};
      margin-bottom: ${dim(17)};
      border-radius: 8px;
      padding: 0 ${dim(20)};
      position: relative;
      background: ${(props) => props.theme['--color-connect-background']};
      & > div {
        position: absolute;
        z-index: 10;
        img {
          width: ${dim(28)};
          margin-right: ${dim(10)};
        }
        span {
          color: ${(props) => props.theme['--color-text-main']};
          font-weight: normal;
          font-size: 14px;
        }
      }

      span {
        color: ${(props) => props.theme['--color-text-secondary']};
        font-weight: normal;
        font-size: 17px;
      }
    }
    @media only screen and (max-width: 564px) {
      padding: 0 ${dim(34)};
      & > .metamask-connect-btn {
        height: 54px;
        width: 100%;
      }
      & > .metamask-connect-btn:last-child {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 768px) {
      padding: ${dim(34)};
    }

    .coinbase-connect-btn,
    .ledger-connect-btn {
      border-bottom: 1px solid ${(props) => props.theme['--color-bg-active']};
    }

    .metamask-status {
      margin-top: 20px;
      background-color: rgba(255, 0, 0, 0.03);
      padding: 5px 10px;
      border-radius: 4px;
      color: ${(props) => props.theme['--color-red']};
      a {
        margin-left: 5px;
      }
    }
  }

  .terms-of-use {
    font-size: 13.5px;
    color: ${(props) => props.theme['--color-text-secondary']};
    margin-bottom: 32px;
    a {
      color: ${(props) => props.theme['--color-orange']};
      margin-left: 11px;
    }
  }
`;

export { ModalContent };
