import styled from 'styled-components';

const MoreWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-card-bg-new']};
  padding: 20px 18px 18px 18px;

  @media only screen and (max-width: 1197px) {
    padding: 9px 18px 9px 18px;
  }

  @media only screen and (max-width: 768px) {
    padding: 9px 18px 22px 72px;
  }

  .font {
    font-size: 14px;
    opacity: 0.6;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    color: ${(props) => props.theme['--color-white']};
  }
  .img-wrap {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    background-color: ${(props) => props.theme['--color-bg-icon-new']};
    margin-right: 11px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
    }
  }
  .add-mask {
    max-width: 130px;
    height: 40px;
    border: 1px solid ${(props) => props.theme['--color-more-border-new']};
    background-color: ${(props) => props.theme['--color-more-bg-new']};
    box-sizing: border-box;
    border-radius: 8px;
    color: ${(props) => props.theme['--color-more-font-new']};
    margin-top: 20px;
    // padding: 8px;
    &:hover {
      opacity: 0.8;
      span {
        cursor: pointer;
        // color: ${(props) => props.theme['--color-yellow']};
        // font-weight: 900;
        // font-size: 15px;
      }
    }
    img {
      width: 14px;
      height: 14px;
      margin-right: 6px;
      color: #ffffff;

      @media only screen and (max-width: 1072) {
        margin-left: 3px;
      }
    }
  }
  .boost {
    margin-top: 20px !important;
  }
  .last-wrap {
    margin-top: 20px;
    text-align: center;
  }
`;

export { MoreWrapper };
