import styled from 'styled-components';

const VotingPowerWrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 14px 56px 14px 56px;
  border-radius: 8px;
  background-image: linear-gradient(to right, #45cd85, #7eddaa);

  .title {
    font-size: 20px;
    color: ${(props) => props.theme['--color-text-main']};
    font-weight: bold;
  }

  .content {
    color: ${(props) => props.theme['--color-text-main']};
    font-size: 28.5px;
    font-weight: 900;
  }

  span {
    color: ${(props) => props.theme['--color-bg-main']};
  }
`;

export { VotingPowerWrapper };
