import styled from 'styled-components';

const ToggleWrapper = styled.div`
  height: 15px;
  margin-top: -5px;

  .ant-switch {
    height: 20px;
    width: 32px;
    min-width: 32px;
    background-color: ${(props) => props.theme['--color-bg-unchecked']};
    border-color: ${(props) => props.theme['--color-bg-unchecked']};
    display: inline-block;
    &::after {
      margin-left: 3px;
      top: 3px;
      width: 12px;
      height: 12px;
    }
    @media only screen and (max-width: 768px) {
      height: 16px;
      width: 27px;
      min-width: 27px;
      &::after {
        top: 1px;
        margin-left: 2px;
      }
    }
  }

  .ant-switch-checked {
    background-color: ${(props) => props.theme['--color-white']};
    border-color: ${(props) => props.theme['--color-bg-primary']};
    &::after {
      margin-left: -4px;
      background-color: ${(props) => props.theme['--color-green']} !important;
    }
    @media only screen and (max-width: 768px) {
      &::after {
        margin-left: -2px;
      }
    }
  }
`;

export { ToggleWrapper };
