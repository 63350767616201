import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const CircleProgressBarWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;

  & > div > div {
    width: ${dim(130)} !important;
    background: red;
  }
  .circle-label {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    margin: 0;
    padding: 0;
    color: ${(props) => props.theme['--color-text-green']};
    line-height: 1;
    white-space: normal;
    text-align: center;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);

    .percent {
      color: #ffffff;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 17px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .percent-red {
      color: ${(props) => props.theme['--color-text-red']};
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 17px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }

    .label {
      color: ${(props) => props.theme['--color-text-white']};
      font-size: 15.5px;
      font-weight: normal;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;
export { CircleProgressBarWrapper };
