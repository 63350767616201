import React from 'react';
import PropTypes from 'prop-types';
import { Spin, Icon } from 'antd';
import loadingImg from 'assets/img/loading.svg';
import { ManualVotingWrapper } from './style';

const antIcon = <img src={loadingImg} alt="loading" style={{ fontSize: 64 }} />;
function ManualVoting({ address, balance, isLoading }) {
  const getBefore = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(0, position + 5) : value;
  };

  const getAfter = (value) => {
    const position = value.indexOf('.');
    return position !== -1 ? value.slice(position + 5) : null;
  };

  return (
    <ManualVotingWrapper>
      <div className="flex align-center just-center header-content">
        <p>Confirm Transaction</p>
      </div>
      <div className="flex flex-column align-center just-center manual-voting-section">
        <p className="voting-count">
          {getBefore(balance)}
          <span>{getAfter(balance)}</span>
          {` `}
          Votes
        </p>
        <span className="voting-address">
          Manual Voting from {`${address.substr(0, 4)}...${address.substr(address.length - 4, 4)}`}
        </span>
        {isLoading && <Spin className="voting-spinner" indicator={antIcon} />}
        <span className="voting-confirm">Confirm the transaction.</span>
      </div>
    </ManualVotingWrapper>
  );
}

ManualVoting.propTypes = {
  address: PropTypes.bool.isRequired,
  balance: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ManualVoting;
