import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { Wrap } from './style';

function CustomButton(props) {
  return <Wrap {...props}>{props.children}</Wrap>;
}

CustomButton.propTypes = {};

CustomButton.defaultProps = {};

export default CustomButton;
