import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import light from 'assets/img/light.png';
import dark from 'assets/img/dark.png';
import { ThemeSwitchWrapper } from './style';

function ThemeSwitch({ ui, changeTheme, className }) {
  return (
    <ThemeSwitchWrapper
      className={className}
      aria-label="theme"
      role="presentation"
      onClick={(e) => {
        changeTheme();
        e.stopPropagation();
      }}
    >
      {ui.theme === 'dark' ? <img src={light} alt="" /> : <img src={dark} alt="" />}
    </ThemeSwitchWrapper>
  );
}

ThemeSwitch.propTypes = {
  ui: PropTypes.object,
  changeTheme: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ThemeSwitch.defaultProps = {
  ui: {},
  className: '',
};
export default compose(withRouter)(ThemeSwitch);
