import styled from 'styled-components';

const VotingWalletWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: ${(props) => props.theme['--color-bg-primary']};
  padding: 36px 22px 48px 15px;

  .header {
    padding-left: 35px;
    margin-bottom: 20px;
    .title {
      font-size: 17px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
    }
  }

  .content {
    padding: 20px 0px;
    border-bottom: 1px solid ${(props) => props.theme['--color-bg-active']};

    img {
      width: 25px;
      height: 25px;
      border-radius: 50%;
      margin-right: 9px;
    }

    a,
    p {
      font-size: 16px;
      font-weight: bold;
      color: ${(props) => props.theme['--color-orange']};
    }

    .content-label {
      padding-left: 35px;
      font-size: 16px;
      color: ${(props) => props.theme['--color-text-secondary']};
    }

    .content-value {
      font-size: 28.5px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
    }
  }
  .delegate-change {
    padding-left: 35px;
    .content-label {
      padding-left: 0px;
    }
    .change {
      font-size: 16px;
      font-weight: bold;
      color: ${(props) => props.theme['--color-orange']};
    }
  }
  .setup {
    padding: 30px 35px 0px;

    .setup-header {
      font-size: 18px;
      font-weight: 900;
      color: ${(props) => props.theme['--color-text-main']};
    }

    .setup-content {
      margin-top: 32px;
      font-size: 16px;
      color: ${(props) => props.theme['--color-text-secondary']};

      a {
        color: ${(props) => props.theme['--color-orange']};
      }
    }
  }

  .started-btn {
    margin-top: 30px;
    width: 50%;
    height: 46px;
    border-radius: 5px;
    background-image: linear-gradient(to right, #45cd85, #7eddaa);
    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-main']};
      text-transform: capitalize;
    }
  }

  span {
    color: ${(props) => props.theme['--color-orange']};
  }

  .mint-content-label {
    margin-top: 20px;
    padding-left: 35px;
    font-weight: 900;
    font-size: 16px;
    color: ${(props) => props.theme['--color-text-secondary']};
  }
`;

export { VotingWalletWrapper };
