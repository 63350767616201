import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { Icon, Progress, Tooltip, Form } from 'antd';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import { getCTokenContract, getComptrollerContract, methods } from 'utilities/contractService';
import { CONTRACT_CTOKEN_ADDRESS, CONTRACT_COMPTROLLER_ADDRESS } from 'constants/address';
import { SAFE_MAX_THRESHOLD } from 'constants/enum';
import coinImg from 'assets/img/networks/ethf.png';
import arrowRightImg from 'assets/img/arrow-right.png';
import caiImg from 'assets/img/coins/cai.png';
import infoImg from 'assets/img/icons/info.svg';
import feeImg from 'assets/img/fee.png';
import { TabContentWrapper, TabContent, LoadingBgWrapper } from 'components/common/style';
import { formatNumber, getBigNumber } from 'utilities/number';
import LoadingSpinner from 'components/common/loadingSpinner';

const abortController = new AbortController();
const allowedAmountTip = 'Your available withdraw amount = Total supplied amount - borrowed amount';

function WithdrawTab({ asset, settings, ui, changeTab, onCancel, setSetting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState({ value: getBigNumber() });
  const [borrowLimit, setBorrowLimit] = useState(getBigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(getBigNumber(0));
  const [newBorrowLimit, setNewBorrowLimit] = useState(getBigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(getBigNumber(0));
  const [safeMaxBalance, setSafeMaxBalance] = useState(getBigNumber(0));
  const [feePercent, setFeePercent] = useState(getBigNumber(0));

  const getFeePercent = async () => {
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
    const treasuryPercent = await methods.call(appContract.methods.treasuryPercent, []);
    setFeePercent(getBigNumber(treasuryPercent).times(100).div(1e18));
  };

  useEffect(() => {
    getFeePercent();
  }, []);

  const validateAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const temp = getBigNumber(value || 0);
    const { totalBorrowLimit } = settings;
    const { tokenPrice, collateralFactor } = asset;
    const isNotAllowed = temp.isGreaterThan(asset.supplyBalance) || newBorrowPercent.isGreaterThan(getBigNumber(100));
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onAmountInput = ({ value }) => {
    setAmount({ ...validateAmount(value), value: getBigNumber(value) });
  };
  const updateInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    const { collateral } = asset;
    const supplyBalance = getBigNumber(asset.supplyBalance);
    const collateralFactor = getBigNumber(asset.collateralFactor);
    if (!collateral) {
      setSafeMaxBalance(supplyBalance);
      return;
    }
    const safeMax = BigNumber.maximum(
      totalBorrowLimit.minus(totalBorrowBalance.div(SAFE_MAX_THRESHOLD)).div(collateralFactor).div(tokenPrice),
      getBigNumber(0)
    );
    setSafeMaxBalance(BigNumber.minimum(safeMax, supplyBalance));

    if (tokenPrice && !amount.value.isZero() && !amount.value.isNaN()) {
      const temp = totalBorrowLimit.minus(amount.value.times(tokenPrice).times(collateralFactor));
      setNewBorrowLimit(temp);
      setNewBorrowPercent(temp.isZero() ? getBigNumber(0) : totalBorrowBalance.div(temp).times(100));
      if (totalBorrowLimit.isZero()) {
        setBorrowLimit(getBigNumber(0));
        setBorrowPercent(getBigNumber(0));
      } else {
        setBorrowLimit(totalBorrowLimit);
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      setBorrowLimit(totalBorrowLimit);
      setNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    }
  }, [settings.selectedAddress, amount]);

  useEffect(() => {
    if (asset.ctokenAddress && settings.selectedAddress) {
      updateInfo();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, updateInfo]);

  /**
   * Withdraw
   */
  const handleWithdraw = async () => {
    const { id: assetId } = asset;
    const cTokenContract = getCTokenContract(assetId, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
    if (assetId && settings.selectedAddress) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Withdraw',
          status: true,
          amount: formatNumber(amount, false, 8),
          symbol: asset.symbol,
        },
      });
      try {
        if (amount.value.eq(asset.supplyBalance)) {
          const cTokenBalance = await methods.call(cTokenContract.methods.balanceOf, [settings.selectedAddress]);
          await methods.send(cTokenContract.methods.redeem, [cTokenBalance], settings.selectedAddress);
        } else {
          await methods.send(
            cTokenContract.methods.redeemUnderlying,
            [amount.value.times(getBigNumber(10).pow(settings.decimals[assetId].token)).integerValue().toString(10)],
            settings.selectedAddress
          );
        }
        setAmount({ value: getBigNumber(0) });
        setIsLoading(false);
        onCancel();
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: '',
          },
        });
      } catch (error) {
        setIsLoading(false);
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: '',
          },
        });
      }
    }
  };
  /**
   * Max amount
   */
  const handleSetAmountByProportion = (proportion) => {
    setAmount({ value: safeMaxBalance.multipliedBy(proportion) });
  };

  // withdraw color
  const withDrawBorrowVal = formatNumber(newBorrowLimit, true, 2, '$');
  const withDrawBorrowPercent = formatNumber(newBorrowPercent, false, 2, '', '%');

  return (
    <TabContentWrapper>
      <Form name="basic" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} autoComplete="off">
        {isLoading && (
          <LoadingBgWrapper>
            <LoadingSpinner />
          </LoadingBgWrapper>
        )}
        <TabContent blurBg={isLoading} className="flex flex-column align-center just-content">
          <div className="tabcontent-title align-center just-center">
            <img className="asset-img" src={asset.img} alt="asset" />
            <span>Withdraw Amount</span>
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please input amount!' }]}
            validateStatus={amount.validateStatus}
            help={amount.errorMsg}
          >
            <div className="flex align-center input-wrapper">
              <NumberFormat
                autoFocus
                value={amount.value.isZero() ? null : amount.value.toString(10)}
                onValueChange={onAmountInput}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span className="coin-name">{asset.displayName}</span>
            </div>
          </Form.Item>
          <div className="flex align-center balance-content">
            <span className="wallet-balance">
              <FormattedMessage id="Supplied" />: {formatNumber(asset.supplyBalance, true, 5)} {asset.symbol}
            </span>
            <div className="flex align-center">
              <span className="proportion-wrapper pointer">
                {[
                  // { label: '10%', val: 0.1 },
                  // { label: '25%', val: 0.25 },
                  { label: 'SAFE MAX', val: 1 },
                ].map((proportion) => (
                  <span
                    key={proportion.val}
                    onClick={() => {
                      handleSetAmountByProportion(proportion.val);
                    }}
                  >
                    {proportion.label}
                  </span>
                ))}
              </span>
              <Tooltip placement="top" title={<FormattedMessage id={allowedAmountTip} />}>
                <img className="info_img" src={infoImg} alt="infoImg" />
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-column just-center align-center apy-content">
            <div className="borrow-limit">
              <span>
                {/* Borrow Limit */}
                <FormattedMessage id="Borrow Limit" />
              </span>
              {amount.value.isZero() || amount.value.isNaN() ? (
                <span>{formatNumber(borrowLimit, true, 2, '$')}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{formatNumber(borrowLimit, true, 2, '$')}</span>
                  <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                  <span className={withDrawBorrowVal === '$0' ? 'repay-green-color' : 'repay-green-revert'}>
                    {withDrawBorrowVal}
                  </span>
                </div>
              )}
            </div>
            <div className="flex align-center just-between borrow-limit-used">
              <span>
                {/* Borrow Limit Used */}
                <FormattedMessage id="Borrow Limit Used" />
              </span>
              {amount.value.isZero() || amount.value.isNaN() ? (
                <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
              ) : (
                <div className="flex align-center just-between">
                  <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                  <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                  <span className={withDrawBorrowPercent === '0%' ? 'repay-green-color' : 'repay-green-revert'}>
                    {withDrawBorrowPercent}
                  </span>
                </div>
              )}
            </div>
            <Progress
              percent={newBorrowPercent.toNumber()}
              className="apy-progress"
              strokeColor="#3541FF"
              trailColor={`${ui.theme === 'dark' ? '#000000' : '#FFFFFF'}`}
              strokeWidth={7}
              showInfo={false}
            />
          </div>
          <Button
            className="button withdraw"
            disabled={
              isLoading ||
              amount.value.isNaN() ||
              amount.value.isZero() ||
              amount.value.isGreaterThan(asset.supplyBalance) ||
              newBorrowPercent.isGreaterThan(getBigNumber(100))
            }
            onClick={handleWithdraw}
          >
            <FormattedMessage id="Withdraw" />
          </Button>
          <div className="flex flex-wrap just-between">
            {[
              { img: asset.img, label: 'Supply APY', val: `${formatNumber(asset.supplyApy, false, 2, '', '%')}` },
              { img: coinImg, label: 'FLD APY', val: `${formatNumber(asset.fldSupplyApy, false, 2, '', '%')}` },
              ...[
                asset.symbol !== 'CELO'
                  ? {
                      img: feeImg,
                      label: 'Fee',
                      val: `${
                        !amount.value.isNaN()
                          ? getBigNumber(amount)
                              .times(feePercent / 100)
                              .dp(4)
                              .toString(10)
                          : 0
                      }
          ${asset.symbol} (${feePercent.toString(10)})%`,
                    }
                  : null,
              ],
            ]
              .filter(Boolean)
              .map((item) => (
                <div key={item.label} className="trans-proportion">
                  <img className="asset-img" src={item.img} alt="asset" />
                  <span>
                    {/* Supply APY */}
                    <FormattedMessage id={item.label} />
                  </span>
                  <span>{item.val}</span>
                </div>
              ))}
          </div>
        </TabContent>
      </Form>
    </TabContentWrapper>
  );
}

WithdrawTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  changeTab: PropTypes.func,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired,
};

WithdrawTab.defaultProps = {
  asset: {},
  settings: {},
  changeTab: () => {},
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(WithdrawTab);
