import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import Button from '@material-ui/core/Button';
import NumberFormat from 'react-number-format';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import BigNumber from 'bignumber.js';
import { getTokenContract, getCTokenContract, methods } from 'utilities/contractService';
import { CONTRACT_BASE_TOKEN, CONTRACT_CTOKEN_ADDRESS, CONTRACT_TOKEN_ADDRESS } from 'constants/address';
import { sendRepay } from 'utilities/baseConnect';
import arrowRightImg from 'assets/img/arrow-right.png';
import coinImg from 'assets/img/networks/ethf.png';
import caiImg from 'assets/img/coins/cai.png';
import infoImg from 'assets/img/icons/info.svg';
import { Icon, Progress, Tooltip, Form } from 'antd';
import { TabContentWrapper, TabContent, LoadingBgWrapper } from 'components/common/style';
import eventBus from 'utilities/eventBus';
import { LOAD_GLOBAL_DATA } from 'constants/eventCenter';
import { formatNumber, getBigNumber } from 'utilities/number';
import LoadingSpinner from 'components/common/loadingSpinner';

const abortController = new AbortController();

function RepayBorrowTab({ asset, settings, ui, onCancel, setSetting }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [amount, setAmount] = useState({ value: getBigNumber() });
  const [borrowBalance, setBorrowBalance] = useState(getBigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(getBigNumber(0));
  const [newBorrowBalance, setNewBorrowBalance] = useState(getBigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(getBigNumber(0));
  const [showEnabledBtn, setShowEnabledBtn] = useState(false);

  const updateInfo = useCallback(() => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    if (amount.value.isZero() || amount.value.isNaN()) {
      setBorrowBalance(totalBorrowBalance);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else {
      const temp = totalBorrowBalance.minus(amount.value.times(tokenPrice));
      setBorrowBalance(totalBorrowBalance);
      setNewBorrowBalance(temp);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(temp.div(totalBorrowLimit).times(100));
      }
    }
  }, [settings.selectedAddress, amount, asset]);

  useEffect(() => {
    if (asset.ctokenAddress && settings.selectedAddress) {
      updateInfo();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, updateInfo, asset]);

  useEffect(() => {
    setIsEnabled(asset.isEnabled);
  }, [asset.isEnabled]);

  useEffect(() => {
    setShowEnabledBtn(!asset.isEnabled && asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]);
  }, [asset.isEnabled, asset.id, settings.networkName]);

  const validateAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const temp = getBigNumber(value || 0);
    const { totalBorrowLimit } = settings;
    const { tokenPrice, collateralFactor } = asset;
    const isNotAllowed = temp.isGreaterThan(BigNumber.minimum(asset.walletBalance, asset.borrowBalance));
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onAmountInput = ({ value }) => {
    setAmount({ ...validateAmount(value), value: getBigNumber(value) });
  };
  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset && settings.selectedAddress && asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]) {
      setIsLoading(true);
      const tokenContract = getTokenContract(asset.id, CONTRACT_TOKEN_ADDRESS[settings.networkName]);
      methods
        .send(
          tokenContract.methods.approve,
          [asset.ctokenAddress, getBigNumber(2).pow(256).minus(1).toString(10)],
          settings.selectedAddress
        )
        .then(() => {
          eventBus.emit(LOAD_GLOBAL_DATA);
          setIsEnabled(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };
  /**
   * Repay Borrow
   */
  const handleRepayBorrow = async () => {
    const cTokenContract = getCTokenContract(asset.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
    if (asset && settings.selectedAddress) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Repay Borrow',
          status: true,
          amount: formatNumber(amount, false, 8),
          symbol: asset.symbol,
        },
      });
      if (asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]) {
        if (amount.value.eq(asset.borrowBalance)) {
          await methods.send(
            cTokenContract.methods.repayBorrow,
            [getBigNumber(2).pow(256).minus(1).toString(10)],
            settings.selectedAddress
          );
        } else {
          await methods.send(
            cTokenContract.methods.repayBorrow,
            [amount.value.times(getBigNumber(10).pow(settings.decimals[asset.id]?.token)).integerValue().toString(10)],
            settings.selectedAddress
          );
        }
        setAmount({ value: getBigNumber(0) });
        onCancel();
        setIsLoading(false);
        setSetting({
          pendingInfo: {
            type: '',
            status: false,
            amount: 0,
            symbol: '',
          },
        });
      } else {
        sendRepay(
          settings.selectedAddress,
          amount.value.times(getBigNumber(10).pow(settings.decimals[asset.id]?.token)).integerValue().toString(10),
          settings.networkName,
          () => {
            setAmount({ value: getBigNumber(0) });
            setIsLoading(false);
            onCancel();
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
          }
        );
      }
    }
  };

  /**
   * Max amount
   */
  const handleSetAmountByProportion = (proportion) => {
    setAmount({ value: BigNumber.minimum(asset.walletBalance, asset.borrowBalance).multipliedBy(proportion) });
  };

  // green-color : all 0's
  const repayVal = formatNumber(BigNumber(asset.borrowBalance).minus(amount));
  const newBorrowVal = formatNumber(newBorrowBalance, true, 2, '$');
  const newPercentVal = formatNumber(newBorrowPercent, true, 2, '', '%');

  return (
    <TabContentWrapper>
      {isLoading && (
        <LoadingBgWrapper>
          <LoadingSpinner />
        </LoadingBgWrapper>
      )}
      <Form name="basic" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} autoComplete="off">
        <TabContent
          blurBg={(showEnabledBtn && !isEnabled) || isLoading}
          className="flex flex-column align-center just-content"
        >
          <div className="tabcontent-title align-center just-center">
            <img className="asset-img" src={asset.img} alt="asset" />
            <span>Repay Borrow Amount</span>
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please input amount!' }]}
            validateStatus={amount.validateStatus}
            help={amount.errorMsg}
          >
            <div className="flex flex-column align-center just-center input-wrapper">
              <NumberFormat
                autoFocus
                value={amount.value.isZero() ? null : amount.value.toString(10)}
                onValueChange={onAmountInput}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span className="coin-name">{asset.displayName}</span>
            </div>
          </Form.Item>
          <div className="flex align-center balance-content">
            <span className="wallet-balance">
              {/* Wallet Balance */}
              <FormattedMessage id="Wallet Balance" />: {formatNumber(asset.walletBalance, true, 5)} {asset.symbol}
            </span>
            <div className="flex align-center">
              <span className={`proportion-wrapper ${isEnabled ? 'pointer' : 'pointer--not-allowed'}`}>
                {[
                  { label: '25%', val: 0.25 },
                  { label: '50%', val: 0.5 },
                  { label: 'MAX', val: 1 },
                ].map((proportion) => (
                  <span
                    key={proportion.label}
                    onClick={() => {
                      if (isEnabled) handleSetAmountByProportion(proportion.val);
                    }}
                  >
                    {proportion.label}
                  </span>
                ))}
              </span>
              <Tooltip
                placement="top"
                title={<FormattedMessage id="RepayMessage" values={{ name: asset.displayName }} />}
              >
                <img className="info_img" src={infoImg} alt="infoImg" />
              </Tooltip>
            </div>
          </div>
          {isEnabled && (
            <div className="flex flex-column just-center align-center apy-content">
              <div className="borrow-limit">
                <span>
                  <FormattedMessage id="Borrowed" />
                </span>
                {amount.value.isZero() || amount.value.isNaN() ? (
                  <span>{formatNumber(asset.borrowBalance)}</span>
                ) : (
                  <div className="flex align-center just-between">
                    <span>{formatNumber(asset.borrowBalance)}</span>
                    <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                    <span className={repayVal === '0' ? 'repay-green-color' : 'repay-green-revert'}>{repayVal}</span>
                  </div>
                )}
              </div>
              <div className="borrow-limit">
                <span>
                  <FormattedMessage id="Borrow Balance" />
                </span>
                {amount.value.isZero() || amount.value.isNaN() ? (
                  <span>{formatNumber(borrowBalance, true, 2, '$')}</span>
                ) : (
                  <div className="flex align-center just-between">
                    <span>{formatNumber(borrowBalance, true, 2, '$')}</span>
                    <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                    <span className={newBorrowVal === '$0' ? 'repay-green-color' : 'repay-green-revert'}>
                      {newBorrowVal}
                    </span>
                  </div>
                )}
              </div>
              <div className="borrow-limit">
                <span>
                  <FormattedMessage id="Borrow Limit Used" />
                </span>
                {amount.value.isZero() || amount.value.isNaN() ? (
                  <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                ) : (
                  <div className="flex align-center just-between">
                    <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                    <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                    <span className={newPercentVal === '0%' ? 'repay-green-color' : 'repay-green-revert'}>
                      {newPercentVal}
                    </span>
                  </div>
                )}
              </div>
              <Progress
                percent={newBorrowPercent.toNumber()}
                className="apy-progress"
                strokeColor="#3541FF"
                trailColor={`${ui.theme === 'dark' ? '#000000' : '#FFFFFF'}`}
                strokeWidth={7}
                showInfo={false}
              />
            </div>
          )}
          <Button
            className="button withdraw"
            disabled={
              isLoading ||
              amount.value.isZero() ||
              amount.value.isNaN() ||
              amount.value.isGreaterThan(BigNumber.minimum(asset.walletBalance, asset.borrowBalance))
            }
            onClick={handleRepayBorrow}
          >
            <FormattedMessage id="Repay Borrow" />
          </Button>
          <div className="flex flex-wrap just-between">
            {[
              { img: asset.img, label: 'Borrow APY', val: `${formatNumber(asset.borrowApy, false, 2, '', '%')}` },
              { img: coinImg, label: 'FLD APY', val: `${formatNumber(asset.fldBorrowApy, false, 2, '', '%')}` },
            ]
              .filter(Boolean)
              .map((item) => (
                <div key={item.label} className="trans-proportion">
                  <img className="asset-img" src={item.img} alt="asset" />
                  <span>
                    {/* Supply APY */}
                    <FormattedMessage id={item.label} />
                  </span>
                  <span>{item.val}</span>
                </div>
              ))}
          </div>
        </TabContent>
      </Form>
      {showEnabledBtn && !isEnabled ? (
        <div className="flex flex-column approve-enable">
          <div className="flex flex-center align-center just-center title">
            <img src={asset.img} alt="coinImg" />
            {asset.displayName}
          </div>
          <Button
            className="button"
            disabled={isLoading}
            onClick={() => {
              onApprove();
            }}
          >
            <FormattedMessage id="Enable" />
          </Button>
        </div>
      ) : null}
    </TabContentWrapper>
  );
}

RepayBorrowTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired,
};

RepayBorrowTab.defaultProps = {
  asset: {},
  settings: {},
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(RepayBorrowTab);
