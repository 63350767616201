import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { connectUI } from 'modules/ui/connectUI';
import { connectAccount, accountActionCreators } from 'modules';
import { bindActionCreators } from 'redux';

import { LANG_LIST } from 'constants/lang';
import eventBus from 'utilities/eventBus';
import { CLICK_DRAWER_LANG } from 'constants/eventCenter';
import { List, ListItem, DrawerWrap } from './style';

function LangDrawerComponent({ ui, settings, changeLanguage }) {
  const [showLangDrawer, setShowLangDrawer] = useState(false);

  const onClickLang = () => {
    setShowLangDrawer(true);
  };
  useEffect(() => {
    eventBus.addListener(CLICK_DRAWER_LANG, onClickLang);
    return () => {
      eventBus.removeListener(CLICK_DRAWER_LANG, onClickLang);
    };
  }, []);

  const changeLang = (lang) => {
    changeLanguage(lang);
    setShowLangDrawer(false);
  };

  return (
    <DrawerWrap
      placement="bottom"
      height="unset"
      closable={false}
      onClose={() => setShowLangDrawer(false)}
      visible={showLangDrawer}
      key="lang"
    >
      <List>
        {LANG_LIST.map((item) => (
          <ListItem
            key={item.value}
            onClick={() => {
              changeLang(item.value);
            }}
          >
            {item.label}
          </ListItem>
        ))}
      </List>
    </DrawerWrap>
  );
}

LangDrawerComponent.propTypes = {
  ui: PropTypes.object,
};

LangDrawerComponent.defaultProps = {
  ui: {},
};
const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});
const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;
  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(
  withRouter,
  connectAccount(mapStateToProps, mapDispatchToProps),
  connectUI(mapStateToProps, undefined)
)(LangDrawerComponent);
