import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  .ant-btn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    @media screen and (max-width: 768px) {
      width: 77px;
    }
  }
`;

export { Wrap };
