import styled from 'styled-components';

const CardWrap = styled.div`
  width: 100%;
  height: 150px;
  background: ${(props) => props.theme['--color-card-bg-new']};
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .left {
      display: flex;
      align-items: center;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: ${(props) => props.theme['--color-pools-text']};
      .icon {
        width: 40px;
        height: 40px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden;
        background: #eeeeee;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .right {
      display: flex;
      align-items: center;
      .action {
        /* display: flex;
        flex-direction: column;
        align-items: center; */
        margin-right: 10px;
        /* margin-bottom: -3px; */
      }
      .icon {
        width: 16px;
        height: 16px;
      }
      .name {
        margin-top: 5px;
        color: ${(props) => props.theme['--color-pools-text']};
        font-size: 12px;
      }
    }
  }
  .bottom {
    display: flex;
    justify-content: space-around;
    .item {
      &__title {
        color: ${(props) => props.theme['--color-text-gray']};
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 12px;
      }
      &__number {
        color: ${(props) => props.theme['--color-pools-text']};
        margin-top: 10px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 18px;
      }
    }
  }
`;

export { CardWrap };
