import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connectAccount, accountActionCreators } from 'modules';
import { FormattedMessage } from 'react-intl';
import ActionBtn from 'components/common/action-btn';
import { bindActionCreators } from 'redux';
import { ASSETS_STATUS_NAME, ASSETS_STATUS_MAP } from 'constants/enum';
import Toggle from 'components/common/toggle';
import helpIcon from 'assets/img/icons/help-fill.svg';
import helpIconDark from 'assets/img/icons/help-fill-dark.svg';
import { formatNumber, getBigNumber } from 'utilities/number';
import { CardWrap } from './style';
import CustomButton from '../button';

function Card({ asset, settings, switchCollateral, ui }) {
  const supplyApy = settings.withFLD ? asset.supplyApy.plus(asset.fldSupplyApy) : asset.supplyApy;
  const borrowApy = settings.withFLD ? getBigNumber(asset.fldBorrowApy).plus(asset.borrowApy) : asset.borrowApy;

  return (
    <CardWrap>
      <div className="top">
        <div className="left">
          <img className="icon" src={asset.img} alt="" />
          {asset.displayName}
        </div>
        <div className="right">
          <div className="action">
            {/* <Toggle
              checked={asset.collateral}
              onChecked={() => {
                switchCollateral(asset);
              }}
            /> */}
            {/* <span className="name">
              <FormattedMessage id="Collateral" />
            </span> */}
            {/* <Tooltip title={<FormattedMessage id="Collateral" />}>
              <img className="icon m-l-2 m-t-3" src={ui.theme === 'dark' ? helpIconDark : helpIcon} alt="" />
            </Tooltip> */}
          </div>
          <ActionBtn asset={asset} />
        </div>
      </div>
      <div className="bottom">
        <div className="item">
          <div className="item__title">{ASSETS_STATUS_NAME[asset.status]}ing Balance</div>
          <div className="item__number">
            {asset.status === ASSETS_STATUS_MAP.SUPPLY && <>{formatNumber(asset.supplyBalance, true, 5)}</>}
            {asset.status === ASSETS_STATUS_MAP.BORROW && <>{formatNumber(asset.borrowBalance, true, 5)}</>}
          </div>
        </div>
        <div className="item">
          <div className="item__title">{ASSETS_STATUS_NAME[asset.status]} APY</div>
          <div className="item__number">
            {asset.status === ASSETS_STATUS_MAP.SUPPLY && (
              <>
                <span>+</span>
                {formatNumber(supplyApy, true, 2, '', '%')}
              </>
            )}
            {asset.status === ASSETS_STATUS_MAP.BORROW && (
              <>{formatNumber(borrowApy, true, 2, borrowApy.dp(2, 1) > 0 ? '+' : '', '%')}</>
            )}
          </div>
        </div>
        {asset.status !== ASSETS_STATUS_MAP.BORROW && (
          <div className="item">
            <div className="item__title">
              <FormattedMessage id="Collateral" />
            </div>
            <div className="flex just-center">
              <div className="m-t-13">
                <Toggle
                  checked={asset.collateral}
                  onChecked={() => {
                    switchCollateral(asset);
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </CardWrap>
  );
}

Card.propTypes = {
  asset: PropTypes.object.isRequired,
  switchCollateral: PropTypes.func.isRequired,
};

Card.defaultProps = {};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(Card);
