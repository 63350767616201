/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import {
  getTokenContract,
  getCTokenContract,
  getComptrollerContract,
  getCaiControllerContract,
  methods,
} from 'utilities/contractService';
import MainLayout from 'components/layout/wrap';
import { promisify } from 'utilities/promisify';
import LoadingSpinner from 'components/common/loadingSpinner';
import checkIsValidNetwork from 'utilities/checkIsValidNetwork';
import { Row, Column } from 'components/common/style';
import {
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CAI_UNITROLLER_ADDRESS,
} from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import Proposals from './components/proposals';
import VotingPower from './components/votingPower';
import VotingWallet from './components/votingWallet';
import CoinInfo from './components/coinInfo';
import { VoteWrapper, SpinnerWrapper } from './style';

let timeStamp = 0;

function Vote({ settings, getProposals, setSetting }) {
  const [balance, setBalance] = useState(0);
  const [votingWeight, setVotingWeight] = useState('0');
  const [proposals, setProposals] = useState({});
  const [current, setCurrent] = useState(1);
  const [isLoadingProposal, setIsLoadingPropoasl] = useState(false);
  const [earnedBalance, setEarnedBalance] = useState('0.00000000');
  const [caiMint, setCaiMint] = useState('0.00000000');
  const [delegateAddress, setDelegateAddress] = useState('');
  const [delegateStatus, setDelegateStatus] = useState('');

  const loadInitialData = useCallback(async () => {
    setIsLoadingPropoasl(true);
    await promisify(getProposals, {
      offset: 0,
      limit: 5,
    })
      .then((res) => {
        setIsLoadingPropoasl(false);
        setProposals(res.data);
      })
      .catch(() => {
        setIsLoadingPropoasl(false);
      });
  }, [getProposals]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  const handleChangePage = (pageNumber, offset, limit) => {
    setCurrent(pageNumber);
    setIsLoadingPropoasl(true);
    promisify(getProposals, {
      offset,
      limit,
    })
      .then((res) => {
        setProposals(res.data);
        setIsLoadingPropoasl(false);
      })
      .catch(() => {
        setIsLoadingPropoasl(false);
      });
  };

  const updateBalance = async () => {
    if (settings.selectedAddress && checkIsValidNetwork(settings.walletType)) {
      const fldTokenContract = getTokenContract('fld', CONTRACT_TOKEN_ADDRESS[settings.networkName]);
      await methods.call(fldTokenContract.methods.getCurrentVotes, [settings.selectedAddress]).then((res) => {
        const weight = getBigNumber(res).div(getBigNumber(10).pow(18)).toString(10);
        setVotingWeight(weight);
      });
      let temp = await methods.call(fldTokenContract.methods.balanceOf, [settings.selectedAddress]);
      temp = getBigNumber(temp).dividedBy(getBigNumber(10).pow(18)).dp(4, 1).toString(10);
      setBalance(temp);
    }
  };

  const getVoteInfo = async () => {
    const myAddress = settings.selectedAddress;
    if (!myAddress) return;
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
    const caiContract = getCaiControllerContract(CONTRACT_CAI_UNITROLLER_ADDRESS);

    let [cheeInitialIndex, cheeAccrued, cheeCAIState, caiMinterIndex, caiMinterAmount] = await Promise.all([
      methods.call(appContract.methods.fairlendInitialIndex, []),
      methods.call(appContract.methods.fairlendAccrued, [myAddress]),
      methods.call(caiContract.methods.fairlendCAIState, []),
      methods.call(caiContract.methods.fairlendCAIMinterIndex, [myAddress]),
      methods.call(appContract.methods.mintedCAIs, [myAddress]),
    ]);
    let fldEarned = getBigNumber(0);
    await Promise.all(
      Object.values(CONTRACT_CTOKEN_ADDRESS[settings.networkName]).map(async (item, index) => {
        const cTokenContract = getCTokenContract(item.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
        let [supplyState, supplierIndex, supplierTokens, borrowState, borrowerIndex, borrowBalanceStored, borrowIndex] =
          await Promise.all([
            methods.call(appContract.methods.fairlendSupplyState, [item.address]),
            methods.call(appContract.methods.fairlendSupplierIndex, [item.address, myAddress]),
            methods.call(cTokenContract.methods.balanceOf, [myAddress]),
            methods.call(appContract.methods.fairlendBorrowState, [item.address]),
            methods.call(appContract.methods.fairlendBorrowerIndex, [item.address, myAddress]),
            methods.call(cTokenContract.methods.borrowBalanceStored, [myAddress]),
            methods.call(cTokenContract.methods.borrowIndex, []),
          ]);
        const supplyIndex = supplyState.index;
        if (+supplierIndex === 0 && +supplyIndex > 0) {
          supplierIndex = cheeInitialIndex;
        }
        let deltaIndex = getBigNumber(supplyIndex).minus(supplierIndex);

        const supplierDelta = getBigNumber(supplierTokens).multipliedBy(deltaIndex).dividedBy(1e36);

        fldEarned = fldEarned.plus(supplierDelta);
        if (+borrowerIndex > 0) {
          deltaIndex = getBigNumber(borrowState.index).minus(borrowerIndex);
          const borrowerAmount = getBigNumber(borrowBalanceStored).multipliedBy(1e18).dividedBy(borrowIndex);
          const borrowerDelta = borrowerAmount.times(deltaIndex).dividedBy(1e36);
          fldEarned = fldEarned.plus(borrowerDelta);
        }
      })
    );

    fldEarned = fldEarned.plus(cheeAccrued).dividedBy(1e18).dp(8, 1).toString(10);

    const caiMintIndex = cheeCAIState.index;
    if (+caiMinterIndex === 0 && +caiMintIndex > 0) {
      caiMinterIndex = cheeInitialIndex;
    }
    const deltaIndex = getBigNumber(caiMintIndex).minus(getBigNumber(caiMinterIndex));
    const caiMinterDelta = getBigNumber(caiMinterAmount).times(deltaIndex).div(1e54).dp(8, 1).toString(10);
    setEarnedBalance(fldEarned && fldEarned !== '0' ? `${fldEarned}` : '0.00000000');
    setCaiMint(caiMinterDelta && caiMinterDelta !== '0' ? `${caiMinterDelta}` : '0.00000000');
  };

  const updateDelegate = async () => {
    if (settings.selectedAddress && timeStamp % 3 === 0) {
      const tokenContract = getTokenContract('fld', CONTRACT_TOKEN_ADDRESS[settings.networkName]);
      methods
        .call(tokenContract.methods.delegates, [settings.selectedAddress])
        .then((res) => {
          setDelegateAddress(res);
          if (res !== '0x0000000000000000000000000000000000000000') {
            setDelegateStatus(res === settings.selectedAddress ? 'self' : 'delegate');
          } else {
            setDelegateStatus('');
          }
        })
        .catch(() => {});
    }
    timeStamp = Date.now();
  };

  useEffect(() => {
    getVoteInfo();
    updateBalance();
    updateDelegate();
  }, [settings.markets]);

  const handleAccountChange = async () => {
    await getVoteInfo();
    await updateBalance();
    setSetting({
      accountLoading: false,
    });
  };

  useEffect(() => {
    if (settings.accountLoading) {
      handleAccountChange();
    }
  }, [settings.accountLoading]);

  return (
    <MainLayout title="Vote">
      <VoteWrapper className="flex">
        {(!settings.selectedAddress || settings.accountLoading || settings.wrongNetwork) && (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        )}
        {settings.selectedAddress && !settings.accountLoading && !settings.wrongNetwork && (
          <Row>
            <Column xs="12" sm="12" md="5">
              <Row>
                <Column xs="12">
                  <CoinInfo
                    balance={balance !== '0' ? `${balance}` : '0.00000000'}
                    address={settings.selectedAddress ? settings.selectedAddress : ''}
                  />
                </Column>
                <Column xs="12">
                  <VotingWallet
                    balance={balance !== '0' ? `${balance}` : '0.00000000'}
                    earnedBalance={earnedBalance}
                    caiMint={caiMint}
                    delegateAddress={delegateAddress}
                    delegateStatus={delegateStatus}
                  />
                </Column>
              </Row>
            </Column>
            <Column xs="12" sm="12" md="7">
              <Row>
                <Column xs="12">
                  <VotingPower
                    power={votingWeight !== '0' ? `${getBigNumber(votingWeight).dp(8, 1).toString(10)}` : '0.00000000'}
                  />
                </Column>
                <Column xs="12">
                  <Proposals
                    address={settings.selectedAddress ? settings.selectedAddress : ''}
                    isLoadingProposal={isLoadingProposal}
                    pageNumber={current}
                    proposals={proposals.result}
                    total={proposals.total || 0}
                    votingWeight={votingWeight}
                    onChangePage={handleChangePage}
                  />
                </Column>
              </Row>
            </Column>
          </Row>
        )}
      </VoteWrapper>
    </MainLayout>
  );
}

Vote.propTypes = {
  settings: PropTypes.object,
  getProposals: PropTypes.func.isRequired,
  setSetting: PropTypes.func.isRequired,
};

Vote.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { getProposals, setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      getProposals,
      setSetting,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Vote);
