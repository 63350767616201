import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import { connectUI } from 'modules/ui/connectUI';
import closeImg from 'assets/img/close-white.svg';
import logoImgDark from 'assets/img/fld-logo-dark.svg';
import logoImgLight from 'assets/img/networks/ethf.png';
import loadingImg from 'assets/img/loading.svg';
import { Tabs, TabSection, TabContent } from 'components/common/style';
import { ModalContent } from './style';

const antIcon = <img src={loadingImg} alt="loading" style={{ fontSize: 64 }} />;
function CollateralConfirmModal({ visible, isCollateralEnalbe, onCancel, theme }) {
  return (
    <Modal
      className="connect-modal mobile-modal"
      width={381}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        <TabSection>
          <Tabs activeBar="none" className="flex just-center align-center modal-header">
            <img className="close-btn pointer" src={closeImg} alt="close" onClick={onCancel} />
            <img src={theme === 'dark' ? logoImgDark : logoImgLight} alt="logo" className="logo-text logo-image" />
          </Tabs>
          <TabContent className="flex flex-column align-center just-center">
            <p className="title">
              {isCollateralEnalbe ? (
                <FormattedMessage id="Disable as collateral" />
              ) : (
                <FormattedMessage id="Enable as collateral" />
              )}
            </p>
            <Spin className="voting-spinner" indicator={antIcon} />
            <p className="confirm-text">
              {/* Confirm the transaction */}
              <FormattedMessage id="Confirm the transaction" />
            </p>
          </TabContent>
        </TabSection>
      </ModalContent>
    </Modal>
  );
}

CollateralConfirmModal.propTypes = {
  visible: PropTypes.bool,
  isCollateralEnalbe: PropTypes.bool,
  onCancel: PropTypes.func,
  theme: PropTypes.string,
};

CollateralConfirmModal.defaultProps = {
  visible: false,
  isCollateralEnalbe: true,
  onCancel: () => {},
  theme: PropTypes.string,
};

export default connectUI()(CollateralConfirmModal);
