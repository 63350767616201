import { getBigNumber } from 'utilities/number';

export const getAccount = (id, num, markets) => {
  const { tokenPrice } =
    markets.find((market) => {
      return market.address.toLowerCase() === id.toLowerCase();
    }) || {};

  return getBigNumber(tokenPrice).multipliedBy(num).toNumber();
};

export const getNum = (id, totalAccount, markets) => {
  const { tokenPrice } =
    markets.find((market) => {
      return market.address.toLowerCase() === id.toLowerCase();
    }) || {};
  return getBigNumber(totalAccount).dividedBy(tokenPrice).toNumber();
};

export const getUnderlyingDecimal = (id, markets) => {
  const { underlyingDecimal } =
    markets.find((market) => {
      return market.address.toLowerCase() === id.toLowerCase();
    }) || {};
  return underlyingDecimal;
};
